export default class ApiClient {
  constructor(medicalContentAxiosInstance) {
    this.mcAxiosInstance = medicalContentAxiosInstance;
  }

  // Question services
  getQuestion(id) {
    return this.mcAxiosInstance.get(`/question/${id}`);
  }

  updateQuestion(
    id,
    questionType,
    text,
    units = undefined,
    helperText = undefined,
    html = undefined,
    minValue = undefined,
    maxValue = undefined,
    sliderType = undefined,
    stepSize = undefined,
    isAboutPii = undefined,
  ) {
    const questionData = {
      questionType,
      text,
      units,
      helperText,
      html,
      minValue,
      maxValue,
      sliderType,
      stepSize,
      isAboutPii,
    };
    return this.mcAxiosInstance.put(`/question/${id}`, questionData);
  }

  // Module services
  getModule(id) {
    return this.mcAxiosInstance.get(`/module/${id}`);
  }

  updateModule(id, {
    moduleName, isRepeatable, languagesToTranslate,
  }) {
    return this.mcAxiosInstance.put(`/module/${id}`, {
      uniqueName: moduleName, isRepeatable, languagesToTranslate,
    });
  }

  saveAsNewModule(id, name) {
    return this.mcAxiosInstance.post(`/module/${id}/saveAsNew`, { uniqueName: name });
  }

  getModules() {
    return this.mcAxiosInstance.get('/module/search');
  }

  deleteModule(id) {
    return this.mcAxiosInstance.delete(`/module/${id}`);
  }

  getAllModuleLocales() {
    return this.mcAxiosInstance.get('/module/locales');
  }

  // IntakePlan services
  getIntakePlan(id) {
    return this.mcAxiosInstance.get(`/intakePlan/${id}`);
  }

  createIntakePlan(values) {
    const {
      referenceCode, customerDisplayName, moduleId, description,
    } = values;
    return this.mcAxiosInstance.post('/intakePlan', {
      referenceCode, customerDisplayName, moduleId, description,
    });
  }

  updateIntakePlan(id, values) {
    const {
      referenceCode, customerDisplayName, moduleId, description,
    } = values;
    return this.mcAxiosInstance.put(`/intakePlan/${id}`, {
      referenceCode, customerDisplayName, moduleId, description,
    });
  }

  getIntakePlans() {
    return this.mcAxiosInstance.get('/intakePlan');
  }

  deleteIntakePlan(id) {
    return this.mcAxiosInstance.delete(`/intakePlan/${id}`);
  }

  // DxHx services
  getDxHx(id) {
    return this.mcAxiosInstance.get(`/dxhx/${id}`);
  }

  getAllDxHx() {
    return this.mcAxiosInstance.get('/dxhx/search');
  }

  addDxHx() {
    return this.mcAxiosInstance.post('/dxhx/create');
  }

  updateDxHx(id, name) {
    return this.mcAxiosInstance.put(`/dxhx/${id}`, { name });
  }

  deleteDxHx(id) {
    return this.mcAxiosInstance.delete(`/dxhx/${id}`);
  }

  getSymptom(id) {
    return this.mcAxiosInstance.get(`/symptom/${id}`);
  }

  getSymptoms() {
    return this.mcAxiosInstance.get('/symptom/search');
  }

  createSymptom(medicalName, reportName, layName, laySubstitutor, helperText) {
    return this.mcAxiosInstance.post('/symptom/', {
      medicalName, reportName, layName, laySubstitutor, helperText,
    });
  }

  addSymptomToDxHx(dxHxId, symptomId) {
    return this.mcAxiosInstance.post(`/dxhx/${dxHxId}/symptoms/addExisting`, { symptomId });
  }

  removeSymptomFromDxhx(dxHxId, sxOfDxHxId) {
    return this.mcAxiosInstance.delete(`/dxhx/${dxHxId}/symptoms/remove/${sxOfDxHxId}`);
  }

  updateSymptom(id, medicalName, reportName, layName, laySubstitutor, helperText) {
    return this.mcAxiosInstance.put(`/symptom/${id}`, {
      medicalName, layName, reportName, laySubstitutor, helperText,
    });
  }

  deleteSymptom(id) {
    return this.mcAxiosInstance.delete(`/symptom/${id}`);
  }

  getDxHxSymptoms(id) {
    return this.mcAxiosInstance.get(`/dxhx/${id}/symptoms`);
  }

  // ActionList Services
  getActionList(id) {
    return this.mcAxiosInstance.get(`/actionList/${id}`);
  }

  addQuestion(id) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'askQuestion' });
  }

  addModule(id) {
    if (id) return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'doModule' });
    return this.mcAxiosInstance.post('/module/create');
  }

  addExistingModule(id, moduleId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'doModule', moduleId });
  }

  addAddDxhxAction(id, dxhxId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'addDxHx', dxhxId });
  }

  addOfferTemplatesAction(id, listId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'offerTemplates', listId });
  }

  reorderActionList(id, orderedActionId) {
    return this.mcAxiosInstance.put(`/actionList/${id}/reorder`, { actionIds: orderedActionId });
  }

  removeActionFromActionList(actionListId, actionIdToRemove) {
    return this.mcAxiosInstance.delete(`actionList/${actionListId}/remove/${actionIdToRemove}`);
  }

  moveActionToNewModule(actionId, module) {
    return this.mcAxiosInstance.post(`actionList/${module.actionListId}/moveAction/${actionId}`);
  }

  reassignActionListToANewModule(actionListId, newModuleName) {
    return this.mcAxiosInstance.post(`actionList/${actionListId}/makeModule/`, { newModuleName });
  }

  // Question and Answer Services
  deleteQuestion(id) {
    return this.mcAxiosInstance.delete(`/question/${id}`);
  }

  getFixedAnswers(id) {
    return this.mcAxiosInstance.get(`/question/${id}/fixedAnswers`);
  }

  updateFixedAnswers(id, text, deselectOtherAnswers, helperText) {
    return this.mcAxiosInstance.put(`/fixedAnswer/${id}`, {
      text, deselectOtherAnswers, helperText,
    });
  }

  setFixedAnswerExclusionCriteria(id, exclusionCriteria) {
    return this.mcAxiosInstance.put(`/fixedAnswer/${id}`, {
      exclusionCriteria,
    });
  }

  addAnswer(id) {
    return this.mcAxiosInstance.post(`/question/${id}/fixedAnswers/create`);
  }

  reorderAnswerList(id, orderedAnswerId) {
    return this.mcAxiosInstance.put(`/fixedAnswer/${id}/reorder`, { answerIds: orderedAnswerId });
  }

  createDefaultAttributeForQuestion(
    questionId,
    attributeCreateUpdateRequest,
  ) {
    return this.mcAxiosInstance
      .post(
        `question/${questionId}/defaultAttribute/create`,
        attributeCreateUpdateRequest,
      );
  }

  addExistingAttributeAsDefaultForQuestion(questionId, attributeId) {
    return this.mcAxiosInstance
      .post(
        `question/${questionId}/defaultAttribute/addExisting`,
        { attributeId },
      );
  }

  removeDefaultAttributeFromQuestion(questionId) {
    return this.mcAxiosInstance.put(`question/${questionId}/defaultAttribute/remove`);
  }

  raiseQuestionAnswer(questionId, id) {
    return this.mcAxiosInstance.put(`/question/${questionId}/raise/${id}`);
  }

  lowerQuestionAnswer(questionId, id) {
    return this.mcAxiosInstance.put(`/question/${questionId}/lower/${id}}`);
  }

  getAnswer(id) {
    return this.mcAxiosInstance.get(`/fixedAnswer/${id}`);
  }

  deleteAnswer(id) {
    return this.mcAxiosInstance.delete(`/fixedAnswer/${id}`);
  }

  createAttributeForFixedAnswer(
    id,
    isSelected,
    attributeCreateUpdateRequest,
  ) {
    return this.mcAxiosInstance
      .post(
        `fixedAnswer/${id}/attribute/create/${isSelected ? 'selected' : 'unselected'}`,
        attributeCreateUpdateRequest,
      );
  }

  createAttributeValueForFixedAnswer(id, isSelected, attributeValueCreateRequest) {
    return this.mcAxiosInstance
      .post(
        `fixedAnswer/${id}/attributeValue/create/${isSelected ? 'selected' : 'unselected'}`,
        attributeValueCreateRequest,
      );
  }

  addExistingAttributeToFixedAnswer(id, isSelected, attributeId) {
    return this.mcAxiosInstance
      .post(
        `fixedAnswer/${id}/attribute/addExisting/${isSelected ? 'selected' : 'unselected'}`,
        { attributeId },
      );
  }

  addExistingAttributeValueToFixedAnswer(id, isSelected, attributeValueId) {
    return this.mcAxiosInstance
      .post(
        `fixedAnswer/${id}/attributeValue/addExisting/${isSelected ? 'selected' : 'unselected'}`,
        { attributeValueId },
      );
  }

  removeAttributeFromFixedAnswer(id, isSelected) {
    return this.mcAxiosInstance.put(`fixedAnswer/${id}/attribute/remove/${isSelected ? 'selected' : 'unselected'}`);
  }

  removeAttributeValueFromFixedAnswer(id, isSelected) {
    return this.mcAxiosInstance.put(`fixedAnswer/${id}/attributeValue/remove/${isSelected ? 'selected' : 'unselected'}`);
  }

  attributeSearch(contextType, subtype, excludeAttributesWithoutValues) {
    return this.mcAxiosInstance.get('/attribute/search/', { params: { contextType, subtype, excludeAttributesWithoutValues } });
  }

  getAttributeFollowers(id) {
    return this.mcAxiosInstance.get(`/attribute/${id}/reportFollowers`);
  }

  addReportFollower(leaderId, followerId) {
    return this.mcAxiosInstance.post(`/attribute/${leaderId}/addReportFollower/${followerId}`);
  }

  removeReportFollower(leaderId, followerId) {
    return this.mcAxiosInstance.delete(`/attribute/${leaderId}/removeReportFollower/${followerId}`);
  }

  getAttributeValues(id) {
    return this.mcAxiosInstance.get(`/attribute/${id}/values`);
  }

  updateAttribute(
    id,
    attributeCreateUpdateRequest,
  ) {
    return this.mcAxiosInstance.put(
      `attribute/${id}`,
      attributeCreateUpdateRequest,
    );
  }

  createAttribute(attributeCreateUpdateRequest) {
    return this.mcAxiosInstance.post('attribute/', attributeCreateUpdateRequest);
  }

  getAttributeById(id) {
    return this.mcAxiosInstance.get(`attribute/${id}`);
  }

  createAttributeValue(attributeId, attributeValue) {
    return this.mcAxiosInstance.post(`fixedAttributeValue/${attributeId}`, attributeValue);
  }

  updateAttributeValue(id, attributeValueUpdateRequest) {
    return this.mcAxiosInstance
      .put(
        `fixedAttributeValue/${id}/`,
        attributeValueUpdateRequest,
      );
  }

  addFixedAttributeValueToNarrative(id, attributeValueId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'setFixedAttributeValue', attributeValueId });
  }

  addNumericAttributeValueToNarrative(id, attributeId, value) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'setNumericAttributeValue', attributeId, value });
  }

  addConditional(id, conditionalCreateRequest) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'doConditional', conditionalCreateRequest });
  }

  updateConditional(conditionalId, conditionalUpdateRequest) {
    return this.mcAxiosInstance.put(`/conditional/${conditionalId}/`, conditionalUpdateRequest);
  }

  deleteConditional(id) {
    return this.mcAxiosInstance.delete(`/conditional/${id}`);
  }

  getConditional(id) {
    return this.mcAxiosInstance.get(`/conditional/${id}`);
  }

  addSubcondition(parentId, conditionalCreateRequest) {
    return this.mcAxiosInstance.post(`/condition/addSubcondition/${parentId}`, conditionalCreateRequest);
  }

  removeAllSubconditions(parentId) {
    return this.mcAxiosInstance.delete(`/condition/allSubcondition/${parentId}`);
  }

  addSymptomToNarrative(id, symptomId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'addSxToNarrative', symptomId });
  }

  addDeniedSymptomToNarrative(id, symptomId) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'addDeniedSxToNarrative', symptomId });
  }

  addMedicationToNarrative(id, medicationId, medicationNameOverride) {
    return this.mcAxiosInstance.post(`/actionList/${id}/add`, { actionType: 'addMedicationToNarrative', medicationId, medicationNameOverride });
  }

  // Medication services
  getAllMedications() {
    return this.mcAxiosInstance.get('/medication/getAllMedications');
  }

  getAllMainClasses() {
    return this.mcAxiosInstance.get('/medication/getAllMainClasses');
  }

  getAllMedicationsByMainClass(id) {
    return this.mcAxiosInstance.get(`medication/getAllMedicationsByMainClass/${id}`);
  }

  getMedication(id) {
    return this.mcAxiosInstance.get(`medication/${id}`);
  }

  createMedication(medicationCreateRequest) {
    return this.mcAxiosInstance.post('/medication/', medicationCreateRequest);
  }

  createMainClass(mainClassCreateRequest) {
    return this.mcAxiosInstance.post('/medication/mainClass', mainClassCreateRequest);
  }

  updateMainClass(id, mainClassUpdateRequest) {
    return this.mcAxiosInstance.put(`/medication/mainClass/${id}`, mainClassUpdateRequest);
  }

  deleteMainClass(id) {
    return this.mcAxiosInstance.delete(`/medication/mainClass/${id}`);
  }

  updateMedication(id, medicationUpdateRequest) {
    return this.mcAxiosInstance.put(`/medication/${id}`, medicationUpdateRequest);
  }

  deleteMedication(id) {
    return this.mcAxiosInstance.delete(`/medication/${id}`);
  }

  addMainClassToMedication(medicationId, mainClassId) {
    return this.mcAxiosInstance.post(`/medication/${medicationId}/mainClass/${mainClassId}`);
  }

  removeMainClassFromMedication(medicationId, mainClassId) {
    return this.mcAxiosInstance.delete(`/medication/${medicationId}/mainClass/${mainClassId}`);
  }

  addAlternateNameToMedication(medicationId, lookupName) {
    return this.mcAxiosInstance.post(`/medication/${medicationId}/lookupName`, {
      lookupName,
    });
  }

  removeAlternateNameFromMedication(medicationId, lookupNameId) {
    return this.mcAxiosInstance.delete(`/medication/${medicationId}/lookupName/${lookupNameId}`);
  }

  createCommonDirectionForMedication(
    medicationId,
    commonDirectionData,
  ) {
    return this.mcAxiosInstance.post(`/medication/${medicationId}/commonDirection`, commonDirectionData);
  }

  deleteCommonDirection(directionId) {
    return this.mcAxiosInstance.delete(`/medication/commonDirection/${directionId}`);
  }

  getAllMedFrequencies() {
    return this.mcAxiosInstance.get('/medication/getAllFrequencies');
  }

  getAllMedRoutes() {
    return this.mcAxiosInstance.get('/medication/getAllRoutes');
  }

  // Score services
  addScoreToReportAction(actionListId, reportSection, scoreId) {
    return this.mcAxiosInstance.post(`/actionList/${actionListId}/add`, { actionType: 'addScoreToReport', reportSection, scoreId });
  }

  getScore(scoreId) {
    return this.mcAxiosInstance.get(`/score/${scoreId}`);
  }

  getAllScores() {
    return this.mcAxiosInstance.get('/score/');
  }

  createScore(uniqueName, reportPreString) {
    return this.mcAxiosInstance.post('/score/', { uniqueName, reportPreString });
  }

  updateScore(scoreId, uniqueName, reportPreString) {
    return this.mcAxiosInstance.put(`/score/${scoreId}`, { uniqueName, reportPreString });
  }

  deleteScore(scoreId) {
    return this.mcAxiosInstance.delete(`/score/${scoreId}`);
  }

  getFactorsForScore(scoreId) {
    return this.mcAxiosInstance.get(`/score/${scoreId}/factors`);
  }

  createScoreFactor(scoreId, scoreFactorCreateRequest) {
    return this.mcAxiosInstance.post(`/score/${scoreId}/createFactor`, scoreFactorCreateRequest);
  }

  updateScoreFactor(scoreFactorId, scoreFactorUpdateRequest) {
    return this.mcAxiosInstance.put(`/score/factor/${scoreFactorId}`, scoreFactorUpdateRequest);
  }

  deleteScoreFactor(scoreFactorId) {
    return this.mcAxiosInstance.delete(`/score/factor/${scoreFactorId}`);
  }

  // Translation utilities services
  getLanguages() {
    return this.mcAxiosInstance.get('/language');
  }

  runPipeline(pipelineName) {
    return this.mcAxiosInstance.post(`/translationUtilities/run/${pipelineName}`);
  }

  getRecentPipelineLogs(numLogs) {
    return this.mcAxiosInstance.get(`/translationUtilities/logs/${numLogs}`);
  }

  getSyncRequired() {
    return this.mcAxiosInstance.get('/translationUtilities/syncRequired');
  }

  // Reason for Visit services
  getReasonForVisit(id) {
    return this.mcAxiosInstance.get(`/reasonForVisit/${id}`);
  }

  getAllReasonsForVisit() {
    return this.mcAxiosInstance.get('/reasonForVisit/');
  }

  createReasonForVisit(reasonForVisitCreateRequest) {
    return this.mcAxiosInstance.post('/reasonForVisit/', reasonForVisitCreateRequest);
  }

  updateReasonForVisit(id, reasonForVisitUpdateRequest) {
    return this.mcAxiosInstance.put(`/reasonForVisit/${id}`, reasonForVisitUpdateRequest);
  }

  deleteReasonForVisit(id) {
    return this.mcAxiosInstance.delete(`/reasonForVisit/${id}`);
  }

  // Template services
  getTemplateLists(category) {
    return this.mcAxiosInstance.get(`/templateList/?category=${category}`);
  }

  getAllTemplatesForList(listId) {
    return this.mcAxiosInstance.get(`/templateList/${listId}/templates`);
  }

  getTemplate(id) {
    return this.mcAxiosInstance.get(`/template/${id}`);
  }

  createTemplateList(templateListCreateRequest) {
    return this.mcAxiosInstance.post('/templateList', templateListCreateRequest);
  }

  updateTemplateList(listId, templateListUpdateRequest) {
    return this.mcAxiosInstance.put(`/templateList/${listId}`, templateListUpdateRequest);
  }

  deleteTemplateList(listId) {
    return this.mcAxiosInstance.delete(`/templateList/${listId}`);
  }

  createTemplate(templateCreateRequest) {
    return this.mcAxiosInstance.post('/template/', templateCreateRequest);
  }

  updateTemplate(templateId, templateUpdateRequest) {
    return this.mcAxiosInstance.put(`/template/${templateId}`, templateUpdateRequest);
  }

  deleteTemplate(templateId) {
    return this.mcAxiosInstance.delete(`/template/${templateId}`);
  }
}
