import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LazyLoadingTreeItem from '../../components/LazyLoadingTreeItem';

export function useMedicalContentTreeItem(label) {
  const [
    MCTreeItemLabel,
    setMCTreeItemLabel,
  ] = useState(label);

  const [expand, setExpand] = useState(false);

  const [MCTreeItemChildNodes, setMCTreeItemChildNodes] = useState(null);

  const updateLabel = (newLabel) => {
    setMCTreeItemLabel(newLabel);
  };

  useEffect(() => {
    setMCTreeItemLabel(label);
  }, [label]);

  return {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setMCTreeItemChildNodes,
    updateLabel,
    setExpand,
  };
}

export default function MedicalContentTreeItem(props) {
  const {
    id,
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    isEndNode,
    fetchChildNodes,
    onSelect,
    expand,
    textToDisplayIfNoChildren,
  } = props;

  return (
    <LazyLoadingTreeItem
      key={`${MCTreeItemLabel}_${id}`}
      nodeId={id.toString()}
      label={MCTreeItemLabel}
      isEndNode={isEndNode}
      expandCallback={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren={textToDisplayIfNoChildren}
    >
      {MCTreeItemChildNodes}
    </LazyLoadingTreeItem>
  );
}

MedicalContentTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  MCTreeItemLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  MCTreeItemChildNodes: PropTypes.array,
  isEndNode: PropTypes.bool,
  fetchChildNodes: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  expand: PropTypes.bool,
  textToDisplayIfNoChildren: PropTypes.string,
};

MedicalContentTreeItem.defaultProps = {
  MCTreeItemChildNodes: null,
  isEndNode: false,
  expand: false,
  fetchChildNodes: null,
  textToDisplayIfNoChildren: 'No children yet...',
};
