import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

import { Controls } from '../../components/controls/Controls';
import useToast from '../../hooks/useToast';
import Form from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';

export default function FixedAnswerFormSubFormAnswer(props) {
  const {
    fieldValues, handleInputChange, fixedAnswerId, refreshParent,
  } = props;

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const validate = () => {
    const errorMsg = {};
    errorMsg.text = fieldValues.text.length > 0 ? '' : 'Answer text is required!';
    setErrors(errorMsg);
    return Object.values(errorMsg).every((item) => item === '');
  };

  // Answer-editing callbacks
  // ----------------
  const handleSave = async () => {
    if (validate()) {
      setLoading(true);
      basicServiceHandler(
        () => apiClient.updateFixedAnswers(
          fixedAnswerId,
          fieldValues.text,
          fieldValues.deselectOtherAnswers,
          fieldValues.helperText,
        ),
        () => {
          refreshParent();
          toastSetSuccessNotification('Updated');
        },
        (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
        () => setLoading(false),
      );
    }
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                label="Answer Text"
                name="text"
                value={fieldValues.text}
                error={!!errors.text}
                helperText={errors.text}
                onChange={handleInputChange}
                multiline
                minRows={1}
                maxRows={5}
                fullWidth
              />
              <TextField
                label="Helper Text"
                name="helperText"
                value={fieldValues.helperText}
                onChange={handleInputChange}
                multiline
                minRows={1}
                maxRows={5}
                fullWidth
              />
              <Controls.Checkbox
                label="Deselect other answers"
                name="deselectOtherAnswers"
                checked={fieldValues.deselectOtherAnswers}
                onChange={handleInputChange}
              />
              <Grid container justifyContent="flex-end">
                <Controls.Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{ margin: '6px' }}
                  loading={loading}
                  text="Save"
                  size="medium"
                />

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

FixedAnswerFormSubFormAnswer.propTypes = {
  fieldValues: PropTypes.shape({
    text: PropTypes.string,
    deselectOtherAnswers: PropTypes.bool,
    helperText: PropTypes.string,
  }),
  handleInputChange: PropTypes.func.isRequired,
  fixedAnswerId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

FixedAnswerFormSubFormAnswer.defaultProps = {
  fieldValues: {
    text: null,
    deselectOtherAnswers: false,
    helperText: null,
  },
};
