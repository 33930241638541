import React, { useState, useEffect } from 'react';

import {
  Box,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Sync from '@mui/icons-material/Sync';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Controls } from '../../components/controls/Controls';
import basicServiceHandler from '../../services/basicServiceHandler';
import useToast from '../../hooks/useToast';
import Modal from '../../components/Modal';
import TranslationModuleLocalesTable from './TranslationModuleLocalesTable';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import TranslationPipelineLogTable from './TranslationPipelineLogTable';
import DataCongruencyWarning from './SyncRequiredWarning';
import useAuth from '../../hooks/useAuth';

const NUM_LOGS_TO_FETCH = 5;
const ENVIRONMENTS_TO_SHOW_TRANSLATION_UTILITIES = ['local', 'development', 'translation-test'];

export default function TranslationPane() {
  const [activeModal, setActiveModal] = useState(null);
  const [moduleLocales, setModuleLocales] = useState({});
  const [syncRequired, setSyncRequired] = useState(true);
  const [pipelineLogs, setPipelineLogs] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { hasPermissions } = useAuth();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const apiClient = useApiClient();

  const fetchModuleLocales = async () => {
    basicServiceHandler(
      () => apiClient.getAllModuleLocales(),
      (response) => setModuleLocales(response.data),
      () => toastSetErrorNotification('Unable to fetch module locales.'),
      () => {},
    );
  };

  const fetchRecentPipelineLogs = async () => {
    basicServiceHandler(
      () => apiClient.getRecentPipelineLogs(NUM_LOGS_TO_FETCH),
      (response) => setPipelineLogs(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const fetchSyncRequired = async () => {
    basicServiceHandler(
      () => apiClient.getSyncRequired(),
      (response) => setSyncRequired(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const hasTranslationPipelinePermissions = hasPermissions({ c2i: ['TranslationPipeline'] });

  const loadAllData = async () => {
    const promises = [fetchModuleLocales()];
    if (hasTranslationPipelinePermissions) {
      promises.push(fetchRecentPipelineLogs(), fetchSyncRequired());
    }

    await Promise.all(promises);
  };

  useEffect(() => {
    loadAllData();
  }, []);

  const handleStagingSnapshot = async () => {
    basicServiceHandler(
      () => apiClient.runPipeline('ContentSnapshot'),
      () => toastSetSuccessNotification('Snapshot started. Use the refresh button to monitor status.'),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      loadAllData,
    );
  };

  const handlePlatformSync = async () => {
    basicServiceHandler(
      () => apiClient.runPipeline('PlatformSync'),
      () => toastSetSuccessNotification('Sync started. Use the refresh button to monitor status.'),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      loadAllData,
    );
  };

  const handlePlatformSyncByLocale = async () => {
    basicServiceHandler(
      () => apiClient.runPipeline('PlatformSyncByLocale'),
      () => toastSetSuccessNotification('Sync started. Use the refresh button to monitor status.'),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      loadAllData,
    );
  };

  const handleTranslationCheck = async () => {
    basicServiceHandler(
      () => apiClient.runPipeline('TranslationCheck'),
      () => toastSetSuccessNotification('Translation check started. Use the refresh button to monitor status.'),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      loadAllData,
    );
  };

  const handleCloseModal = () => setActiveModal(null);

  const handleStagingConfirm = async () => {
    await handleStagingSnapshot();
    handleCloseModal();
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await loadAllData();
      toastSetSuccessNotification('Refreshed');
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          border: '1px solid #c6c6c6',
          borderRadius: '4px',
        }}
      >
        <TranslationModuleLocalesTable moduleLocales={moduleLocales} />
      </Box>
      {ENVIRONMENTS_TO_SHOW_TRANSLATION_UTILITIES.includes(process.env.REACT_APP_ENV)
        && hasTranslationPipelinePermissions && (
        <>
          {syncRequired && <DataCongruencyWarning />}
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '1px solid #c6c6c6',
              borderRadius: '4px',
            }}
          >
            <Controls.Button
              sx={{ margin: '20px' }}
              variant="outlined"
              text="Take Staging Snapshot"
              onClick={() => setActiveModal('staging')}
              startIcon={<CameraAltIcon />}
            />
            <Modal
              isOpen={activeModal === 'staging'}
              handleClose={handleCloseModal}
              title="WARNING"
            >
              <Box>
                <Typography component="div">
                  Are you sure you want to take a content snapshot from staging?
                  <br />
                  <br />
                  Note that ALL content will be updated. Any languages that are currently
                  fully-translated on Lokalise may become partly untranslated. Ensure any
                  fully-translated languages are moved to production if appropriate before
                  proceeding.
                  <br />
                  <br />
                  Also ensure you run a &quot;Sync With Lokalise&quot; after this pipeline succeeds!
                  <div style={{ marginTop: '1em' }}><strong>This action cannot be undone.</strong></div>
                </Typography>
              </Box>
              <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleStagingConfirm} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Modal>
            <Controls.Button
              sx={{ margin: '20px' }}
              variant="outlined"
              text="Sync With Lokalise by Module"
              onClick={handlePlatformSync}
              startIcon={<Sync />}
            />
            <Controls.Button
              sx={{ margin: '20px' }}
              variant="outlined"
              text="Sync With Lokalise By Locale"
              onClick={handlePlatformSyncByLocale}
              startIcon={<SyncAltIcon />}
            />
            <Controls.Button
              sx={{ margin: '20px' }}
              variant="outlined"
              text="Translation Check"
              onClick={handleTranslationCheck}
              startIcon={<PlaylistAddCheckIcon />}
            />
          </Box>
          <br />
          <Box
            sx={{
              pl: 2,
              border: '1px solid #c6c6c6',
              borderRadius: '4px',
              maxWidth: 'fit-content',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" my={2}>
                Recent Pipeline Runs
              </Typography>
              <Controls.Button
                onClick={handleRefresh}
                sx={{ margin: '12px' }}
                loading={refreshing}
                text="Refresh"
              />
            </div>

            <TranslationPipelineLogTable pipelineLogs={pipelineLogs} />
          </Box>
        </>
      )}
    </>
  );
}
