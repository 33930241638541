import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import SymptomPane from './SymptomPane';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

export default function SymptomTreeItem(props) {
  const {
    symptomId, label, setFormPane, refreshParent, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemLabel,
    expand,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const apiClient = useApiClient();

  const onSelect = () => {
    setFormPane(
      <SymptomPane
        key={`sxPane_${symptomId}`}
        symptomId={symptomId}
        updateCallback={updateLabel}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the Symptom "${label}"?`,
      deleteService: () => apiClient.deleteSymptom(symptomId),
      raiseService: null,
      lowerService: null,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={symptomId}
      MCTreeItemLabel={MCTreeItemLabel}
      isEndNode
      onSelect={onSelect}
      expand={expand}
    />
  );
}

SymptomTreeItem.propTypes = {
  symptomId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
