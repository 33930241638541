import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, CssBaseline, Paper, Box, Grid, Typography, Link as MuiLink,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import validator from 'validator';
import { Controls } from '../components/controls/Controls';
import { useForm } from '../components/useForm';
import { sendVerificationCode, verifyMfaCode } from '../services/auth/authServices';

const initialFieldValues = {
  phoneNumber: '',
  mfa: '',
};

const theme = createTheme();

export function Layout({ img, children }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {children}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  img: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export function InnerBoxLayout({ title, subTitle, children }) {
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
      {children}
    </Box>
  );
}

InnerBoxLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node.isRequired,
};

InnerBoxLayout.defaultProps = {
  subTitle: '',
};

export default function FhxMfaSignUp(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [mfaCode, setMfaCode] = useState('');
  const userDetails = location.state || {};
  const [isMfaCodeSent, setIsMfaCodeSent] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const { img } = props;

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const handleMfaCodeChange = (e) => {
    setMfaCode(e.target.value);
  };

  const handlePhoneChange = async (phone) => {
    handleInputChange({ target: { name: 'phoneNumber', value: phone } });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let errorMsg = {};

    if (!values.phoneNumber) {
      errorMsg.phoneNumber = 'Phone number is required';
    }
    if (!validator.isMobilePhone(values.phoneNumber)) {
      errorMsg.phoneNumber = 'Valid phone number is required';
    } else {
      errorMsg.phoneNumber = '';
    }
    if (!isMfaCodeSent) {
      if (errorMsg.phoneNumber) {
        setErrors(errorMsg);
        setLoading(false);
        return;
      }
      setErrors(errorMsg);
      try {
        await sendVerificationCode(
          userDetails.username,
          values.phoneNumber,
          userDetails.signUpToken,
        );
        setIsMfaCodeSent(true);
      } catch (error) {
        errorMsg.phoneNumber = 'Failed to send SMS verification code';
        setErrors(errorMsg);
      }
    } else {
      errorMsg = {};
      if (!mfaCode) {
        errorMsg.mfaCode = 'MFA code is required';
      }

      if (Object.keys(errorMsg).length > 0) {
        setErrors(errorMsg);
        setLoading(false);
        return;
      }

      try {
        const response = await verifyMfaCode({
          username: userDetails.username,
          phoneNumber: values.phoneNumber,
          mfaVerificationCode: mfaCode,
          signupToken: userDetails.signUpToken,
          mfaMethod: 'SMS',
        });
        if (response.status === 200) {
          setUserUpdated(true);
        } else {
          errorMsg.mfaCode = 'Invalid verification code';
          setErrors(errorMsg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          errorMsg.mfaCode = 'Invalid verification code';
        } else {
          errorMsg.mfaCode = 'Failed to sign up for multi factor authorization';
        }
        setErrors(errorMsg);
      }
    }
    setLoading(false);
  };

  if (userUpdated) {
    return (
      <Layout img={img}>
        <InnerBoxLayout title="Success!" subTitle="Multi-factor authorization has been enabled for your account!">
          <Controls.Button
            text="Continue"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ margin: '20px 0px' }}
            onClick={() => navigate('/home')}
          />
        </InnerBoxLayout>
      </Layout>
    );
  }

  const isValidEmail = (email) => validator.isEmail(email);

  return (
    <Layout img={img}>
      <InnerBoxLayout
        title="Multi-factor Authentication Setup"
        subTitle={(
          <>
            <br />
            We require your phone number to enable multi-factor authentication,
            which provides an additional layer of security for your account.
            <br />
            <br />
            We will send you a verification code to confirm the number.
          </>
        )}
      >
        <Box component="form" noValidate={false} onSubmit={handleSubmit} sx={{ mt: 1, width: '100%', textAlign: 'center' }}>
          <PhoneInput
            country="ca"
            value={values.phoneNumber}
            preferredCountries={['us', 'ca', 'ae', 'sa']}
            onChange={handlePhoneChange}
            placeholder="Enter phone number"
            inputProps={{
              'data-testid': 'phoneNumber',
            }}
            containerStyle={{ width: '100%', margin: '10px 0px' }}
            inputStyle={{ width: '100%' }}
          />
          {errors.phoneNumber && <Typography variant="caption" color="error">{errors.phoneNumber}</Typography>}
          {isMfaCodeSent && (
            <Controls.Input
              fullWidth
              label="Code"
              name="mfaCode"
              value={mfaCode}
              error={errors.mfaCode}
              onChange={handleMfaCodeChange}
              sx={{ margin: '10px 0px' }}
            />
          )}
          <Controls.Button
            type="submit"
            text={isMfaCodeSent ? 'Verify' : 'Submit'}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ margin: '20px 0px' }}
            disabled={loading}
          />
          {userDetails.username && isValidEmail(userDetails.username)
            && (
            <MuiLink component={Link} to="/email-mfa" state={userDetails}>
              I don&apos;t have a phone
            </MuiLink>
            )}
        </Box>
      </InnerBoxLayout>
    </Layout>
  );
}

FhxMfaSignUp.propTypes = {
  img: PropTypes.string,
};

FhxMfaSignUp.defaultProps = {
  img: '',
};
