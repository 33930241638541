import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import AttributeCreateEditForm from '../attribute/createAndEdit/AttributeCreateEditForm';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';
import { useForm } from '../../../components/useForm';
import AttributeFollowersForm from './AttributeFollowersForm';

export default function AttributePane(props) {
  const {
    attributeId, refreshParent,
  } = props;

  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(null);

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getAttributeById(attributeId),
      (response) => setValues(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  }, [attributeId]);

  const onSubmit = () => apiClient.updateAttribute(attributeId, values);

  return (
    values
      ? (
        <>
          <AttributeCreateEditForm
            values={values}
            handleInputChange={handleInputChange}
            callbacks={{
              service: onSubmit,
              onSuccess: refreshParent,
              successMsg: 'Saved',
              errorMsg: 'Error saving attribute',
            }}
          />
          <AttributeFollowersForm
            attributeId={attributeId}
            contextType={values.contextType}
          />
        </>
      ) : <CircularProgress />
  );
}

AttributePane.propTypes = {
  attributeId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
