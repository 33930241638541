import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { Controls } from '../../../components/controls/Controls';
import Modal from '../../../components/Modal';
import Form from '../../../components/useForm';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';

export default function IncludeDxhxAdditionButton(props) {
  const {
    actionListId, reloadCallback,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dxhxs, setDxhxs] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [chosenDxhxId, setChosenDxhxId] = useState('');

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const apiClient = useApiClient();

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getAllDxHx(),
      (result) => setDxhxs(result.data),
      () => toastSetErrorNotification('Unable to load dxhxs.'),
      () => {},
    );
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    setServiceLoading(true);
    basicServiceHandler(
      () => apiClient.addAddDxhxAction(actionListId, chosenDxhxId),
      () => { toastSetSuccessNotification('Successful!'); reloadCallback(); },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => setServiceLoading(false),
    );
    setModalIsOpen(false);
  };

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={() => setModalIsOpen(true)}
        sx={{ margin: '6px' }}
        text="Include A DxHx"
      />

      <Modal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        title="Include A DxHx"
      >
        <Form onSubmit={handleAdd}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                select
                label="Select a Dxhx to include"
                name="attributeValueId"
                value={chosenDxhxId}
                onChange={(e) => setChosenDxhxId(e.target.value)}
              >
                {dxhxs.map(
                  (dxhx) => (
                    <MenuItem key={dxhx.id} value={dxhx.id}>
                      {dxhx.name}
                    </MenuItem>
                  ),
                )}
              </Controls.Input>
              <Grid container justifyContent="flex-end">
                <Controls.Button
                  variant="contained"
                  type="submit"
                  sx={{ margin: '6px' }}
                  loading={serviceLoading}
                  text="Include"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Modal>
    </>
  );
}

IncludeDxhxAdditionButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
};

IncludeDxhxAdditionButton.defaultProps = {
  reloadCallback: null,
};
