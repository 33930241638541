import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../components/controls/Dropdown';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

function MainClassDropdownAdditionForm({
  mainClassesList,
  addMainClassToMedication,
}) {
  const [selectedMainClassId, setSelectedMainClassId] = useState('');

  const handleAddMainClass = async () => {
    await addMainClassToMedication(selectedMainClassId);
  };

  return (
    <>
      <Dropdown
        name="existingMainClasses"
        label="Select a Medication Class"
        value={selectedMainClassId}
        fullWidth
        onChange={(e) => setSelectedMainClassId(e.target.value)}
        options={mainClassesList.map((mainClass) => ({
          id: mainClass.id,
          name: mainClass.name,
        }))}
      />
      <ServiceButtonWithToast
        buttonText="Add"
        service={handleAddMainClass}
        successMsg="Medication class added!"
        errorMsg="Unable to add medication class"
      />
    </>
  );
}

MainClassDropdownAdditionForm.propTypes = {
  mainClassesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  addMainClassToMedication: PropTypes.func.isRequired,
};

export default MainClassDropdownAdditionForm;
