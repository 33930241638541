import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ButtonWithModal from '../../../components/ButtonWithModal';
import AddExistingModuleButton from './AddExistingModuleButton';
import CreateModuleButton from '../../Module/CreateModuleButton';

function ModuleAdditionButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const newReloadCallback = () => {
    setModalIsOpen(false);
    reloadCallback();
  };

  return (
    <ButtonWithModal
      buttonText="Add Module"
      modalTitle="Add Module"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Grid container>
        <Grid item xs={6}>
          <CreateModuleButton
            actionListId={actionListId}
            reloadCallback={newReloadCallback}
          />
        </Grid>
        <Grid item xs={6}>
          <AddExistingModuleButton
            actionListId={actionListId}
            reloadCallback={newReloadCallback}
          />
        </Grid>
      </Grid>
    </ButtonWithModal>
  );
}

ModuleAdditionButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default ModuleAdditionButton;
