import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@mui/material';
import DOMPurify from 'dompurify';
import { Controls } from '../../../components/controls/Controls';

const makeReportSample = (
  preStringRaw,
  attributeValueReportString,
  postStringRaw,
) => {
  if (!attributeValueReportString) {
    return '--not printed to report--';
  }
  // Always ensure there is exactly one space after preString if preString is not empty
  // and does not end with a quotation mark.
  const attributePreString = (
    preStringRaw && !preStringRaw.match(/["']$/)
      ? `${preStringRaw.trimEnd()} `
      : preStringRaw
  );

  // Always ensure there is exactly one space before postString if postString is not empty
  // and does not start with punctuation.
  const attributePostString = (
    postStringRaw && !postStringRaw.match(/^[.,"'!?:;]/)
      ? ` ${postStringRaw.trimStart()}`
      : postStringRaw
  );

  return `${attributePreString}${attributeValueReportString}${attributePostString}`;
};

function AttributeValueFormContent({
  values, attributeReportStrings, handleInputChange, disabled,
}) {
  const reportSample = makeReportSample(
    attributeReportStrings.preString,
    values.reportText,
    attributeReportStrings.postString,
  );

  return (
    <Grid container>
      <Grid item xs={10}>
        <Controls.Input
          disabled={disabled}
          label="C2i Name"
          name="c2iName"
          value={values.c2iName}
          onChange={handleInputChange}
          fullWidth
        />
        <Controls.Input
          disabled={disabled}
          label="Report Text"
          name="reportText"
          value={values.reportText}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={9}
        style={{ marginLeft: '12px' }}
      >
        <InputLabel shrink htmlFor="report-sample">
          Report Sample
        </InputLabel>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
          }}
          /* eslint-disable-next-line */
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reportSample || null) }}
        />
      </Grid>
    </Grid>
  );
}

export const attributeValueFieldValuesPropType = PropTypes.shape({
  c2iName: PropTypes.string,
  reportText: PropTypes.string,
});

AttributeValueFormContent.propTypes = {
  values: attributeValueFieldValuesPropType.isRequired,
  attributeReportStrings: PropTypes.shape({
    preString: PropTypes.string,
    postString: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
};

AttributeValueFormContent.defaultProps = {
  disabled: false,
  handleInputChange: null,
};

export default AttributeValueFormContent;
