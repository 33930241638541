import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

export default function ServiceButtonWithToast(props) {
  const {
    disabled,
    buttonText,
    size,
    variant,
    service,
    onSuccess,
    successMsg,
    errorMsg,
  } = props;
  const [serviceLoading, setServiceLoading] = useState(false);

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const successCB = (result) => {
    onSuccess(result);
    if (successMsg) toastSetSuccessNotification(successMsg);
  };

  const failCB = (error) => {
    toastSetErrorNotification(`${errorMsg}: ${convertAxiosErrorToUserFacingMessage(error)}`);
  };

  const finalCB = () => {
    setServiceLoading(false);
  };

  const handleClick = async () => {
    setServiceLoading(true);
    basicServiceHandler(service, successCB, failCB, finalCB);
  };

  return (
    <Controls.Button
      disabled={disabled}
      variant={variant}
      onClick={handleClick}
      sx={{ margin: '6px' }}
      loading={serviceLoading}
      text={buttonText}
      size={size}
    />
  );
}

ServiceButtonWithToast.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  service: PropTypes.func,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
  errorMsg: PropTypes.string,
};

ServiceButtonWithToast.defaultProps = {
  disabled: false,
  size: 'medium',
  variant: 'contained',
  service: () => {},
  onSuccess: () => {},
  successMsg: null,
  errorMsg: 'Error',
};
