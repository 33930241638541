import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import { Controls } from '../../../components/controls/Controls';
import ReportSectionDropdown from '../../_common/ReportSectionDropdown';

function AttributeFormContent(props) {
  const {
    values, handleInputChange, controlsDisabled,
  } = props;

  return (
    <Grid container direction="column">
      <Grid item>
        <Controls.Input
          disabled={controlsDisabled}
          select
          label={controlsDisabled ? 'Context type' : 'Select a context type'}
          name="contextType"
          value={values.contextType}
          onChange={handleInputChange}
          fullWidth
        >
          <MenuItem key="symptom" value="symptom">Symptom</MenuItem>
          <MenuItem key="patient" value="patient">Patient</MenuItem>
          <MenuItem key="medication" value="medication">Medication</MenuItem>
        </Controls.Input>
      </Grid>
      <Grid item>
        <Controls.Input
          disabled={controlsDisabled}
          label="Unique Name"
          name="uniqueName"
          value={values.uniqueName}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Controls.Input
          disabled={controlsDisabled}
          label="Report PreText"
          name="reportPrestring"
          value={values.reportPrestring}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Controls.Input
          disabled={controlsDisabled}
          label="Report PostText"
          name="reportPoststring"
          value={values.reportPoststring}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <ReportSectionDropdown
          selectedSection={values.reportSection}
          onChange={handleInputChange}
          disabled={controlsDisabled}
        />
      </Grid>
      <Grid item>
        <Controls.Input
          disabled={controlsDisabled}
          select
          label="Report Style"
          name="reportStyle"
          value={values.reportStyle}
          onChange={handleInputChange}
          fullWidth
        >
          <MenuItem key="reportStyleOneLine" value="oneLine">One Line</MenuItem>
          <MenuItem key="reportStyleTwoLines" value="twoLine">Two Lines</MenuItem>
          <MenuItem key="reportStyleBulletedList" value="bulletedList">Bulleted List</MenuItem>
        </Controls.Input>
      </Grid>
      <Grid item>
        <Controls.Checkbox
          disabled={controlsDisabled}
          label="Add empty line above"
          name="reportEmptyLineAbove"
          checked={values.reportEmptyLineAbove}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export const attributeFieldValuesPropType = PropTypes.shape({
  contextType: PropTypes.oneOf(['symptom', 'patient', 'medication', '']),
  uniqueName: PropTypes.string,
  reportPrestring: PropTypes.string,
  reportPoststring: PropTypes.string,
  reportSection: PropTypes.oneOf(['top', 'bottom', 'middle', '']),
  reportStyle: PropTypes.oneOf(['oneLine', 'twoLine', 'bulletedList']),
  reportEmptyLineAbove: PropTypes.bool,
});

AttributeFormContent.propTypes = {
  controlsDisabled: PropTypes.bool,
  values: attributeFieldValuesPropType.isRequired,
  handleInputChange: PropTypes.func,
};

AttributeFormContent.defaultProps = {
  controlsDisabled: false,
  handleInputChange: null,
};

export default AttributeFormContent;
