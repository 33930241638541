import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form from '../../../../components/useForm';
import AttributeValueFormContent from '../AttributeValueFormContent';
import ServiceButtonWithToast from '../../../_common/ServiceButtonWithToast';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export default function AttributeValueCreateEditForm(props) {
  const {
    values, attributeReportStrings, handleInputChange, callbacks,
  } = props;
  const {
    service, onSuccess, successMsg, errorMsg,
  } = callbacks;

  const serviceWithValidation = () => {
    if (!(values.c2iName || values.reportText)) {
      return Promise.reject(new Error('No value entered.'));
    }
    return service(values);
  };

  return (
    <Form>

      <AttributeValueFormContent
        values={values}
        attributeReportStrings={attributeReportStrings}
        handleInputChange={handleInputChange}
      />

      <Grid container justifyContent="flex-end">
        <ServiceButtonWithToast
          buttonText="Save"
          service={serviceWithValidation}
          onSuccess={onSuccess}
          successMsg={successMsg}
          errorMsg={errorMsg}
        />
      </Grid>

    </Form>
  );
}

AttributeValueCreateEditForm.propTypes = {
  values: PropTypes.shape({
    c2iName: PropTypes.string,
    reportText: PropTypes.string,
  }).isRequired,
  attributeReportStrings: PropTypes.shape({
    preString: PropTypes.string,
    postString: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func,
  callbacks: serviceCallbackPropTypes.isRequired,
};

AttributeValueCreateEditForm.defaultProps = {
  handleInputChange: null,
};
