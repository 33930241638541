import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Controls } from '../../components/controls/Controls';

function NumericSliderDetailsForm({ values, handleInputChange, errors }) {
  const getMinMaxValueLabel = (type) => {
    switch (type) {
      case 'gestationalAge': return 'Days';
      case 'weight': return 'Kg';
      case 'height': return 'cm';
      default: return '';
    }
  };

  return (
    <>
      <Controls.Select
        label="Slider Type"
        value={values.sliderType}
        name="sliderType"
        onChange={handleInputChange}
        input={<OutlinedInput label="Slider Type" />}
        error={errors.sliderType}
        required
      >
        <MenuItem value="gestationalAge">Gestational Age</MenuItem>
        <MenuItem value="weight">Weight</MenuItem>
        <MenuItem value="height">Height</MenuItem>
      </Controls.Select>
      {values.sliderType && (
        <>
          <Controls.Input
            name="minValue"
            label={`Minimum Value (${getMinMaxValueLabel(values.sliderType)})`}
            value={values.minValue}
            onChange={handleInputChange}
            error={errors.minValue}
            type="number"
          />
          <Controls.Input
            name="maxValue"
            label={`Maximum Value (${getMinMaxValueLabel(values.sliderType)})`}
            value={values.maxValue}
            onChange={handleInputChange}
            error={errors.maxValue}
            type="number"
          />
          <Controls.Input
            name="stepSize"
            label="Increment Size"
            value={values.stepSize}
            onChange={handleInputChange}
            error={errors.stepSize}
            type="number"
          />
        </>
      )}
    </>
  );
}

NumericSliderDetailsForm.propTypes = {
  values: PropTypes.shape({
    sliderType: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    stepSize: PropTypes.number,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    minValue: PropTypes.string,
    maxValue: PropTypes.string,
    stepSize: PropTypes.string,
    sliderType: PropTypes.string,
  }).isRequired,
};

export default NumericSliderDetailsForm;
