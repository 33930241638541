import React from 'react';

import { Typography } from '@mui/material';

export default function SyncRequiredWarning() {
  return (
    <Typography style={{
      color: 'red',
      fontWeight: 'bold',
      fontSize: '1.1rem',
      margin: '1rem 0',
    }}
    >
      A new snapshot of the medical content has been taken, but
      Lokalise has not been updated.
      <br />
      Be sure to run &quot;Sync With Lokalise&quot;
      as soon as possible!
    </Typography>
  );
}
