import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import { useForm } from '../../components/useForm';
import ConditionCreateSubForm from '../Condition/ConditionCreateSubForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ScoreFactorConditionForm(props) {
  const {
    initialFieldValues, onSubmit, onSuccess, onFail,
  } = props;
  const [condition, setCondition] = useState(null);

  const {
    values,
    setValues,
    errors,
    setErrors,
  } = useForm(initialFieldValues);

  const handleSubmit = () => {
    const points = parseFloat(values.points);
    if (points === 0) {
      setErrors({ points: 'Points required.' });
      return Promise.reject(new Error('Form Validation Error'));
    }
    return onSubmit({ points, condition });
  };

  const handleNumberChange = (e) => {
    const newValue = e.target.value.replace(/[^\d.-]+/g, '');
    setValues({ ...values, [e.target.name]: newValue });
  };

  return (
    <>
      <Typography>
        Select a Condition
      </Typography>
      <ConditionCreateSubForm
        onSubmit={(result) => {
          setCondition(result);
          return Promise.resolve(null);
        }}
        onSuccess={onSuccess}
        onFail={() => {}}
      />
      {condition !== null
      && (
      <>
        <Typography>
          How many points to add if the condition is true
        </Typography>
        <Controls.Input
          value={values.points !== 0 ? values.points : ''}
          name="points"
          onChange={handleNumberChange}
          label="Points"
          inputProps={{ inputMode: 'numeric', pattern: '-?[0-9.]*' }}
          error={errors.points}
        />

        <Grid container justifyContent="flex-end">
          <ServiceButtonWithToast
            disabled={values.condition && values.points}
            buttonText="Save"
            service={handleSubmit}
            onSuccess={onSuccess}
            onFail={onFail}
          />
        </Grid>
      </>
      )}
    </>
  );
}

ScoreFactorConditionForm.propTypes = {
  initialFieldValues: PropTypes.shape({
    points: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

ScoreFactorConditionForm.defaultProps = {
  initialFieldValues: { points: 0 },
};
