import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Controls } from './Controls';

function Dropdown(props) {
  const {
    name, value, onChange, label, options, error, ...other
  } = props;
  return (
    <Controls.Input
      value={value ?? ''}
      name={name}
      onChange={onChange}
      select
      label={label}
      error={error}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ))}
    </Controls.Input>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.string, // Add the error prop here
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

Dropdown.defaultProps = {
  value: '',
  error: null,
  label: '',
};

export default Dropdown;
