import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import SymptomForm from './SymptomForm';
import ButtonWithModal from '../../components/ButtonWithModal';

export default function SymptomParentPane(props) {
  const {
    refreshParent,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const onSubmit = (values) => apiClient.createSymptom(
    values.medicalName,
    values.reportName,
    values.layName,
    values.laySubstitutor,
    values.helperText,
  );

  const onSuccess = () => {
    refreshParent();
    setModalIsOpen(false);
    toastSetSuccessNotification('Symptom created.');
  };

  const onFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  return (
    <ButtonWithModal
      buttonText="Create Symptom"
      modalTitle="Create Symptom"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <SymptomForm
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onFail={onFail}
      />
    </ButtonWithModal>
  );
}

SymptomParentPane.propTypes = {
  refreshParent: PropTypes.func.isRequired,
};
