import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { useForm } from '../../../components/useForm';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import Dropdown from '../../../components/controls/Dropdown';
import useToast from '../../../hooks/useToast';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function SymptomEndorsedSubForm(props) {
  const initialFieldValues = {
    selectedSymptom: '',
  };

  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const {
    toastSetErrorNotification,
  } = useToast();

  const {
    values,
    handleInputChange,
  } = useForm(initialFieldValues);

  const { selectedSymptom } = values;

  const apiClient = useApiClient();

  const [symptoms, setSymptoms] = useState([]);

  const fetchSymptoms = () => {
    basicServiceHandler(
      () => apiClient.getSymptoms(),
      (response) => setSymptoms(response.data),
      (error) => toastSetErrorNotification(`Error fetching symptoms: ${convertAxiosErrorToUserFacingMessage(error)}`),
      () => {},
    );
  };

  useEffect(fetchSymptoms, []);

  const handleSave = () => {
    const condition = {
      conditionType: 'symptomEndorsed',
      symptomId: selectedSymptom,
    };
    return onSubmit(condition);
  };

  return (
    <>
      <Box>
        <Dropdown
          value={selectedSymptom}
          name="selectedSymptom"
          onChange={handleInputChange}
          label="Select a Symptom"
          options={symptoms.map((symptom) => ({ id: symptom.id, name: symptom.medicalName }))}
        />
      </Box>
      {selectedSymptom && (
        <Grid container>
          <ServiceButtonWithToast
            buttonText="Save"
            service={handleSave}
            onSuccess={onSuccess}
            successMsg={successMsg}
          />
        </Grid>
      )}
    </>
  );
}

SymptomEndorsedSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

SymptomEndorsedSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
