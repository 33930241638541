import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../../components/controls/Controls';
import Modal from '../../../components/Modal';
import ConditionCreateSubForm from '../../Condition/ConditionCreateSubForm';
import { useApiClient } from '../../../context/ApiClientContext';
import Form from '../../../components/useForm';

export default function ConditionalAdditionButton(props) {
  const { actionListId, reloadCallback } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiClient = useApiClient();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={handleModalOpen}
        sx={{ margin: '6px' }}
        text="Add Logic Branchpoint"
      />
      <Modal isOpen={isModalOpen} handleClose={handleModalClose} title="Add Logic Branchpoint">
        <Form>
          <ConditionCreateSubForm
            onSubmit={(condition) => apiClient.addConditional(actionListId, { condition })}
            onSuccess={() => {
              reloadCallback();
              setIsModalOpen(false);
            }}
            successMsg="Conditional created."
          />
        </Form>
      </Modal>
    </>
  );
}

ConditionalAdditionButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
};

ConditionalAdditionButton.defaultProps = {
  reloadCallback: null,
};
