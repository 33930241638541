import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import ActionAdditionForm from '../Actions/ActionAdditionForm';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import renderActionList from '../Actions/renderActionList';

export default function SxOfDxhxTreeItem(props) {
  const {
    sxOfDxhxId, dxhxId, actionListId, label, setFormPane, refreshParent, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListId,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <ActionAdditionForm
        actionListId={actionListId}
        reloadCallback={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Remove the Symptom "${label}" from the DxHx?`,
      deleteService: () => apiClient.removeSymptomFromDxhx(dxhxId, sxOfDxhxId),
      raiseService: null,
      lowerService: null,
      onSuccess: refreshParent,
    });
  };

  // prefetch children to accurately display chevron
  useEffect(() => {
    fetchChildNodes();
  }, []);

  return (
    <MedicalContentTreeItem
      key={`sxOfDxhx_${sxOfDxhxId}`}
      id={sxOfDxhxId}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      isEndNode={MCTreeItemChildNodes && MCTreeItemChildNodes.length === 0}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
    />
  );
}

SxOfDxhxTreeItem.propTypes = {
  sxOfDxhxId: PropTypes.number.isRequired,
  dxhxId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  actionListId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
