import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../../context/ApiClientContext';
import useToast from '../../../../hooks/useToast';
import basicServiceHandler from '../../../../services/basicServiceHandler';
import Dropdown from '../../../../components/controls/Dropdown';

export default function AttributeValueSelector(props) {
  const {
    attributeIdToFindValuesFor,
    selectedAttributeValueId,
    setSelectedAttributeValueId,
  } = props;

  const [attributeValueOptions, setAttributeValueOptions] = useState([]);

  const apiClient = useApiClient();

  const {
    toastSetErrorNotification,
  } = useToast();

  const getAttributeValuesSuccessCB = (result) => {
    if (result.data.length) setAttributeValueOptions(result.data);
  };

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getAttributeValues(attributeIdToFindValuesFor),
      getAttributeValuesSuccessCB,
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  }, [attributeIdToFindValuesFor]);

  return (
    <Dropdown
      value={selectedAttributeValueId || ''}
      name="selectedValue"
      onChange={(e) => setSelectedAttributeValueId(e.target.value)}
      label="Select a Value (C2i Name)"
      options={attributeValueOptions.map((val) => ({ id: val.id, name: val.c2iName }))}
    />
  );
}

AttributeValueSelector.propTypes = {
  attributeIdToFindValuesFor: PropTypes.number.isRequired,
  selectedAttributeValueId: PropTypes.number,
  setSelectedAttributeValueId: PropTypes.func.isRequired,
};

AttributeValueSelector.defaultProps = {
  selectedAttributeValueId: '',
};
