import axios from 'axios';

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_ENDPOINT;
const INTAKE_BASE_URL = process.env.REACT_APP_INTAKE_ENDPOINT;
const MEDICAL_CONTENT_BASE_URL = process.env.REACT_APP_MEDICAL_CONTENT_ENDPOINT;

export const axiosIntakeInstance = axios.create({
  baseURL: INTAKE_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosMedicalContentInstance = axios.create({
  baseURL: MEDICAL_CONTENT_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
