import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

import useToast from '../../hooks/useToast';
import AnswerAdditionButton from './AnswerAdditionButton';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import FreeTextPage from './FreeTextPage';
import QuestionFormSubFormQuestion from './QuestionFormSubFormQuestion';
import QuestionFormSubFormAttribute from './QuestionFormSubFormAttribute';
import { useForm } from '../../components/useForm';

const QUESTION_TYPES_ALLOWING_FIXED_ANSWERS_CREATION = ['selectOne', 'selectAll'];
const QUESTION_TYPES_REQUIRING_NUMERIC_ATTRIBUTE = ['numberForm', 'numberFormSlider'];

export default function QuestionForm(props) {
  const {
    questionId,
    updateCallback,
    reloadCallback,
  } = props;

  const apiClient = useApiClient();

  const [defaultAttributeView, setDefaultAttributeView] = useState(null);

  const {
    values: questionFieldValues,
    setValues: setQuestionFieldValues,
    handleInputChange,
  } = useForm(null);

  const {
    toastSetErrorNotification,
  } = useToast();

  const refreshValues = () => basicServiceHandler(
    () => apiClient.getQuestion(questionId),
    (result) => {
      const {
        questionType, text, defaultAttribute, units, html, helperText,
        minValue, maxValue, stepSize, sliderType, isAboutPii,
      } = result.data;
      const unitsStr = units?.join(',');

      setQuestionFieldValues({
        questionType,
        questionText: text,
        units: unitsStr ?? '',
        html: html ?? '',
        helperText: helperText ?? '',
        minValue: minValue ?? 0,
        maxValue: maxValue ?? 200,
        stepSize: stepSize ?? 1,
        sliderType: sliderType ?? '',
        isAboutPii,
      });
      setDefaultAttributeView(defaultAttribute);
    },
    (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    () => { },
  );

  useEffect(refreshValues, [questionId]);

  if (!questionFieldValues) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  const { questionType } = questionFieldValues;

  /* Do not display the question form if question type is medication.
     Users will be adding medication list question through a module rather than
     directly adding as a question type.
  */
  if (questionType === 'medicationForm' || questionType === 'fileUploadQuestion') {
    return null;
  }

  if (questionType === 'text') {
    return (
      <FreeTextPage
        questionId={questionId}
        html={questionFieldValues.html}
        updateCallback={updateCallback}
      />
    );
  }

  if (questionType === 'signature') {
    return (
      <FreeTextPage
        questionType="signature"
        questionId={questionId}
        html={questionFieldValues.html}
        updateCallback={updateCallback}
      />
    );
  }

  const numericAttributeIsRequired = QUESTION_TYPES_REQUIRING_NUMERIC_ATTRIBUTE.includes(
    questionType,
  );
  const fixedAnswerCreationIsAllowed = QUESTION_TYPES_ALLOWING_FIXED_ANSWERS_CREATION.includes(
    questionType,
  );

  return (
    <>
      <QuestionFormSubFormQuestion
        values={questionFieldValues}
        handleInputChange={handleInputChange}
        questionId={questionId}
        updateCallback={updateCallback}
      />
      {fixedAnswerCreationIsAllowed
        && <AnswerAdditionButton id={questionId} reloadCallback={reloadCallback} />}
      <Paper elevation={3}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Typography variant="h6" gutterBottom>
            Default Attribute
          </Typography>
          <QuestionFormSubFormAttribute
            attributeId={defaultAttributeView?.id}
            attributeFieldValues={defaultAttributeView}
            questionId={questionId}
            refreshValues={refreshValues}
            requiredAttributeSubtype={numericAttributeIsRequired ? 'numeric' : 'string'}
          />
        </Box>
      </Paper>
    </>
  );
}

QuestionForm.propTypes = {
  questionId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
  reloadCallback: PropTypes.func,
};

QuestionForm.defaultProps = {
  updateCallback: null,
  reloadCallback: null,
};
