import React, { useState } from 'react';
import { FormGroup } from '@mui/material';
import { useTheme } from '@emotion/react';

export function useForm(initialFieldValues) {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name } = e.target;
    // if a checkbox was clicked, toggle values[name], otherwise use e.target.value
    const value = e.target.type === 'checkbox' ? (!values[name]) : e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  };
}

export default function Form(props) {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  const theme = useTheme();
  return (
    <form
      autoComplete="off"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <FormGroup
        sx={{
          '& .MuiFormControl-root': {
            width: '90%',
            margin: theme.spacing(1),
          },
        }}
      >
        {children}
      </FormGroup>
    </form>
  );
}
