import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form, { useForm } from '../../components/useForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';
import MedicationDropdown from './MedicationDropdown';

const initialFieldValues = {
  medicationId: '',
};
export default function MedicationSelector(props) {
  const {
    reloadCallback, handleMedicationAdd, buttonText, successMsg,
  } = props;

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  // Function to check if the selected input is valid
  const isValidInput = () => {
    const errorMsg = {};
    errorMsg.medication = values.medication === '' ? 'Please select a medication.' : '';
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleButtonClick = async () => {
    if (isValidInput()) {
      return handleMedicationAdd(values.medicationId); // Return the promise
    }
    return null;
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <MedicationDropdown
            label="Select a Medication"
            name="medicationId"
            value={values.medicationId}
            onChange={handleInputChange}
            error={errors.medication}
          />
          <ServiceButtonWithToast
            onSuccess={reloadCallback}
            service={handleButtonClick}
            buttonText={buttonText}
            successMsg={successMsg}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

MedicationSelector.propTypes = {
  reloadCallback: PropTypes.func,
  handleMedicationAdd: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  successMsg: PropTypes.string.isRequired,
};

MedicationSelector.defaultProps = {
  reloadCallback: null,
};
