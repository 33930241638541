import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Controls } from '../components/controls/Controls';
import useToast from '../hooks/useToast';
import { sendEmailMfaCode, verifyMfaCode } from '../services/auth/authServices';
import { useForm } from '../components/useForm';
import { InnerBoxLayout, Layout } from './FhxMfaSignUp';

const initialFieldValues = {
  mfaCode: '',
};

function FhxEmailMfaSetup(props) {
  const [isMfaCodeSent, setIsMfaCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailMfaEnabled, setEmailMfaEnabled] = useState(false);
  const location = useLocation();
  const userDetails = location.state || {};
  const navigate = useNavigate();
  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();
  const { img } = props;

  const {
    values,
    handleInputChange,
    errors,
    setErrors,
  } = useForm(initialFieldValues);

  const handleSendEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await sendEmailMfaCode(userDetails.signUpToken);
      toastSetSuccessNotification('Verification code sent to your email.');
      setIsMfaCodeSent(true);
    } catch (err) {
      toastSetErrorNotification('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    const errorMsg = {};
    setErrors({});
    if (!values.mfaCode) {
      errorMsg.mfaCode = 'MFA code is required! Please check your email.';
    }

    if (Object.keys(errorMsg).length > 0) {
      setErrors(errorMsg);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      await verifyMfaCode({
        mfaVerificationCode: values.mfaCode,
        signupToken: userDetails.signUpToken,
        mfaMethod: 'EMAIL',
      });
      setEmailMfaEnabled(true);
      toastSetSuccessNotification('Verification successful');
    } catch (err) {
      errorMsg.mfaCode = 'Invalid verification code.';
      setErrors(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  if (emailMfaEnabled) {
    return (
      <Layout img={img}>
        <InnerBoxLayout
          title="Multi-factor Authentication Setup By Email"
          subTitle={(
            <>
              <br />
              Multi-factor authorization has been enabled for your account!
            </>
          )}
        >
          <Box sx={{ mt: 2 }}>
            <Controls.Button
              text="Continue"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/home')}
            />
          </Box>
        </InnerBoxLayout>
      </Layout>
    );
  }

  return (
    <Layout img={img}>
      <InnerBoxLayout
        title="Multi-factor Authentication Setup By Email"
        subTitle={(
          <>
            <br />
            Multi-factor authentication (MFA) adds an extra layer of security to your account.
            <br />
            <br />
            We will send a verification code to your email address:
            {' '}
            <strong>{userDetails.username}</strong>
          </>
        )}
      >
        <Box component="form" noValidate={false} sx={{ mt: 1, textAlign: 'center' }} onSubmit={isMfaCodeSent ? handleVerifyCode : handleSendEmail}>
          {isMfaCodeSent && (
          <Controls.Input
            fullWidth
            label="Code"
            name="mfaCode"
            value={values.mfaCode}
            error={errors.mfaCode}
            onChange={handleInputChange}
            sx={{ margin: '10px 0px' }}
            data-testid="emailMfaCode"
          />
          )}
          <Controls.Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, width: 1 }}
            text={isMfaCodeSent ? 'Verify' : 'Submit'}
            disabled={loading}
            data-testid="emailMfaSubmitBtn"
          />
        </Box>
      </InnerBoxLayout>
    </Layout>
  );
}

FhxEmailMfaSetup.propTypes = {
  img: PropTypes.string,
};

FhxEmailMfaSetup.defaultProps = {
  img: '',
};

export default FhxEmailMfaSetup;
