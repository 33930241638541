import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Form from '../../components/useForm';
import ConditionCreateSubForm from './ConditionCreateSubForm';
import AnyOrAllOfConditionEditForm from './AnyOrAllOfCondition/AnyOrAllOfConditionEditForm';

export default function ConditionEditForm(props) {
  const {
    condition,
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Form>
        {['anyOf', 'allOf'].includes(condition.conditionType)
          ? (
            <AnyOrAllOfConditionEditForm
              conditionId={condition.id}
              conditionType={condition.conditionType}
              subconditions={condition.subconditions}
              onSuccess={onSuccess}
            />
          )
          : (
            <>
              Change Condition
              <ConditionCreateSubForm
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                successMsg={successMsg}
              />
            </>
          )}
      </Form>
    </Box>
  );
}

ConditionEditForm.propTypes = {
  condition: PropTypes.shape({
    id: PropTypes.number,
    conditionType: PropTypes.string,
    subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

ConditionEditForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
