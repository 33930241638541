import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, TextField, Chip, Grid,
} from '@mui/material';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

function AlternateNameForm({
  medication,
  onAddAlternateName,
  onRemoveAlternateName,
  refreshParent,
}) {
  const [newAlternateName, setNewAlternateName] = useState('');

  const handleAddAlternateName = async () => {
    await onAddAlternateName(newAlternateName.trim());
    setNewAlternateName('');
  };

  // We define a medication's alternate names as the lookup names
  // from the backend minus the generic name
  const alternateNames = medication?.lookupNames || [];
  const filteredAlternateNames = alternateNames.filter(
    (altName) => altName.lookupName !== medication.genericName,
  );
  const alternateNameChips = filteredAlternateNames.map((altName) => (
    <Chip
      key={altName.id}
      label={altName.lookupName}
      onDelete={() => onRemoveAlternateName(altName.id)}
      style={{ margin: '4px' }}
    />
  ));
  return (
    <>
      <Box mb={2}>
        {alternateNameChips.length > 0 ? alternateNameChips : (
          <Typography variant="body2">No alternate names found.</Typography>
        )}
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            label="New Alternate Name"
            value={newAlternateName}
            fullWidth
            onChange={(e) => setNewAlternateName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <ServiceButtonWithToast
            buttonText="Add"
            service={handleAddAlternateName}
            onSuccess={refreshParent}
            successMsg="Alternate name added!"
            errorMsg="Unable to add alternate name"
          />
        </Grid>
      </Grid>
    </>
  );
}

AlternateNameForm.propTypes = {
  medication: PropTypes.shape({
    id: PropTypes.number.isRequired,
    genericName: PropTypes.string.isRequired,
    lookupNames: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        lookupName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  onAddAlternateName: PropTypes.func.isRequired,
  onRemoveAlternateName: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

AlternateNameForm.defaultProps = {
  medication: null,
};

export default AlternateNameForm;
