import React from 'react';
import PropTypes from 'prop-types';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import { useApiClient } from '../../../context/ApiClientContext';
import AttributeTreeItem from './AttributeTreeItem';

export default function AttributeParentTreeItem(props) {
  const {
    label, contextType, numeric, setFormPane, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
    expand,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    const results = await apiClient.attributeSearch(contextType, numeric ? 'numeric' : 'string', false);
    setMCTreeItemChildNodes(results.data.map((att) => (
      <AttributeTreeItem
        key={`${att.uniqueName}_${att.id}`}
        attributeId={att.id}
        attributeUniqueName={att.uniqueName}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
      />
    )));
  };

  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={label}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChilren="No attributes yet..."
    />
  );
}

AttributeParentTreeItem.propTypes = {
  label: PropTypes.string.isRequired,
  contextType: PropTypes.oneOf(['patient', 'symptom', 'medication']).isRequired,
  numeric: PropTypes.bool.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
