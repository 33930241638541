import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import ScoreForm from './ScoreForm';
import ButtonWithModal from '../../components/ButtonWithModal';
import ScoreFactorForm from './ScoreFactorForm';

export default function ScorePane(props) {
  const {
    scoreId, updateCallback, reloadCallback,
  } = props;
  const [initialFieldValues, setInitialFieldValues] = useState(null);
  const [createFactorModalIsOpen, setCreateFactorModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const onSubmit = (values) => apiClient.updateScore(
    scoreId,
    values.uniqueName,
    values.reportPreString,
  );

  const onSuccess = (values) => {
    updateCallback(values.uniqueName);
    toastSetSuccessNotification('Update successful.');
  };

  const onFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  useEffect(() => {
    apiClient.getScore(scoreId).then((result) => {
      setInitialFieldValues(result.data);
    }).catch((error) => {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
    });
  }, [scoreId]);

  return (
    initialFieldValues === null
      ? <CircularProgress />
      : (
        <>
          <ScoreForm
            initialFieldValues={initialFieldValues}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onFail={onFail}
          />
          <ButtonWithModal
            buttonText="Add A Factor"
            modalTitle="Add A Factor"
            modalIsOpen={createFactorModalIsOpen}
            setModalIsOpen={setCreateFactorModalIsOpen}
          >
            <ScoreFactorForm
              onSubmit={(values) => apiClient.createScoreFactor(scoreId, values)}
              onSuccess={() => {
                reloadCallback();
                setCreateFactorModalIsOpen(false);
                toastSetSuccessNotification('Score factor added.');
              }}
              onFail={onFail}
            />
          </ButtonWithModal>
        </>
      )
  );
}

ScorePane.propTypes = {
  scoreId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
  reloadCallback: PropTypes.func,
};

ScorePane.defaultProps = {
  updateCallback: null,
  reloadCallback: null,
};
