import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { useApiClient } from '../../../context/ApiClientContext';
import TemplateListForm from './TemplateListForm';

import { useForm } from '../../../components/useForm';
import TemplateCreateButtonWithModal from '../Template/TemplateCreateButtonWithModal';

export default function TemplateListPane(props) {
  const {
    listId, listUniqueName, onUpdateSuccess, onCreateSuccess,
  } = props;

  const apiClient = useApiClient();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm({ uniqueName: listUniqueName });

  useEffect(() => setValues({ uniqueName: listUniqueName }), [listUniqueName]);

  const updateList = () => apiClient.updateTemplateList(listId, {
    uniqueName: values.uniqueName,
  });

  return (
    <Grid container direction="column">
      <Grid item>
        <TemplateListForm
          values={values}
          handleInputChange={handleInputChange}
          onSubmit={updateList}
          onSuccess={onUpdateSuccess}
          successMsg="List name updated"
          errorMsg="Error updating list name"
        />
      </Grid>
      <Grid item>
        <TemplateCreateButtonWithModal
          listId={listId}
          listUniqueName={listUniqueName}
          onCreateSuccess={onCreateSuccess}
        />
      </Grid>
    </Grid>
  );
}

TemplateListPane.propTypes = {
  listId: PropTypes.number.isRequired,
  listUniqueName: PropTypes.string.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
};
