import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import IntakePlanForm, { intakePlanDefaultValues } from './IntakePlanForm';
import { useForm } from '../../components/useForm';
import ButtonWithModal from '../../components/ButtonWithModal';

export default function IntakePlanCreateButtonWithModal(props) {
  const {
    onCreateSuccess,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiClient = useApiClient();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(intakePlanDefaultValues);

  const onSubmit = () => apiClient.createIntakePlan(values);

  const onSuccess = () => {
    onCreateSuccess();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Create Intake Plan"
      modalTitle="Create Intake Plan"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={() => setValues(intakePlanDefaultValues)}
    >
      <IntakePlanForm
        values={values}
        setValues={setValues}
        handleInputChange={handleInputChange}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        successMsg="IntakePlan created"
        errorMsg="Error creating intakePlan"
      />
    </ButtonWithModal>
  );
}

IntakePlanCreateButtonWithModal.propTypes = {
  onCreateSuccess: PropTypes.func.isRequired,
};
