import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@mui/material';
import ReasonForVisitForm from './ReasonForVisitForm';
import { useApiClient } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import { useForm } from '../../components/useForm';

function ReasonForVisitPane({ reasonForVisit, refreshParent }) {
  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(reasonForVisit);

  useEffect(() => setValues(reasonForVisit), [reasonForVisit]);

  const handleUpdate = (reasonForVisitData) => {
    const reasonForVisitUpdateRequest = {
      c2iName: reasonForVisitData.c2iName,
      layName: reasonForVisitData.layName,
      nlpClassName: reasonForVisitData.nlpClassName,
    };
    return apiClient.updateReasonForVisit(reasonForVisit.id, reasonForVisitUpdateRequest);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Reason For Visit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {reasonForVisit
          && (
            <ReasonForVisitForm
              values={values}
              handleInputChange={handleInputChange}
              onSubmit={handleUpdate}
              onSuccess={refreshParent}
              onFail={toastSetErrorNotification}
            />
          )}
      </Grid>
    </Grid>
  );
}

ReasonForVisitPane.propTypes = {
  reasonForVisit: PropTypes.shape({
    id: PropTypes.number,
    c2iName: PropTypes.string,
    layName: PropTypes.string,
    nlpClassName: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
};

export default ReasonForVisitPane;
