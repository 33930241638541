import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from '../../../../components/useForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import AttributeValueCreateEditForm from './AttributeValueCreateEditForm';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';
import { attributeValueFieldValuesPropType } from '../AttributeValueFormContent';

export default function AttributeValueEditButtonWithModal(props) {
  const {
    attributeValueFieldValues, attributeReportStrings, updateCallbacks,
  } = props;

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(attributeValueFieldValues);

  const [isOpen, setIsOpen] = useState(false);

  const resetValues = () => setValues(attributeValueFieldValues);

  useEffect(resetValues, [attributeValueFieldValues]);

  return (
    <ButtonWithModal
      buttonText="Edit Value"
      modalTitle="Edit Value"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={resetValues}
    >
      <AttributeValueCreateEditForm
        values={values}
        attributeReportStrings={attributeReportStrings}
        handleInputChange={handleInputChange}
        callbacks={updateCallbacks}
      />
    </ButtonWithModal>
  );
}

AttributeValueEditButtonWithModal.propTypes = {
  attributeValueFieldValues: attributeValueFieldValuesPropType.isRequired,
  attributeReportStrings: PropTypes.shape({
    preString: PropTypes.string,
    postString: PropTypes.string,
  }).isRequired,
  updateCallbacks: serviceCallbackPropTypes.isRequired,
};
