import { Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useRefreshTokens } from '../services/auth/authServices';
import useAuth from '../hooks/useAuth';

// This component is responsible for persisting user authentication across sessions.
// useAuth and useRefreshTokens hooks is used to get the authentication state and refresh tokens.
// It also uses useState and useEffect to manage the component state and lifecycle.
function PersistLogin() {
  // Initialize the isLoading state and get the refresh tokens and authentication state.
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshTokens();
  const { auth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;

    // Define the function to verify the refresh token and set the loading state accordingly.
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    // Check if the user is not authenticated and should persist their authentication.
    if (!auth?.accessToken && persist) {
      verifyRefreshToken();
    } else {
      // If the user is already authenticated or shouldn't persist their authentication,
      // set the loading state to false.
      setIsLoading(false);
    }

    // Clean up the isMounted flag to avoid setting state on an unmounted component.
    return () => { isMounted = false; };
  }, []);

  // Render the appropriate component based on whether the user should persist their authentication
  // and whether the authentication state is still loading.
  if (!persist) {
    return <Outlet />;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return <Outlet />;
}

export default PersistLogin;
