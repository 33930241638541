import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

import { Controls } from '../../components/controls/Controls';
import { useApiClient } from '../../context/ApiClientContext';

export default function ModuleSelector(props) {
  const {
    selectedModule,
    setSelectedModule,
  } = props;
  const [modules, setModules] = useState([]);

  const apiClient = useApiClient();

  useEffect(() => {
    async function getModules() {
      const result = await apiClient.getModules();
      setModules(result.data);
    }
    getModules();
  }, []);

  return (
    <Autocomplete
      options={modules}
      getOptionLabel={(module) => (
        module.uniqueName
         || modules.find((m) => m.id === selectedModule.id)?.uniqueName || 'Loading'
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={selectedModule ?? null}
      onChange={(event, newValue) => {
        setSelectedModule(newValue);
      }}
      renderInput={(params) => (
        <Controls.Input
          name={params.inputProps.id}
          variant="outlined"
          label="Select a Module"
          value={params.inputProps.value}
          onChange={params.inputProps.onChange}
          InputProps={params.InputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={params.inputProps}
          InputLabelProps={params.InputLabelProps}
          fullWidth={params.fullWidth}
          disabled={params.disabled}
          style={{ minWidth: '200px' }}
        />
      )}
    />

  );
}

ModuleSelector.propTypes = {
  selectedModule: PropTypes.shape({
    id: PropTypes.number,
  }),
  setSelectedModule: PropTypes.func,
};

ModuleSelector.defaultProps = {
  selectedModule: null,
  setSelectedModule: null,
};
