/**
 * Creates a callback function that swaps two elements in an array by their indices.
 * If either index is not within the bounds of the array, returns `null`.
 */
function createCallbackToSwapElementsByIndex(
  reorderService,
  orderedActionIds,
  index1,
  index2,
  actionListId,
) {
  if (
    index1 >= 0 && index1 < orderedActionIds.length
    && index2 >= 0 && index2 < orderedActionIds.length
  ) {
    const newOrderedActionIds = [...orderedActionIds];
    newOrderedActionIds[index1] = orderedActionIds[index2];
    newOrderedActionIds[index2] = orderedActionIds[index1];
    return () => reorderService(actionListId, newOrderedActionIds);
  }
  return null;
}

function createRaiseAndLowerService(
  reorderService,
  orderedActionIds,
  index,
  listId,
) {
  const onRaise = createCallbackToSwapElementsByIndex(
    reorderService,
    orderedActionIds,
    index,
    index - 1,
    listId,
  );
  const onLower = createCallbackToSwapElementsByIndex(
    reorderService,
    orderedActionIds,
    index,
    index + 1,
    listId,
  );
  return { raiseService: onRaise, lowerService: onLower };
}

export default createRaiseAndLowerService;
