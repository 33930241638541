import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonWithModal from '../../../components/ButtonWithModal';
import AddFixedAttributeValueToNarrativeButton from './AddFixedAttributeValueToNarrativeButton';
import AddNumericAttributeValueToNarrativeButton from './AddNumericAttributeValueToNarrativeButton';

export default function AddAttributeValueToNarrativeButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onSuccess = () => {
    reloadCallback();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Add attribute value to narrative"
      modalTitle="Add attribute value to narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <AddFixedAttributeValueToNarrativeButton
        actionListId={actionListId}
        parentOnSuccess={onSuccess}
      />
      <AddNumericAttributeValueToNarrativeButton
        actionListId={actionListId}
        parentOnSuccess={onSuccess}
      />
    </ButtonWithModal>
  );
}

AddAttributeValueToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};
