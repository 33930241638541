import React from 'react';
import PropTypes from 'prop-types';

import SxOfDxhxParentTreeItem from '../SxOfDxhx/SxOfDxhxParentTreeItem';
import DxHxForm from './DxHxForm';
import PatientFactorTreeItem from './PatientFactorsTreeItem';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import { useApiClient } from '../../context/ApiClientContext';

export default function DxHxTreeItem(props) {
  const {
    id, actionListIdPtFactor, label, setFormPane, refreshParent, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes([
      <SxOfDxhxParentTreeItem
        key={`Symptoms_${id}`}
        id={id}
        setFormPane={setFormPane}
        expandCallback={fetchChildNodes}
        setTreeManagementServices={setTreeManagementServices}
      />,
      <PatientFactorTreeItem
        key={`PtFactors_${id}`}
        actionListIdPtFactor={actionListIdPtFactor}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        expandCallback={fetchChildNodes}
      />,
    ]);
  };

  const onSelect = () => {
    setFormPane(
      <DxHxForm
        id={parseInt(id, 10)}
        updateCallback={updateLabel}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Permanently delete the DxHx "${label}"?`,
      deleteService: () => apiClient.deleteDxHx(id),
      raiseService: null,
      lowerService: null,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
    />
  );
}

DxHxTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  actionListIdPtFactor: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
