import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import Form from '../../components/useForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ReasonForVisitForm(props) {
  const {
    values, handleInputChange, onSubmit, onSuccess,
  } = props;

  const [errors, setErrors] = useState({});
  const successMsg = 'Reason for visit saved';

  const validate = () => {
    const errorMsg = {};
    errorMsg.c2iName = values.c2iName === '' ? 'Please enter a c2iName.' : '';
    errorMsg.layName = values.layName === '' ? 'Please enter a layName.' : '';
    errorMsg.nlpClassName = values.nlpClassName === '' ? 'Please enter a nlpClassName.' : '';
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleSubmit = () => (validate() ? onSubmit(values) : Promise.reject(new Error('Form Validation Error')));

  const isNewReasonForVisit = values && 'id' in values ? values.id === undefined : true;

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            label="C2i Name"
            name="c2iName"
            value={values.c2iName}
            onChange={handleInputChange}
            error={errors.c2iName}
          />
          <Controls.Input
            label="Layperson Name (what the patient sees)"
            name="layName"
            value={values.layName}
            onChange={handleInputChange}
            error={errors.layName}
          />
          <Controls.Input
            label="Nlp Class Name"
            name="nlpClassName"
            value={values.nlpClassName}
            onChange={handleInputChange}
            error={errors.nlpClassName}
          />
          {!isNewReasonForVisit
          && (
          <Typography variant="body2" gutterBottom>
            Warning: Edits to Nlp Class Name deactivate the RFV until next model retrain.
          </Typography>
          )}
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Save"
              service={handleSubmit}
              onSuccess={onSuccess}
              errorMsg={errors}
              successMsg={successMsg}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

ReasonForVisitForm.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    c2iName: PropTypes.string,
    layName: PropTypes.string,
    nlpClassName: PropTypes.string,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
