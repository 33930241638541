import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../components/controls/Controls';
import { useApiClient } from '../../context/ApiClientContext';
import Modal from '../../components/Modal';
import GenericNameForm from './Forms/GenericNameForm';

export default function MedicationAdditionButton(props) {
  const { reloadCallback } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiClient = useApiClient();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCreateMedication = (medicationData) => apiClient.createMedication(medicationData);

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={handleModalOpen}
        sx={{ margin: '6px' }}
        text="Add Medication"
      />
      <Modal isOpen={isModalOpen} handleClose={handleModalClose} title="Add Medication">
        <GenericNameForm
          reloadCallback={reloadCallback}
          onCreateOrUpdate={handleCreateMedication}
        />
      </Modal>
    </>
  );
}

MedicationAdditionButton.propTypes = {
  reloadCallback: PropTypes.func.isRequired,
};
