import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, List, ListItem, ListItemText, Divider,
} from '@mui/material';
import MainClassForm from './MainClassForm';
import ActionAdditionForm from '../Actions/ActionAdditionForm';
import { useApiClient } from '../../context/ApiClientContext';

function MedMainClassPane({
  mainClassId, label, updateCallback, refreshParent, actionListId,
}) {
  const apiClient = useApiClient();

  const [className, setClassName] = useState(label);
  const [medications, setMedications] = useState([]);

  const handleUpdate = async () => {
    const mainClassData = { name: className };
    await apiClient.updateMainClass(mainClassId, mainClassData);
    updateCallback(mainClassData.name);
    refreshParent();
  };

  useEffect(() => {
    const fetchMedications = async () => {
      if (mainClassId) {
        const response = await apiClient.getAllMedicationsByMainClass(mainClassId);
        setMedications(response.data);
      }
    };

    fetchMedications();
  }, [mainClassId]);

  // Update the class name whenever the label changes
  useEffect(() => {
    setClassName(label);
  }, [label]);

  // Reloads the pane and expands it after adding a new action
  const reloadAndExpandNode = () => {
    refreshParent();
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="subtitle1">Edit Class Name</Typography>
      </Grid>
      <Grid item xs={12}>
        <MainClassForm
          className={className}
          setClassName={setClassName}
          onSubmit={handleUpdate}
          submitText="Update"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
        <Typography variant="subtitle1" gutterBottom>Medications in this Class</Typography>
        <List>
          {medications.length > 0 ? (
            medications.map((medication) => (
              <ListItem key={medication.id}>
                <ListItemText
                  primary={medication.genericName}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">No medications found for this class.</Typography>
          )}
        </List>
      </Grid>

      <Grid item xs={12}>
        <Divider />
        <Typography variant="h6" gutterBottom>
          Add Actions
        </Typography>
        <ActionAdditionForm
          actionListId={actionListId}
          reloadCallback={reloadAndExpandNode}
          isModule={false}
        />
      </Grid>
    </Grid>
  );
}

MedMainClassPane.propTypes = {
  mainClassId: PropTypes.number,
  updateCallback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  refreshParent: PropTypes.func.isRequired,
  actionListId: PropTypes.number.isRequired,
};

MedMainClassPane.defaultProps = {
  mainClassId: null,
};

export default MedMainClassPane;
