import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@mui/material/Divider';

import AttributeValueCrudForm from './attributeValue/AttributeValueCrudForm';
import AttributeCrudForm from './attribute/AttributeCrudForm';
import { serviceCallbackPropTypes } from '../../services/basicServiceHandler';
import { attributeValueFieldValuesPropType } from './attributeValue/AttributeValueFormContent';
import { attributeFieldValuesPropType } from './attribute/AttributeFormContent';

export default function AttributeAndValueCrudForm(props) {
  const {
    attributeId,
    attributeFieldValues,
    attributeValueFieldValues,
    createAttributeCallbacks,
    updateAttributeCallbacks,
    addExistingAttributeCallbacks,
    removeAttributeCallbacks,
    createAttributeValueCallbacks,
    updateAttributeValueCallbacks,
    addExistingAttributeValueCallbacks,
    removeAttributeValueCallbacks,
  } = props;

  return (
    <>
      <AttributeCrudForm
        attributeFieldValues={attributeFieldValues}
        createCallbacks={createAttributeCallbacks}
        updateCallbacks={updateAttributeCallbacks}
        addExistingAttributeCallbacks={addExistingAttributeCallbacks}
        removeCallbacks={removeAttributeCallbacks}
        requiredSubtype="string" // "AttributeAndValueCrudForm" always requires fixed AttributeValues, so the Attribute must have a "string" subtype
      />
      {// This component only renders if attributeId is non-null.
        attributeId && (
        <>
          <Divider variant="middle" />
          <AttributeValueCrudForm
            attributeId={attributeId}
            attributeFieldValues={attributeFieldValues}
            attributeValueFieldValues={attributeValueFieldValues}
            createCallbacks={createAttributeValueCallbacks}
            updateCallbacks={updateAttributeValueCallbacks}
            addExistingCallbacks={addExistingAttributeValueCallbacks}
            removeCallbacks={removeAttributeValueCallbacks}
          />
        </>
        )
    }
    </>
  );
}

AttributeAndValueCrudForm.propTypes = {
  attributeId: PropTypes.number,
  attributeFieldValues: attributeFieldValuesPropType,
  attributeValueFieldValues: attributeValueFieldValuesPropType,
  createAttributeCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
  updateAttributeCallbacks: serviceCallbackPropTypes,
  removeAttributeCallbacks: serviceCallbackPropTypes,
  createAttributeValueCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingAttributeValueCallbacks: serviceCallbackPropTypes.isRequired,
  updateAttributeValueCallbacks: serviceCallbackPropTypes,
  removeAttributeValueCallbacks: serviceCallbackPropTypes,
};

AttributeAndValueCrudForm.defaultProps = {
  attributeId: null,
  attributeFieldValues: null,
  attributeValueFieldValues: null,
  updateAttributeCallbacks: null,
  removeAttributeCallbacks: null,
  updateAttributeValueCallbacks: null,
  removeAttributeValueCallbacks: null,
};
