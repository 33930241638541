import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { Controls } from './controls/Controls';

export default function ButtonWithModal(props) {
  const {
    buttonText, size, modalTitle, modalIsOpen, setModalIsOpen, onClose, children,
  } = props;

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={() => setModalIsOpen(true)}
        sx={{ margin: '6px' }}
        text={buttonText}
        size={size}
      />

      <Modal
        isOpen={modalIsOpen}
        handleClose={() => {
          onClose();
          setModalIsOpen(false);
        }}
        title={modalTitle}
      >
        {children}
      </Modal>
    </>
  );
}

ButtonWithModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  size: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ButtonWithModal.defaultProps = {
  size: 'large',
  onClose: () => {},
};
