import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Form from '../../../components/useForm';

import AttributeValueFormContent, { attributeValueFieldValuesPropType } from './AttributeValueFormContent';
import AttributeValueEditButtonWithModal from './createAndEdit/AttributeValueEditButtonWithModal';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import AttributeValueCreateOrFindForm from './AttributeValueCreateOrFindForm';
import { serviceCallbackPropTypes } from '../../../services/basicServiceHandler';
import { attributeFieldValuesPropType } from '../attribute/AttributeFormContent';

export default function AttributeValueCrudForm(props) {
  const {
    attributeId,
    attributeFieldValues,
    attributeValueFieldValues,
    createCallbacks,
    updateCallbacks,
    addExistingCallbacks,
    removeCallbacks,
  } = props;

  const attributeReportStrings = {
    preString: attributeFieldValues.reportPrestring,
    postString: attributeFieldValues.reportPoststring,
  };

  return (
    attributeValueFieldValues ? (
      <>
        <Form>
          <AttributeValueFormContent
            values={attributeValueFieldValues}
            attributeReportStrings={attributeReportStrings}
            disabled
          />
        </Form>
        <Grid container justifyContent="flex-end">
          {updateCallbacks && (
            <AttributeValueEditButtonWithModal
              attributeValueFieldValues={attributeValueFieldValues}
              attributeReportStrings={attributeReportStrings}
              updateCallbacks={updateCallbacks}
            />
          )}
          {removeCallbacks && (
          <ServiceButtonWithToast
            buttonText="Remove Value"
            service={removeCallbacks.service}
            onSuccess={removeCallbacks.onSuccess}
            successMsg={removeCallbacks.successMsg}
            errorMsg={removeCallbacks.errorMsg}
          />
          )}
        </Grid>
      </>
    ) : (
      <AttributeValueCreateOrFindForm
        attributeReportStrings={attributeReportStrings}
        attributeId={attributeId}
        createCallbacks={createCallbacks}
        addExistingCallbacks={addExistingCallbacks}
      />
    ));
}

AttributeValueCrudForm.propTypes = {
  attributeId: PropTypes.number.isRequired,
  attributeFieldValues: attributeFieldValuesPropType.isRequired,
  attributeValueFieldValues: attributeValueFieldValuesPropType,
  createCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingCallbacks: serviceCallbackPropTypes.isRequired,
  updateCallbacks: serviceCallbackPropTypes,
  removeCallbacks: serviceCallbackPropTypes,
};

AttributeValueCrudForm.defaultProps = {
  attributeValueFieldValues: null,
  updateCallbacks: null,
  removeCallbacks: null,
};
