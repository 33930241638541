import React, {
  createContext, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext({});

// This component is responsible for providing the authentication state to the rest of the app.
export function AuthProvider({ children }) {
  // Initialize the authentication and persist state from local storage or as an empty object.
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('persist')) ?? false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing persist from localStorage', error);
      return false;
    }
  });

  // Decode the access token and get the user's permissions, if available.
  const userPermissions = useMemo(() => {
    if (auth?.accessToken) {
      const decoded = jwtDecode(auth.accessToken);
      return decoded?.permissions || {};
    }
    return {};
  }, [auth]);

  // Check if a user has all the required permissions.
  const hasPermissions = useCallback(
    // Loop through each key in the requiredPermissions object
    (requiredPermissions) => Object.keys(requiredPermissions).every((key) => {
      // If the user's permissions object lacks the key
      // or doesn't contain all required permissions, return false.
      if (
        !userPermissions[key]
      || !requiredPermissions[key].every((perm) => userPermissions[key].includes(perm))
      ) {
        return false;
      }

      // If the user has all the required permissions, return true.
      return true;
    }),
    [userPermissions],
  );

  // Use memoization to create a memoized object of the authentication state and set functions.
  const authState = useMemo(() => ({
    auth, setAuth, persist, setPersist, hasPermissions,
  }), [auth, setAuth, persist, setPersist, hasPermissions]);

  // Return the AuthContext provider with the authState as the value and the child components.
  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
