import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../../context/ApiClientContext';
import { useForm } from '../../../components/useForm';
import ButtonWithModal from '../../../components/ButtonWithModal';
import TemplateListForm, { templateListDefaultValues } from './TemplateListForm';

export default function TemplateListCreateButtonWithModal(props) {
  const {
    categoryLabel, categoryUniqueName, refreshParent,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiClient = useApiClient();

  const {
    values,
    handleInputChange,
  } = useForm(templateListDefaultValues);

  const onSubmit = () => apiClient.createTemplateList({
    uniqueName: values.uniqueName,
    category: categoryUniqueName,
  });

  const onSuccess = () => {
    refreshParent();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Create Template List"
      modalTitle={`Provide a name for the new ${categoryLabel} template list`}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <TemplateListForm
        values={values}
        handleInputChange={handleInputChange}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        successMsg="Template list created"
        errorMsg="Error creating list"
      />
    </ButtonWithModal>
  );
}

TemplateListCreateButtonWithModal.propTypes = {
  categoryLabel: PropTypes.string.isRequired,
  categoryUniqueName: PropTypes.string.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
