import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useApiClient } from '../../../context/ApiClientContext';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import AttributeFormContent from '../../Attribute/attribute/AttributeFormContent';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';
import Form from '../../../components/useForm';
import AttributeValueFormContent from '../../Attribute/attributeValue/AttributeValueFormContent';

export default function AttributeValueToNarrativeTreeItem(props) {
  const {
    id,
    attributeId,
    attributeValueData,
    label,
    setFormPane,
    setTreeManagementServices,
    onDeleteWarningMessage,
    deleteService,
    raiseService,
    lowerService,
    onSuccess,
  } = props;
  const {
    MCTreeItemLabel,
  } = useMedicalContentTreeItem(label);

  const apiClient = useApiClient();

  const [attribute, setAttribute] = useState(null);

  const {
    toastSetErrorNotification,
  } = useToast();

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getAttributeById(attributeId),
      (result) => setAttribute(result.data),
      () => toastSetErrorNotification('Unable to load attribute.'),
    );
  }, [attributeId]);

  const onSelect = () => {
    setFormPane(
      <Form>
        <Typography>Attribute</Typography>
        <AttributeFormContent
          controlsDisabled
          values={attribute}
        />
        <Typography>Attribute Value</Typography>
        <AttributeValueFormContent
          values={attributeValueData}
          attributeReportStrings={{
            preString: attribute.reportPrestring,
            postString: attribute.reportPoststring,
          }}
          disabled
        />
      </Form>,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService,
      raiseService,
      lowerService,
      onSuccess,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      isEndNode
      onSelect={onSelect}
    />
  );
}

AttributeValueToNarrativeTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  attributeId: PropTypes.number.isRequired,
  setFormPane: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  attributeValueData: PropTypes.shape({
    reportText: PropTypes.string,
    c2iName: PropTypes.string,
  }).isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  deleteService: PropTypes.func,
  raiseService: PropTypes.func,
  lowerService: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

AttributeValueToNarrativeTreeItem.defaultProps = {
  deleteService: null,
  raiseService: null,
  lowerService: null,
};
