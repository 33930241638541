import React, { useState, useEffect } from 'react';
import {
  Grid, Box, Chip, MenuItem, OutlinedInput, Typography,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';
import ModuleSaveAsNewButton from './ModuleSaveAsNewButton';

const initialFieldValues = {
  moduleName: null,
  languagesToTranslate: [],
};

export default function ModuleEditForm(props) {
  const { id, updateCallback, refreshParent } = props;
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState(null);

  const apiClient = useApiClient();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const isLoaded = () => Object.values(values).every((item) => item !== null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    basicServiceHandler(
      () => apiClient.updateModule(
        id,
        values,
      ),
      () => {
        updateCallback(values.moduleName);
        toastSetSuccessNotification('Update successful!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => setLoading(false),
    );
  };

  useEffect(() => {
    apiClient.getLanguages().then((result) => {
      const allLanguages = result.data.map((language) => language.code);
      setLanguages(allLanguages);
    }).catch((error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)));
  }, []);

  useEffect(() => {
    apiClient.getModule(id).then((result) => {
      const {
        uniqueName, isRepeatable, languagesToTranslate,
      } = result.data;
      setValues({
        moduleName: uniqueName, isRepeatable, languagesToTranslate,
      });
    }).catch((error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)));
  }, [id]);

  return (
    !isLoaded()
      ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
      : (
        <Grid container>
          <Grid item xs={6}>
            <Form onSubmit={handleSubmit}>
              <Controls.Input
                label="Module Name"
                name="moduleName"
                value={values.moduleName}
                onChange={handleInputChange}
              />
              {values.isRepeatable && <Typography align="right">Repeatable</Typography>}
              <Controls.Select
                data-testid="LanguagesDropDown"
                label="Languages To Translate"
                labelId="languages-to-translate-label"
                id="languages-to-translate"
                multiple
                value={values.languagesToTranslate}
                name="languagesToTranslate"
                onChange={handleInputChange}
                input={<OutlinedInput id="select-multiple-languages" label="Languages To Translate" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected?.map((value) => (<Chip key={value} label={value} />))}
                  </Box>
                )}
              >
                {languages
                  && languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
              </Controls.Select>
              <Grid container justifyContent="flex-end">
                <Controls.Button
                  variant="contained"
                  type="submit"
                  sx={{ margin: '6px' }}
                  loading={loading}
                  text="Save"
                  size="medium"
                />
              </Grid>
            </Form>
          </Grid>

          <Grid container>
            <ModuleSaveAsNewButton
              sourceModuleId={id}
              onSuccess={() => {
                toastSetSuccessNotification('New module created (see bottom of modules list)');
                refreshParent();
              }}
              onError={(error) => (
                toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error))
              )}
            />
          </Grid>
        </Grid>
      )
  );
}

ModuleEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
  refreshParent: PropTypes.func,
};

ModuleEditForm.defaultProps = {
  updateCallback: null,
  refreshParent: null,
};
