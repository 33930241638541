import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form, { useForm } from '../../components/useForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';
import ScoreDropdown from './ScoreDropdown';
import ReportSectionDropdown from '../_common/ReportSectionDropdown';

const initialFieldValues = {
  scoreId: '',
  reportSection: 'middle',
};

export default function ScoreSelector(props) {
  const {
    reloadCallback, handleSubmit, buttonText,
  } = props;

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  // Function to check if the selected input is valid
  const isValidInput = () => {
    const errorMsg = {};
    errorMsg.score = values.scoreId === '' ? 'Please select a score.' : '';
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleButtonClick = async () => {
    if (isValidInput()) {
      return handleSubmit(values.reportSection, values.scoreId); // Return the promise
    }
    return null;
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <ScoreDropdown
            label="Select a Score"
            name="scoreId"
            value={values.scoreId}
            onChange={handleInputChange}
            error={errors.score}
          />
          <ReportSectionDropdown
            selectedSection={values.reportSection}
            onChange={handleInputChange}
          />
          <ServiceButtonWithToast
            onSuccess={reloadCallback}
            service={handleButtonClick}
            buttonText={buttonText}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

ScoreSelector.propTypes = {
  reloadCallback: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

ScoreSelector.defaultProps = {
  reloadCallback: null,
};
