import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function DxHxAdditionButton(props) {
  const { reloadCallback } = props;
  const apiClient = useApiClient();

  const handleAdd = () => apiClient.addDxHx();

  return (

    <ServiceButtonWithToast
      onSuccess={reloadCallback}
      service={handleAdd}
      buttonText="Create DxHx"
      successMsg="DxHx created!"
    />
  );
}

DxHxAdditionButton.propTypes = {
  reloadCallback: PropTypes.func,
};

DxHxAdditionButton.defaultProps = {
  reloadCallback: null,
};
