import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';

export default function ScoreForm(props) {
  const {
    initialFieldValues, onSubmit, onSuccess, onFail,
  } = props;
  const [loading, setLoading] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(initialFieldValues);

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    basicServiceHandler(
      () => onSubmit(values),
      () => { onSuccess(values); setUpdatedValues(values); },
      (error) => { onFail(error); setValues(updatedValues); },
      () => setLoading(false),
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            label="Unique Name (seen on C2i only)"
            name="uniqueName"
            value={values.uniqueName}
            onChange={handleInputChange}
          />
          <Controls.Input
            label="Report PreText"
            name="reportPreString"
            value={values.reportPreString}
            onChange={handleInputChange}
          />
          <Grid container justifyContent="flex-end">
            <Controls.Button
              variant="contained"
              type="submit"
              score={{ margin: '6px' }}
              loading={loading}
              text="Save"
              size="medium"
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

ScoreForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialFieldValues: PropTypes.shape({
    uniqueName: PropTypes.string.isRequired,
    reportPreString: PropTypes.string.isRequired,
  }),
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

ScoreForm.defaultProps = {
  initialFieldValues: { uniqueName: '', reportPreString: '' },
};
