import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form from '../../../components/useForm';
import { Controls } from '../../../components/controls/Controls';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

function GenericNameForm({
  onCreateOrUpdate,
  reloadCallback,
  medication,
}) {
  const [genericName, setGenericName] = useState(medication?.genericName || '');

  const handleSubmit = () => {
    onCreateOrUpdate({ genericName });
    setGenericName('');
  };

  useEffect(() => {
    setGenericName(medication?.genericName || '');
  }, [medication]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="genericName"
            label="Generic Name"
            value={genericName}
            onChange={(e) => setGenericName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <ServiceButtonWithToast
            buttonText="Save"
            service={() => onCreateOrUpdate({ genericName })}
            onSuccess={reloadCallback}
            successMsg="Generic name updated!"
            errorMsg="Unable to update generic name"
          />
        </Grid>
      </Grid>
    </Form>
  );
}

GenericNameForm.propTypes = {
  reloadCallback: PropTypes.func,
  onCreateOrUpdate: PropTypes.func,
  medication: PropTypes.shape({
    genericName: PropTypes.string,
  }),
};

GenericNameForm.defaultProps = {
  reloadCallback: null,
  onCreateOrUpdate: null,
  medication: null,
};

export default GenericNameForm;
