import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useForm } from '../../../components/useForm';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import Input from '../../../components/controls/Input';
import ScoreDropdown from '../../Score/ScoreDropdown';

export default function ScoreInRangeSubForm(props) {
  const initialFieldValues = {
    scoreId: '',
    lowerBound: '',
    upperBound: '',
  };

  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const { scoreId, lowerBound, upperBound } = values;

  const handleSave = () => {
    const condition = {
      conditionType: 'scoreInRange',
      scoreId,
      lowerBound: lowerBound === '' ? undefined : parseFloat(lowerBound),
      upperBound: upperBound === '' ? undefined : parseFloat(upperBound),

    };
    return onSubmit(condition);
  };

  const handleNumberChange = (e) => {
    const newValue = e.target.value.replace(/[^\d.-]+/g, '');
    setValues({ ...values, [e.target.name]: newValue });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ScoreDropdown
          name="scoreId"
          value={values.scoreId}
          onChange={handleInputChange}
          label="Select a Score"
        />
      </Grid>
      <Grid item xs={5}>
        <Input
          value={lowerBound}
          name="lowerBound"
          onChange={handleNumberChange}
          label="Lower bound"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Grid>
      {lowerBound === '' && upperBound !== '' && (
      <Grid
        item
        xs={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        No lower bound
      </Grid>
      )}
      <Grid item xs={5}>
        <Input
          value={upperBound}
          name="upperBound"
          onChange={handleNumberChange}
          label="Upper bound"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Grid>

      {upperBound === '' && lowerBound !== '' && (
      <Grid
        item
        xs={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        No upper bound
      </Grid>
      )}
      <Grid item xs={6}>
        <ServiceButtonWithToast
          buttonText="Save"
          service={handleSave}
          onSuccess={onSuccess}
          successMsg={successMsg}
          disabled={lowerBound === '' && upperBound === ''}
        />
      </Grid>
    </Grid>
  );
}

ScoreInRangeSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

ScoreInRangeSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
