import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttributeFindForm from './AttributeFindForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export default function AttributeFindButtonWithModal(props) {
  const {
    initialContextType, requiredSubtype, addExistingAttributeCallbacks,
    buttonText, allowContextChange,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [contextType, setContextType] = useState(initialContextType);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);

  const callbacks = {
    ...addExistingAttributeCallbacks,
    service: () => addExistingAttributeCallbacks.service(selectedAttributeId),
    onSuccess: (result) => {
      setContextType(initialContextType);
      setSelectedAttributeId(null);
      setIsOpen(false);
      addExistingAttributeCallbacks.onSuccess(result);
    },
  };

  return (
    <ButtonWithModal
      buttonText={buttonText}
      modalTitle="Select Attribute"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={() => {
        setContextType(initialContextType);
        setSelectedAttributeId(null);
      }}
    >
      <AttributeFindForm
        selectedContextType={contextType}
        setSelectedContextType={allowContextChange ? setContextType : null}
        requiredSubtype={requiredSubtype}
        selectedAttributeId={selectedAttributeId}
        setSelectedAttributeId={setSelectedAttributeId}
        callbacks={callbacks}
      />
    </ButtonWithModal>
  );
}

AttributeFindButtonWithModal.propTypes = {
  initialContextType: PropTypes.oneOf(['patient', 'symptom', 'medication', '']),
  requiredSubtype: PropTypes.oneOf(['string', 'numeric']),
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
  buttonText: PropTypes.string,
  allowContextChange: PropTypes.bool,
};

AttributeFindButtonWithModal.defaultProps = {
  initialContextType: '',
  requiredSubtype: null,
  buttonText: 'Select Existing Attribute',
  allowContextChange: true,
};
