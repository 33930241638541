import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  Grid, IconButton, Typography, Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controls } from '../../components/controls/Controls';
import { useApiClient } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';

const extractParagraphsFromHtml = (html) => {
  const parser = new DOMParser();
  const sanitizedHtml = DOMPurify.sanitize(html);
  const doc = parser.parseFromString(sanitizedHtml, 'text/html');

  const outerDiv = doc.querySelector('div');
  const divElements = outerDiv.children;

  const paragraphs = Array.from(divElements).map((div, index) => ({
    id: `paragraph-${index}-${Date.now()}`,
    text: div.innerHTML.trim(),
  }));

  return paragraphs;
};

const createHtmlFromParagraphs = (paragraphs) => {
  const containerStyle = 'display: flex; flex-direction: column; gap: 20px';

  const rawHtml = `<div style="${containerStyle}">${paragraphs
    .map((paragraph) => `<div>${DOMPurify.sanitize(paragraph.text)}</div>`)
    .join('')}</div>`;

  return DOMPurify.sanitize(rawHtml);
};

function ParagraphInput({ paragraph, onChange, onDelete }) {
  return (
    <Grid item xs={12}>
      <Controls.Input
        label="Paragraph"
        value={paragraph.text}
        onChange={onChange}
        fullWidth
        multiline
        InputProps={{
          endAdornment: (
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          ),
        }}
      />
    </Grid>
  );
}

function FreeTextPage({
  questionId, html, updateCallback, questionType,
}) {
  const [existingParagraphs, setExistingParagraphs] = useState([]);
  const [newParagraphText, setNewParagraphText] = useState('');
  const apiClient = useApiClient();
  const { toastSetSuccessNotification, toastSetErrorNotification } = useToast();

  // ensure pane is updated with the correct content
  // when user switches to different FreeTextPage on same render
  useEffect(() => {
    if (html) {
      setExistingParagraphs(extractParagraphsFromHtml(html));
    }
  }, [html]);

  const updateQuestion = async (newParagraphs) => {
    basicServiceHandler(
      () => apiClient.updateQuestion(
        questionId,
        questionType === 'signature' ? 'signature' : 'text',
        questionType === 'signature' ? 'Add free text page(signature)' : 'Add free text page',
        undefined,
        undefined,
        createHtmlFromParagraphs(newParagraphs),
      ),
      () => {
        toastSetSuccessNotification('Free text page updated successfully!');
        updateCallback('Add free text page');
      },
      () => toastSetErrorNotification('Failed to update free text page.'),
    );
  };

  const addParagraph = async () => {
    // instead of using index as id, use combination of paragraph length + date to ensure uniqueness
    const newParagraphs = [...existingParagraphs, { id: `paragraph-${existingParagraphs.length}-${Date.now()}`, text: newParagraphText }];
    setExistingParagraphs(newParagraphs);
    setNewParagraphText('');
    await updateQuestion(newParagraphs);
  };

  const deleteParagraph = (id) => async () => {
    const newParagraphs = existingParagraphs.filter((paragraph) => paragraph.id !== id);
    setExistingParagraphs(newParagraphs);
    await updateQuestion(newParagraphs);
  };

  const onChange = (id) => async (e) => {
    const newParagraphs = existingParagraphs.map((paragraph) => (
      paragraph.id === id ? { ...paragraph, text: e.target.value } : paragraph));
    setExistingParagraphs(newParagraphs);
    await updateQuestion(newParagraphs);
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Create Paragraph
      </Typography>
      <Controls.Input
        value={newParagraphText}
        fullWidth
        onChange={(e) => {
          setNewParagraphText(e.target.value);
        }}
      />
      <Controls.Button
        variant="contained"
        onClick={addParagraph}
        sx={{ margin: '6px' }}
        text="Add"
        size="medium"
      />
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Edit Paragraphs
        </Typography>
        <Grid container spacing={2}>
          {existingParagraphs.map((p) => (
            <ParagraphInput
              key={p.id}
              paragraph={p}
              onChange={onChange(p.id)}
              onDelete={deleteParagraph(p.id)}
            />
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default FreeTextPage;

FreeTextPage.propTypes = {
  questionId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  html: PropTypes.string,
  questionType: PropTypes.string,
};

FreeTextPage.defaultProps = {
  html: '',
  questionType: 'text',
};

ParagraphInput.propTypes = {
  paragraph: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
