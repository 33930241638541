import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
  Paper,
} from '@mui/material';

export default function TranslationPipelineLogTable({ pipelineLogs }) {
  return (
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Pipeline</TableCell>
            <TableCell>Triggered By</TableCell>
            <TableCell>Started At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pipelineLogs.map((log) => {
            let status = '';
            let statusColor = '';
            let details = '';
            if (log.status.isComplete) {
              if (log.status.result.success) {
                status = 'Success';
                statusColor = 'green';
              } else {
                status = 'Failed';
                statusColor = 'red';
                details = `${log.status.result?.errorType}: ${log.status.result?.details}`;
              }
            } else {
              status = 'In progress';
              statusColor = 'black';
              details = log.status.statusMessage;
            }

            return (
              <TableRow key={log.createdAt}>
                <TableCell component="th" scope="row">{log.name}</TableCell>
                <TableCell>{log.triggeredBy}</TableCell>
                <TableCell>{moment(log.createdAt).format('MMM DD YYYY, h:mm:ss a')}</TableCell>
                <TableCell style={{ color: statusColor }}>{status}</TableCell>
                <TableCell>{details}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TranslationPipelineLogTable.propTypes = {
  pipelineLogs: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    triggeredBy: PropTypes.string,
    status: PropTypes.shape({
      isComplete: PropTypes.bool,
    }),
  })).isRequired,
};
