import React from 'react';
import PropTypes from 'prop-types';

import AttributeValueCreateButtonWithModal from './createAndEdit/AttributeValueCreateButtonWithModal';
import AttributeValueFindButtonWithModal from './findExisting/AttributeValueFindButtonWithModal';
import { serviceCallbackPropTypes } from '../../../services/basicServiceHandler';

export default function AttributeValueCreateOrFindForm(props) {
  const {
    attributeReportStrings,
    attributeId,
    createCallbacks,
    addExistingCallbacks,
  } = props;

  return (
    <>
      <AttributeValueCreateButtonWithModal
        attributeReportStrings={attributeReportStrings}
        createCallbacks={createCallbacks}
      />
      <AttributeValueFindButtonWithModal
        attributeIdToFindValuesFor={attributeId}
        addExistingCallbacks={addExistingCallbacks}
      />
    </>
  );
}

AttributeValueCreateOrFindForm.propTypes = {
  attributeReportStrings: PropTypes.shape({
    preString: PropTypes.string,
    postString: PropTypes.string,
  }).isRequired,
  attributeId: PropTypes.number.isRequired,
  createCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingCallbacks: serviceCallbackPropTypes.isRequired,
};
