import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ScoreSelector from '../../Score/ScoreSelector';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import useToast from '../../../hooks/useToast';
import basicServiceHandler from '../../../services/basicServiceHandler';

function AddScoreToReportButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const handleSubmit = async (reportSection, scoreId) => {
    basicServiceHandler(
      () => apiClient.addScoreToReportAction(actionListId, reportSection, scoreId),
      () => { reloadCallback(); setModalIsOpen(false); toastSetSuccessNotification('Score added.'); },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  return (
    <ButtonWithModal
      buttonText="Add Score To Report"
      modalTitle="Add Score To Report"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Grid container>
        <Grid item xs={6}>
          <ScoreSelector
            handleSubmit={handleSubmit}
            buttonText="Add"
            successMsg="Score added"
            reloadCallback={reloadCallback}
          />
        </Grid>
      </Grid>
    </ButtonWithModal>
  );
}

AddScoreToReportButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default AddScoreToReportButton;
