import React from 'react';
import { TextField } from '@mui/material';

export default function Input(props) {
  const {
    // eslint-disable-next-line react/prop-types
    name, variant, label, value, error = null, onChange, ...others
  } = props;

  return (
    <TextField
      variant={variant || 'outlined'}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(error && { error: true, helperText: error })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    />
  );
}
