import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonWithModal from '../../../components/ButtonWithModal';
import useToast from '../../../hooks/useToast';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import AddMedicationToNarrativeForm from './AddMedicationToNarrativeForm';

function AddMedicationToNarrativeButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const handleSubmit = async (medicationId, medicationNameOverride) => {
    basicServiceHandler(
      () => apiClient.addMedicationToNarrative(actionListId, medicationId, medicationNameOverride),
      () => { reloadCallback(); setModalIsOpen(false); },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  return (
    <ButtonWithModal
      buttonText="Add Medication To Narrative"
      modalTitle="Add Medication To Narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <AddMedicationToNarrativeForm
        handleMedicationAdd={handleSubmit}
        buttonText="Add"
        successMsg="Success"
        reloadCallback={reloadCallback}
      />
    </ButtonWithModal>
  );
}

AddMedicationToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default AddMedicationToNarrativeButton;
