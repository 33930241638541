import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../../context/ApiClientContext';
import TemplateForm, { templateDefaultValues } from './TemplateForm';
import { useForm } from '../../../components/useForm';
import ButtonWithModal from '../../../components/ButtonWithModal';

export default function TemplateCreateButtonWithModal(props) {
  const {
    listId, listUniqueName, onCreateSuccess,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiClient = useApiClient();

  const {
    values,
    handleInputChange,
  } = useForm(templateDefaultValues);

  const onSubmit = () => apiClient.createTemplate({
    uniqueName: values.uniqueName,
    content: values.content,
    listId,
  });

  const onSuccess = () => {
    onCreateSuccess();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Create Template"
      modalTitle={`Create ${listUniqueName} Template`}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <TemplateForm
        values={values}
        handleInputChange={handleInputChange}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        successMsg="Template created"
        errorMsg="Error creating template"
      />
    </ButtonWithModal>
  );
}

TemplateCreateButtonWithModal.propTypes = {
  listId: PropTypes.number.isRequired,
  listUniqueName: PropTypes.string.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
};
