import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';
import ActionAdditionForm from '../Actions/ActionAdditionForm';
import FixedAnswerFormSubFormAnswer from './FixedAnswerFormSubFormAnswer';
import FixedAnswerFormSubFormAttributeAndValue from './FixedAnswerFormSubFormAttributeAndValue';
import { useForm } from '../../components/useForm';
import FixedAnswerSubFormExclusionCriteria from './FixedAnswerFormSubFormExclusionCriteria';
import generateFixedAnswerTreeItemLabel from './utils';

export default function FixedAnswerForm(props) {
  const {
    fixedAnswerId, actionListId, updateTreeItemLabel, reloadCallback,
  } = props;

  const apiClient = useApiClient();

  const {
    values: answerFieldValues,
    setValues: setAnswerFieldValues,
    handleInputChange,
  } = useForm(null);

  const [selectedAttributeView, setSelectedAttributeView] = useState(null);
  const [selectedAttributeValueView, setSelectedAttributeValueView] = useState(null);
  const [unselectedAttributeView, setUnselectedAttributeView] = useState(null);
  const [unselectedAttributeValueView, setUnselectedAttributeValueView] = useState(null);
  const [exclusionCriteria, setExclusionCriteria] = useState(null);
  const [questionType, setQuestionType] = useState(null);

  const {
    toastSetErrorNotification,
  } = useToast();

  const isLoaded = answerFieldValues !== null;

  const refreshStateWithAnswerFormView = (answerFormView) => {
    setAnswerFieldValues({
      text: answerFormView.text,
      deselectOtherAnswers: answerFormView.deselectOtherAnswers,
      helperText: answerFormView.helperText || '',
    });
    setSelectedAttributeView(answerFormView.selectedAttribute?.attribute);
    setSelectedAttributeValueView(answerFormView.selectedAttribute?.attributeValue);
    setUnselectedAttributeView(answerFormView.unselectedAttribute?.attribute);
    setUnselectedAttributeValueView(answerFormView.unselectedAttribute?.attributeValue);
    setExclusionCriteria(answerFormView.exclusionCriteria);
    updateTreeItemLabel(
      generateFixedAnswerTreeItemLabel(answerFormView.text, answerFormView.exclusionCriteria),
    );
  };

  const refresh = () => basicServiceHandler(
    async () => {
      const answer = await apiClient.getAnswer(fixedAnswerId);
      const question = await apiClient.getQuestion(answer.data.questionId);
      return {
        ...answer,
        questionType: question.data.questionType,
      };
    },
    (result) => {
      setQuestionType(result.questionType);
      refreshStateWithAnswerFormView(result.data);
    },
    (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    () => {},
  );

  useEffect(refresh, [fixedAnswerId]);

  // Assemble the final component
  // ----------------------------

  return (
    !isLoaded
      ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
      : (
        <>
          <FixedAnswerFormSubFormAnswer
            fieldValues={answerFieldValues}
            handleInputChange={handleInputChange}
            fixedAnswerId={fixedAnswerId}
            refreshParent={refresh}
          />
          <Box sx={{ my: 3 }}>
            <Divider />
            <FixedAnswerSubFormExclusionCriteria
              fixedAnswerId={fixedAnswerId}
              exclusionCriteria={exclusionCriteria}
              refreshParent={refresh}
            />
            <Divider />
          </Box>

          <ActionAdditionForm
            actionListId={actionListId}
            reloadCallback={reloadCallback}
          />

          <Paper elevation={3}>
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="h6" gutterBottom>
                If selected
              </Typography>
              <FixedAnswerFormSubFormAttributeAndValue
                attributeId={selectedAttributeView?.id}
                attributeFieldValues={selectedAttributeView}
                attributeValueId={selectedAttributeValueView?.id}
                attributeValueFieldValues={selectedAttributeValueView}
                fixedAnswerId={fixedAnswerId}
                isSelected
                updateCallback={refreshStateWithAnswerFormView}
              />
            </Box>
            {questionType === 'selectAll' && (
            <Box sx={{ my: 3, mx: 2 }}>
              <Typography variant="h6" gutterBottom>
                If not selected
              </Typography>
              <FixedAnswerFormSubFormAttributeAndValue
                attributeId={unselectedAttributeView?.id}
                attributeFieldValues={unselectedAttributeView}
                attributeValueId={unselectedAttributeValueView?.id}
                attributeValueFieldValues={unselectedAttributeValueView}
                fixedAnswerId={fixedAnswerId}
                isSelected={false}
                updateCallback={refreshStateWithAnswerFormView}
              />
            </Box>
            )}
          </Paper>
        </>
      )
  );
}

FixedAnswerForm.propTypes = {
  fixedAnswerId: PropTypes.number.isRequired,
  updateTreeItemLabel: PropTypes.func,
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
};

FixedAnswerForm.defaultProps = {
  reloadCallback: null,
  updateTreeItemLabel: null,
};
