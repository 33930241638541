import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Chip, Box,
} from '@mui/material';
import GenericNameForm from './Forms/GenericNameForm';
import AlternateNameForm from './Forms/AlternateNameForm';
import CommonDirectionsForm from './Forms/CommonDirectionsForm';
import MainClassDropdownAdditionForm from './Forms/MainClassDropdownAdditionForm';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import useToast from '../../hooks/useToast';

function MedicationPane({ medicationId, updateCallback, refreshParent }) {
  const [medication, setMedication] = useState(null);
  const [mainClassesList, setMainClassesList] = useState([]);

  const apiClient = useApiClient();
  const { toastSetErrorNotification, toastSetSuccessNotification } = useToast();

  const fetchMedicationDetails = () => {
    basicServiceHandler(
      () => apiClient.getMedication(medicationId),
      (response) => setMedication(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const fetchExistingMainClasses = () => {
    basicServiceHandler(
      () => apiClient.getAllMainClasses(),
      (response) => setMainClassesList(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const handleUpdate = (medicationData) => {
    basicServiceHandler(
      () => apiClient.updateMedication(medicationId, medicationData),
      () => {
        updateCallback(medicationData.genericName);
        toastSetSuccessNotification('Medication updated successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const handleAddAlternateName = (alternateName) => {
    basicServiceHandler(
      () => apiClient.addAlternateNameToMedication(medicationId, alternateName),
      () => {
        fetchMedicationDetails();
        toastSetSuccessNotification('Alternate name added successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const handleRemoveAlternateName = (alternateNameId) => {
    basicServiceHandler(
      () => apiClient.removeAlternateNameFromMedication(medicationId, alternateNameId),
      () => {
        fetchMedicationDetails();
        toastSetSuccessNotification('Alternate name removed successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const handleAddMainClassToMedication = (mainClassId) => {
    basicServiceHandler(
      () => apiClient.addMainClassToMedication(medicationId, mainClassId),
      () => {
        fetchMedicationDetails();
        toastSetSuccessNotification('Main class added successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  const handleRemoveMainClassFromMedication = (mainClassId) => {
    basicServiceHandler(
      () => apiClient.removeMainClassFromMedication(medicationId, mainClassId),
      () => {
        fetchMedicationDetails();
        toastSetSuccessNotification('Main class removed successfully!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  useEffect(() => {
    fetchMedicationDetails();
    fetchExistingMainClasses();
  }, [medicationId]);

  return (
    <Grid container spacing={8}>
      <Grid item xs={10}>
        <Typography variant="subtitle1">Medication</Typography>
        <GenericNameForm
          medication={medication}
          medicationId={medicationId}
          onCreateOrUpdate={handleUpdate}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="subtitle1">Alternate Name</Typography>
        <AlternateNameForm
          medication={medication}
          refreshParent={refreshParent}
          onAddAlternateName={handleAddAlternateName}
          onRemoveAlternateName={handleRemoveAlternateName}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="subtitle1">Common Directions</Typography>
        <CommonDirectionsForm
          commonDirections={medication?.directions}
          onAddCommonDirection={(commonDirectionData) => (
            apiClient.createCommonDirectionForMedication(medicationId, commonDirectionData)
          )}
          refreshParent={fetchMedicationDetails}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="subtitle1">Medication Classes</Typography>
        <Box marginBottom={2}>
          {medication?.mainClasses?.map((mainClass) => (
            <Chip
              key={mainClass.id}
              label={mainClass.name}
              onDelete={() => handleRemoveMainClassFromMedication(mainClass.id)}
              style={{ margin: '4px' }}
            />
          ))}
        </Box>
        <Box marginTop={2}>
          <MainClassDropdownAdditionForm
            mainClassesList={mainClassesList}
            addMainClassToMedication={handleAddMainClassToMedication}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

MedicationPane.propTypes = {
  medicationId: PropTypes.number,
  updateCallback: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

MedicationPane.defaultProps = {
  medicationId: null,
};

export default MedicationPane;
