import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useForm } from '../../../components/useForm';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import Dropdown from '../../../components/controls/Dropdown';
import Input from '../../../components/controls/Input';

export default function AgeRangeSubForm(props) {
  const initialFieldValues = {
    minAgeNumber: '',
    minAgeUnit: '',
    maxAgeNumber: '',
    maxAgeUnit: '',
  };

  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const {
    minAgeNumber, minAgeUnit, maxAgeNumber, maxAgeUnit,
  } = values;

  const validate = () => {
    const errorMsg = {};
    if (minAgeNumber !== '' && minAgeUnit === '') errorMsg.minAgeUnit = 'Units must be provided';
    if (maxAgeNumber !== '' && maxAgeUnit === '') errorMsg.maxAgeUnit = 'Units must be provided';

    setErrors(errorMsg);
    return Object.keys(errorMsg).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      const condition = {
        conditionType: 'ageInRange',
      };
      if (minAgeNumber !== '') {
        condition.minAge = { number: parseFloat(minAgeNumber), unit: minAgeUnit };
      }
      if (maxAgeNumber !== '') {
        condition.maxAge = { number: parseFloat(maxAgeNumber), unit: maxAgeUnit };
      }
      return onSubmit(condition);
    }
    return null;
  };

  const handleNumberChange = (e) => {
    const newValue = e.target.value.replace(/[^\d.-]+/g, '');
    setValues({ ...values, [e.target.name]: newValue });
  };

  const units = ['days', 'weeks', 'months', 'years'];
  const unitOptions = units.map((val) => ({ id: val, name: val }));

  return (
    <Grid container>
      <Grid item xs={5}>
        <Input
          value={minAgeNumber}
          name="minAgeNumber"
          onChange={handleNumberChange}
          label="Minimum Age"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={errors.minAgeNumber}
        />
      </Grid>
      <Grid item xs={5}>
        <Dropdown
          value={minAgeUnit}
          name="minAgeUnit"
          onChange={handleInputChange}
          options={unitOptions}
          error={errors.minAgeUnit}
        />
      </Grid>
      {minAgeNumber === '' && maxAgeNumber !== '' && maxAgeUnit !== '' && (
      <Grid
        item
        xs={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        No min
      </Grid>
      )}
      <Grid item xs={5}>
        <Input
          value={maxAgeNumber}
          name="maxAgeNumber"
          onChange={handleNumberChange}
          label="Maximum Age"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={errors.maxAgeNumber}
        />
      </Grid>
      <Grid item xs={5}>
        <Dropdown
          value={maxAgeUnit}
          name="maxAgeUnit"
          onChange={handleInputChange}
          options={unitOptions}
          error={errors.maxAgeUnit}
        />
      </Grid>
      {maxAgeNumber === '' && minAgeNumber !== '' && minAgeUnit !== '' && (
      <Grid
        item
        xs={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        No max
      </Grid>
      )}
      <Grid item xs={6}>
        <ServiceButtonWithToast
          buttonText="Save"
          service={handleSave}
          onSuccess={onSuccess}
          successMsg={successMsg}
          disabled={!((minAgeNumber && minAgeUnit) || (maxAgeNumber && maxAgeUnit))}
        />
      </Grid>
    </Grid>
  );
}

AgeRangeSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

AgeRangeSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
