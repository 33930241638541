import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../../context/ApiClientContext';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function QuestionAdditionButton(props) {
  const { actionListId, reloadCallback } = props;
  const apiClient = useApiClient();

  const handleAdd = () => apiClient.addQuestion(actionListId);

  return (

    <ServiceButtonWithToast
      onSuccess={reloadCallback}
      service={handleAdd}
      buttonText="Add Question"
      successMsg="Question added!"
    />
  );
}

QuestionAdditionButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
};

QuestionAdditionButton.defaultProps = {
  reloadCallback: null,
};
