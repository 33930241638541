import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import useToast from '../../hooks/useToast';
import SymptomForm from './SymptomForm';

function SymptomPane({ symptomId, updateCallback }) {
  const [initialFieldValues, setInitialFieldValues] = useState(null);

  const apiClient = useApiClient();
  const { toastSetSuccessNotification, toastSetErrorNotification } = useToast();

  // Fetching details
  const fetchSymptomDetails = () => {
    basicServiceHandler(
      () => apiClient.getSymptom(symptomId),
      (response) => setInitialFieldValues(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  // Handling symptom form submission
  const handleSymptomSubmit = (values) => apiClient.updateSymptom(
    symptomId,
    values.medicalName,
    values.reportName,
    values.layName,
    values.laySubstitutor,
    values.helperText,
  );

  const handleSymptomSuccess = (values) => {
    updateCallback(values.medicalName);
    toastSetSuccessNotification('Update successful.');
  };

  const handleSymptomFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  useEffect(() => {
    fetchSymptomDetails();
  }, [symptomId]);

  return (
    initialFieldValues === null
      ? <CircularProgress />
      : (
        <SymptomForm
          initialFieldValues={initialFieldValues}
          onSubmit={handleSymptomSubmit}
          onSuccess={handleSymptomSuccess}
          onFail={handleSymptomFail}
        />
      )
  );
}

SymptomPane.propTypes = {
  symptomId: PropTypes.number,
  updateCallback: PropTypes.func.isRequired,
};

SymptomPane.defaultProps = {
  symptomId: null,
};

export default SymptomPane;
