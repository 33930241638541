import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CheckboxControl(props) {
  const {
    // eslint-disable-next-line react/prop-types
    label, name, disabled, checked, onChange,
  } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            disabled={disabled}
            name={name}
            checked={checked}
            onChange={onChange}
          />
        )}
        label={label}
        labelPlacement="start"
      />
    </FormGroup>
  );
}
