import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ModuleCreateForm(props) {
  const {
    onSubmit, onSuccess,
  } = props;

  const {
    values,
    handleInputChange,
  } = useForm({ moduleName: '' });

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <Controls.Input
            label="Enter a name for the new module"
            name="moduleName"
            value={values.moduleName}
            onChange={handleInputChange}
          />
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Submit"
              service={() => onSubmit(values)}
              onSuccess={onSuccess}
              successMsg="Success. New new module and action list reassigned."
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

ModuleCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

ModuleCreateForm.defaultProps = {
  onSuccess: () => {},
};
