import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import Form, { useForm } from '../../../components/useForm';
import MedicationDropdown from '../../Medication/MedicationDropdown';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

const initialFieldValues = {
  medicationId: '',
  medicationNameOverride: '',
};

export default function AddMedicationToNarrativeForm(props) {
  const {
    reloadCallback, handleMedicationAdd, buttonText, successMsg,
  } = props;

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const isValidInput = () => {
    const errorMsg = {};
    errorMsg.medication = values.medicationId === '' ? 'Please select a medication.' : '';
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleButtonClick = async () => {
    if (isValidInput()) {
      return handleMedicationAdd(values.medicationId, values.medicationNameOverride);
    }
    return null;
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <MedicationDropdown
            label="Select a Medication"
            name="medicationId"
            value={values.medicationId}
            onChange={handleInputChange}
            error={errors.medication}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            label="Name displayed to patient"
            variant="outlined"
            fullWidth
            name="medicationNameOverride"
            value={values.medicationNameOverride}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ServiceButtonWithToast
            onSuccess={reloadCallback}
            service={handleButtonClick}
            buttonText={buttonText}
            successMsg={successMsg}
          />
        </Grid>
      </Grid>
    </Form>
  );
}

AddMedicationToNarrativeForm.propTypes = {
  reloadCallback: PropTypes.func,
  handleMedicationAdd: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  successMsg: PropTypes.string.isRequired,
};

AddMedicationToNarrativeForm.defaultProps = {
  reloadCallback: null,
};
