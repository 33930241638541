import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import ActionAdditionForm from '../Actions/ActionAdditionForm';
import renderActionList from '../Actions/renderActionList';

export default function PatientFactorTreeItem(props) {
  const {
    actionListIdPtFactor, setFormPane, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Patient Factors');

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListIdPtFactor,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => setFormPane(
    <ActionAdditionForm
      actionListId={actionListIdPtFactor}
      reloadCallback={reloadAndExpandNode}
    />,
  );

  // prefetch children to accurately display chevron
  useEffect(() => {
    fetchChildNodes();
  }, []);

  return (
    <MedicalContentTreeItem
      id={actionListIdPtFactor}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      isEndNode={MCTreeItemChildNodes && MCTreeItemChildNodes.length === 0}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No actions yet..."
    />
  );
}

PatientFactorTreeItem.propTypes = {
  actionListIdPtFactor: PropTypes.number.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
