import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import Form from '../../../components/useForm';
import { Controls } from '../../../components/controls/Controls';

export const templateDefaultValues = {
  uniqueName: '',
  content: '',
};

export default function TemplateForm(props) {
  const {
    values, handleInputChange, onSubmit, onSuccess, successMsg, errorMsg,
  } = props;

  const [errors, setErrors] = useState({});

  const validate = () => {
    const inputErrorMessages = {};
    inputErrorMessages.uniqueName = values.uniqueName === '' ? 'Name cannot be empty.' : '';
    setErrors(inputErrorMessages);
    return Object.values(inputErrorMessages).every((item) => item === '');
  };

  const handleSubmit = () => (validate() ? onSubmit(values) : Promise.reject(new Error('Form Validation Error')));

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <Controls.Input
            label="Template Name"
            name="uniqueName"
            value={values.uniqueName}
            onChange={handleInputChange}
            errors={errors.uniqueName}
          />
          <Controls.Input
            label="Content"
            name="content"
            value={values.content}
            errors={errors.content}
            onChange={handleInputChange}
            multiline
            fullWidth
            minRows={4}
          />
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Save"
              service={handleSubmit}
              onSuccess={() => onSuccess(values)}
              successMsg={successMsg}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

TemplateForm.propTypes = {
  values: PropTypes.shape({
    uniqueName: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMsg: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
