import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import ButtonWithModal from '../../components/ButtonWithModal';
import ReasonForVisitForm from './ReasonForVisitForm';
import { useForm } from '../../components/useForm';

const reasonForVisitDefaultValues = {
  c2iName: '',
  layName: '',
  nlpClassName: '',
};

export default function ReasonForVisitAdditionButton(props) {
  const {
    refreshParent,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(reasonForVisitDefaultValues);

  const resetValues = () => setValues(reasonForVisitDefaultValues);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const onSubmit = () => {
    const reasonForVisitCreateRequest = {
      c2iName: values.c2iName,
      layName: values.layName,
      nlpClassName: values.nlpClassName,
    };

    return apiClient.createReasonForVisit(reasonForVisitCreateRequest);
  };

  const onSuccess = () => {
    refreshParent();
    setModalIsOpen(false);
    toastSetSuccessNotification('Reason For Visit created.');
  };

  const onFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  return (
    <ButtonWithModal
      buttonText="Create Reason For Visit"
      modalTitle="Create Reason For Visit"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={resetValues}
    >
      <ReasonForVisitForm
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onFail={onFail}
        values={values}
        handleInputChange={handleInputChange}
      />
    </ButtonWithModal>
  );
}

ReasonForVisitAdditionButton.propTypes = {
  refreshParent: PropTypes.func.isRequired,
};
