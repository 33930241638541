import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import AttributeContextTreeItem from './AttributeContextTreeItem';

export default function AttributeContextParentTreeItem(props) {
  const {
    setFormPane, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem();

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes([
      <AttributeContextTreeItem
        key="patient"
        label="Patient"
        contextType="patient"
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
      <AttributeContextTreeItem
        key="symptom"
        label="Symptom"
        contextType="symptom"
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
      <AttributeContextTreeItem
        key="medication"
        label="Medication"
        contextType="medication"
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
    ]);
  };

  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel="Attributes"
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
    />
  );
}

AttributeContextParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
