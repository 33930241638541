import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function AnyOrAllOfConditionCreationSubForm(props) {
  const {
    conditionType,
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  return (
    <Box>
      <ServiceButtonWithToast
        buttonText="Save"
        service={() => onSubmit({ conditionType })}
        onSuccess={onSuccess}
        successMsg={successMsg}
      />
    </Box>
  );
}

AnyOrAllOfConditionCreationSubForm.propTypes = {
  conditionType: PropTypes.oneOf(['anyOf', 'allOf']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

AnyOrAllOfConditionCreationSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
