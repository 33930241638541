import React from 'react';
import PropTypes from 'prop-types';

import TemplateListCreateButtonWithModal from '../TemplateList/TemplateListCreateButtonWithModal';

export default function TemplateCategoryPane(props) {
  const {
    categoryLabel, categoryUniqueName, refreshParent,
  } = props;

  return (
    <TemplateListCreateButtonWithModal
      categoryLabel={categoryLabel}
      categoryUniqueName={categoryUniqueName}
      refreshParent={refreshParent}
    />
  );
}

TemplateCategoryPane.propTypes = {
  categoryLabel: PropTypes.string.isRequired,
  categoryUniqueName: PropTypes.string.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
