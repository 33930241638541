import React from 'react';
import PropTypes from 'prop-types';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import TranslationPane from './TranslationPane';

export default function TranslationParentTreeItem(props) {
  const {
    setFormPane,
  } = props;
  const {
    MCTreeItemLabel,
  } = useMedicalContentTreeItem('Translation Utilities');

  const onSelect = () => setFormPane(
    <TranslationPane />,
  );

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      onSelect={onSelect}
      isEndNode
    />
  );
}

TranslationParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
};
