import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AttributeSelector from '../../Attribute/attribute/findExisting/AttributeSelector';
import AttributeValueSelector from '../../Attribute/attributeValue/AttributeValueSelector';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function FixedAttributeValueEndorsedSubForm(props) {
  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const [contextType, setContextType] = useState('patient');
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const [selectedAttributeValueId, setSelectedAttributeValueId] = useState(null);

  const service = () => onSubmit({
    conditionType: 'fixedAttributeValueEndorsed',
    attributeValueId: selectedAttributeValueId,
  });

  return (
    <Box>
      <AttributeSelector
        requiredSubtype="string"
        selectedContextType={contextType}
        setSelectedContextType={setContextType}
        selectedAttributeId={selectedAttributeId}
        setSelectedAttributeId={setSelectedAttributeId}
        excludeAttributesWithoutValues
      />
      {selectedAttributeId && (
        <AttributeValueSelector
          attributeIdToFindValuesFor={selectedAttributeId}
          selectedAttributeValueId={selectedAttributeValueId}
          setSelectedAttributeValueId={setSelectedAttributeValueId}
        />
      )}
      {selectedAttributeValueId
      && (
      <ServiceButtonWithToast
        buttonText="Save"
        service={service}
        onSuccess={onSuccess}
        successMsg={successMsg}
      />
      )}
    </Box>
  );
}

FixedAttributeValueEndorsedSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

FixedAttributeValueEndorsedSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
