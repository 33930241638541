import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DriveFileMove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ModuleFindForm from '../Module/ModuleFindForm';
import Modal from '../../components/Modal';

export default function MoveToNewModuleButton({ moveService, onMoveSuccess }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <IconButton
        disabled={!moveService}
        onClick={() => setModalIsOpen(true)}
      >
        <DriveFileMove />
      </IconButton>
      <Modal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        title="Move to a new module"
      >
        <ModuleFindForm
          onModuleSelected={(selectedModule) => moveService(selectedModule)}
          onSuccess={() => {
            onMoveSuccess();
            setModalIsOpen(false);
          }}
          successMessage="Question moved."
        />
      </Modal>
    </>
  );
}

MoveToNewModuleButton.propTypes = {
  moveService: PropTypes.func,
  onMoveSuccess: PropTypes.func,
};

MoveToNewModuleButton.defaultProps = {
  moveService: null,
  onMoveSuccess: () => {},
};
