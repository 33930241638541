import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';
import Form from '../../components/useForm';
import { Controls } from '../../components/controls/Controls';
import ModuleSelector from '../Module/ModuleSelector';

export const intakePlanDefaultValues = {
  referenceCode: '',
  customerDisplayName: '',
  description: '',
  moduleId: null,
};

export default function IntakePlanForm(props) {
  const {
    values, setValues, handleInputChange, onSubmit, onSuccess, successMsg, errorMsg,
  } = props;

  const [errors, setErrors] = useState({});

  const validate = () => {
    const inputErrorMessages = {};
    inputErrorMessages.referenceCode = values.referenceCode === '' ? 'Reference Code cannot be empty.' : '';
    inputErrorMessages.customerDisplayName = values.customerDisplayName === '' ? 'CustomerDisplayName cannot be empty.' : '';
    inputErrorMessages.moduleId = values.moduleId === '' ? 'Select a module.' : '';

    setErrors(inputErrorMessages);
    return Object.values(inputErrorMessages).every((item) => item === '');
  };

  const handleSubmit = () => (validate() ? onSubmit(values) : Promise.reject(new Error('Form Validation Error')));

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          {/* Later we could add an Input to modify the uniqueName, but for
             now uniqueName will just default to the module name for simplicity */}
          <Controls.Input
            label="Reference Code"
            name="referenceCode"
            value={values.referenceCode}
            onChange={handleInputChange}
            errors={errors.referenceCode}
            helperText="Seen by Customer Success when assigning intake plan to customers"
          />
          <Controls.Input
            label="Customer Display Name"
            name="customerDisplayName"
            value={values.customerDisplayName}
            onChange={handleInputChange}
            errors={errors.customerDisplayName}
            helperText="Seen by the customer when sending intakes"
          />
          <Controls.Input
            label="C2i Description (optional)"
            name="description"
            value={values.description}
            multiline
            fullWidth
            onChange={handleInputChange}
            helperText="Long-form description seen in C2i only"
          />
          <ModuleSelector
            selectedModule={values.moduleId ? { id: values.moduleId } : null}
            setSelectedModule={(selectedModule) => setValues({
              ...values,
              moduleId: selectedModule.id,
            })}
          />
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Save"
              service={handleSubmit}
              onSuccess={() => onSuccess(values)}
              successMsg={successMsg}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

IntakePlanForm.propTypes = {
  values: PropTypes.shape({
    referenceCode: PropTypes.string.isRequired,
    customerDisplayName: PropTypes.string.isRequired,
    description: PropTypes.string,
    moduleId: PropTypes.number,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMsg: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
