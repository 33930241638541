import React from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../components/controls/Controls';
import Form from '../../components/useForm';

function MainClassForm({
  className,
  setClassName,
  onSubmit,
  controlsDisabled,
  serviceLoading,
}) {
  const handleChange = ({ target: { value } }) => {
    setClassName(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
    setClassName('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Controls.Input
        disabled={controlsDisabled}
        name="mainClassName"
        label="Medication Class Name"
        value={className}
        onChange={handleChange}
        placeholder="Enter class name"
      />
      <Controls.Button
        variant="contained"
        type="submit"
        text="Save"
        size="medium"
        style={{ margin: '6px' }}
        loading={serviceLoading}
      />
    </Form>
  );
}

MainClassForm.propTypes = {
  className: PropTypes.string.isRequired,
  setClassName: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  controlsDisabled: PropTypes.bool,
  serviceLoading: PropTypes.bool,
};

MainClassForm.defaultProps = {
  controlsDisabled: false,
  serviceLoading: false,
};

export default MainClassForm;
