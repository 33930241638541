import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box, Chip, Grid, Typography,
} from '@mui/material';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';
import AttributeFindButtonWithModal from '../attribute/findExisting/AttributeFindButtonWithModal';

export default function AttributeFollowersForm(props) {
  const {
    attributeId, contextType,
  } = props;

  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const [followers, setFollowers] = useState([]);

  const fetchFollowers = () => {
    basicServiceHandler(
      () => apiClient.getAttributeFollowers(attributeId),
      (response) => setFollowers(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  useEffect(fetchFollowers, [attributeId]);

  const handleRemoveReportFollower = (followerId) => {
    basicServiceHandler(
      () => apiClient.removeReportFollower(attributeId, followerId),
      fetchFollowers,
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  };

  return (
    <Grid item xs={10}>
      <Typography variant="h6">Reporting Tethers</Typography>
      {!!followers.length && (
      <>
        <Typography variant="subtitle1">Followers</Typography>
        <Box marginBottom={2}>
          {followers.map((follower) => (
            <Chip
              key={follower.id}
              label={follower.uniqueName}
              onDelete={() => handleRemoveReportFollower(follower.id)}
              style={{ margin: '4px' }}
            />
          ))}
        </Box>
      </>
      )}
      <Box marginTop={2}>
        <AttributeFindButtonWithModal
          initialContextType={contextType}
          addExistingAttributeCallbacks={{
            service: (selectedAttributeId) => apiClient.addReportFollower(
              attributeId,
              selectedAttributeId,
            ),
            onSuccess: fetchFollowers,
          }}
          buttonText="Add a Follower"
          allowContextChange={false}
        />
      </Box>
    </Grid>
  );
}

AttributeFollowersForm.propTypes = {
  attributeId: PropTypes.number.isRequired,
  contextType: PropTypes.oneOf(['patient', 'symptom', 'medication', '']).isRequired,
};
