import React from 'react';
import PropTypes from 'prop-types';

import ModuleTreeItem from './ModuleTreeItem';
import CreateModuleButton from './CreateModuleButton';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import { useApiClient } from '../../context/ApiClientContext';

export default function ModulesParentTreeItem(props) {
  const {
    setFormPane,
    setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Modules');

  const fetchChildNodes = async () => {
    const results = await apiClient.getModules();
    const treeItems = results.data.map((result) => (
      <ModuleTreeItem
        key={`${result.uniqueName}_${result.id}`}
        id={result.id}
        actionListId={result.actionListId}
        label={result.uniqueName}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
        onDeleteWarningMessage={`Permanently delete the module "${result.uniqueName}"?`}
        deleteService={() => apiClient.deleteModule(result.id)}
      />
    ));
    setMCTreeItemChildNodes(treeItems);
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <CreateModuleButton reloadCallback={reloadAndExpandNode} />,
    );
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No modules yet..."
    />
  );
}

ModulesParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
