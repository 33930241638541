import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import TemplateListSelector from '../../Template/TemplateList/TemplateListSelector';

function OfferTemplatesButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const handleAdd = (
    (listId) => apiClient.addOfferTemplatesAction(actionListId, listId)
  );

  const onSuccess = () => {
    reloadCallback();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Offer Templates With Report"
      modalTitle="Select a list of templates to offer"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Grid container>
        <Grid item xs={6}>
          <TemplateListSelector
            handleSubmit={handleAdd}
            buttonText="Add"
            successMsg="Added"
            onSuccess={onSuccess}
          />
        </Grid>
      </Grid>
    </ButtonWithModal>
  );
}

OfferTemplatesButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default OfferTemplatesButton;
