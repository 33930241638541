import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import generateConditionLabel from '../generateConditionLabel';
// eslint-disable-next-line import/no-cycle
import AnyOrAllOfConditionEditForm from './AnyOrAllOfConditionEditForm';
import Form from '../../../components/useForm';

export default function SubconditionForm(props) {
  const {
    subcondition,
    onSuccess,
  } = props;

  if (subcondition.conditionType === 'anyOf' || subcondition.conditionType === 'allOf') {
    return (
      <Grid container key={`anyAll_${subcondition.id}`} direction="row">
        <Typography variant="subtitle1" sx={{ ml: 2 }}>
          {'-  '}
        </Typography>
        <Grid item xs={10} sx={{ marginLeft: 1 }}>
          <Form>
            <AnyOrAllOfConditionEditForm
              conditionId={subcondition.id}
              conditionType={subcondition.conditionType}
              subconditions={subcondition.subconditions}
              onSuccess={onSuccess}
            />
          </Form>
        </Grid>
      </Grid>
    );
  }

  const label = generateConditionLabel(subcondition);
  return (
    <Typography key={label} variant="subtitle1" sx={{ ml: 2 }}>
      {`-  ${label}`}
    </Typography>
  );
}

SubconditionForm.propTypes = {
  subcondition: PropTypes.shape({
    id: PropTypes.number,
    conditionType: PropTypes.string,
    subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
