import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';

const initialFieldValues = {
  dxHxName: null,
};

export default function DxHxForm(props) {
  const { id, updateCallback } = props;
  const [loading, setLoading] = useState(false);

  const apiClient = useApiClient();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const isLoaded = () => Object.values(values).every((item) => item !== null);
  const handleSave = async () => {
    setLoading(true);
    basicServiceHandler(
      () => apiClient.updateDxHx(id, values.dxHxName),
      () => {
        updateCallback(values.dxHxName);
        toastSetSuccessNotification('Update successful!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => setLoading(false),
    );
  };

  useEffect(() => {
    apiClient.getDxHx(id).then((result) => {
      const { name } = result.data;
      setValues({ dxHxName: name });
    }).catch((error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)));
  }, [id]);
  return (
    !isLoaded()
      ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
      : (

        <Form>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                label="DxHx Name"
                name="dxHxName"
                value={values.dxHxName}
                onChange={handleInputChange}
              />
              <Grid container justifyContent="flex-end">
                <Controls.Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{ margin: '6px' }}
                  loading={loading}
                  text="Save"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )
  );
}

DxHxForm.propTypes = {
  id: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
};

DxHxForm.defaultProps = {
  updateCallback: null,
};
