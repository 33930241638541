import React, { useState } from 'react';

import { useForm } from '../../../../components/useForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import AttributeCreateEditForm from './AttributeCreateEditForm';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export const blankAttributeViewValues = {
  contextType: '',
  uniqueName: '',
  reportPrestring: '',
  reportPoststring: '',
  reportSection: 'middle',
  reportEmptyLineAbove: false,
  reportStyle: 'oneLine',
};

export default function AttributeCreateButtonWithModal({ createCallbacks }) {
  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(blankAttributeViewValues);

  const resetValues = () => setValues(blankAttributeViewValues);

  const [isOpen, setIsOpen] = useState(false);

  const childCallbacks = {
    ...createCallbacks,
    service: () => createCallbacks.service(values),
    onSuccess: (result) => {
      setIsOpen(false);
      createCallbacks.onSuccess(result);
    },
  };

  return (
    <ButtonWithModal
      buttonText="Create New Attribute"
      modalTitle="Create Attribute"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={resetValues}
    >
      <AttributeCreateEditForm
        values={values}
        handleInputChange={handleInputChange}
        callbacks={childCallbacks}
      />
    </ButtonWithModal>
  );
}

AttributeCreateButtonWithModal.propTypes = {
  createCallbacks: serviceCallbackPropTypes.isRequired,
};
