import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttributeValueFindForm from './AttributeValueFindForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export default function AttributeValueFindButtonWithModal({
  attributeIdToFindValuesFor, addExistingCallbacks,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAttributeValueId, setSelectedAttributeValueId] = useState(null);

  const callbacks = {
    ...addExistingCallbacks,
    service: () => addExistingCallbacks.service(selectedAttributeValueId),
  };

  return (
    <ButtonWithModal
      buttonText="Select Existing Value"
      modalTitle="Select Value"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={() => setSelectedAttributeValueId(null)}
    >
      <AttributeValueFindForm
        selectedAttributeValueId={selectedAttributeValueId}
        setSelectedAttributeValueId={setSelectedAttributeValueId}
        attributeIdToFindValuesFor={attributeIdToFindValuesFor}
        callbacks={callbacks}
      />
    </ButtonWithModal>
  );
}

AttributeValueFindButtonWithModal.propTypes = {
  attributeIdToFindValuesFor: PropTypes.number.isRequired,
  addExistingCallbacks: serviceCallbackPropTypes.isRequired,
};
