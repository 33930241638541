import React from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../context/ApiClientContext';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import MedMainClassPane from './MedMainClassPane';
import renderActionList from '../Actions/renderActionList';

export default function MedMainClassTreeItem(props) {
  const {
    id,
    actionListId,
    label,
    setFormPane,
    onDeleteWarningMessage,
    onDeleteConfirmed,
    refreshParent,
    setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    updateLabel,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListId,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <MedMainClassPane
        mainClassId={id}
        label={label}
        updateCallback={updateLabel}
        refreshParent={reloadAndExpandNode}
        actionListId={actionListId}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService: onDeleteConfirmed,
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No actions under this class yet..."
    />
  );
}

MedMainClassTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  actionListId: PropTypes.number.isRequired,
};
