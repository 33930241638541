import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import ScoreFactorConditionForm from './ScoreFactorConditionForm';
import Dropdown from '../../components/controls/Dropdown';
import Form from '../../components/useForm';
import ScoreFactorNumericAttributeForm from './ScoreFactorNumericAttributeForm';

export default function ScoreFactorForm(props) {
  const {
    initialFieldValues, onSubmit, onSuccess, onFail,
  } = props;

  const [subtype, setSubtype] = useState('condition');

  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <Dropdown
            value={subtype}
            name="factorType"
            onChange={(e) => setSubtype(e.target.value)}
            label="Factor Type"
            options={[
              { id: 'condition', name: 'Add points if a condition is true' },
              { id: 'numericAttribute', name: 'Apply a numeric attribute' },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {subtype === 'condition'
      && (
      <ScoreFactorConditionForm
        initialFieldValues={initialFieldValues}
        onSubmit={(values) => onSubmit({ subtype: 'condition', ...values })}
        onSuccess={onSuccess}
        onFail={onFail}
      />
      )}
          {subtype === 'numericAttribute'
      && (
      <ScoreFactorNumericAttributeForm
        initialFieldValues={initialFieldValues}
        onSubmit={(values) => onSubmit({ subtype: 'numericAttribute', ...values })}
        onSuccess={onSuccess}
        onFail={onFail}
      />
      )}
        </Grid>
      </Grid>
    </Form>
  );
}

ScoreFactorForm.propTypes = {
  initialFieldValues: PropTypes.shape({
    points: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

ScoreFactorForm.defaultProps = {
  initialFieldValues: { points: 0 },
};
