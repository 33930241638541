import React, { } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';
import AttributeAndValueCrudForm from '../Attribute/AttributeAndValueCrudForm';
import { attributeFieldValuesPropType } from '../Attribute/attribute/AttributeFormContent';
import { attributeValueFieldValuesPropType } from '../Attribute/attributeValue/AttributeValueFormContent';

export default function FixedAnswerFormSubFormAttributeAndValue(props) {
  const {
    attributeId, attributeFieldValues, attributeValueId,
    attributeValueFieldValues, fixedAnswerId, isSelected, updateCallback,
  } = props;

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const makeCallbacks = (service, successMessage, errorMessage) => ({
    service,
    onSuccess: (result) => {
      toastSetSuccessNotification(successMessage);
      updateCallback(result.data);
    },
    errorMsg: errorMessage,
  });

  // Attribute callbacks
  // -------------------

  const createAttributeCallbacks = makeCallbacks((
    attributeCreateRequest,
  ) => apiClient.createAttributeForFixedAnswer(
    fixedAnswerId,
    isSelected,
    attributeCreateRequest,
  ), 'Attribute created.', 'Error creating Attribute');

  const addExistingAttributeCallbacks = makeCallbacks((
    attributeAddRequest,
  ) => apiClient.addExistingAttributeToFixedAnswer(
    fixedAnswerId,
    isSelected,
    attributeAddRequest,
  ), 'Attribute added.', 'Error adding Attribute');

  const removeAttributeCallbacks = makeCallbacks(
    () => apiClient.removeAttributeFromFixedAnswer(fixedAnswerId, isSelected),
    'Attribute removed',
    'Error removing Attribute',
  );

  // update is a bit different because service.updateAttribute returns just
  // the attribute, not the whole fixedAnswer. We do a fetch for the answerFormView again if the
  // update succeeds.
  const updateAttributeCallbacks = attributeId ? {
    service: (attributeCreateUpdateRequest) => apiClient.updateAttribute(
      attributeId,
      attributeCreateUpdateRequest,
    ),
    onSuccess: () => basicServiceHandler(
      () => apiClient.getAnswer(fixedAnswerId),
      (result) => {
        updateCallback(result.data);
        toastSetSuccessNotification('Attribute updated');
      },
      (error) => toastSetErrorNotification(
        `The Attribute was updated, but there was an error refreshing the Answer. 
        ${convertAxiosErrorToUserFacingMessage(error)}`,
      ),
      () => {},
    ),
    onFail: (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
  } : null;

  // AttributeValue callbacks (similar pattern to Attribute callbacks)
  // ------------------------

  const createAttributeValueCallbacks = makeCallbacks((
    attributeValueCreateRequest,
  ) => apiClient.createAttributeValueForFixedAnswer(
    fixedAnswerId,
    isSelected,
    attributeValueCreateRequest,
  ), 'Attribute Value created.');

  const addExistingAttributeValueCallbacks = makeCallbacks((
    attributeValueAddRequest,
  ) => apiClient.addExistingAttributeValueToFixedAnswer(
    fixedAnswerId,
    isSelected,
    attributeValueAddRequest,
  ), 'Attribute Value added.');

  const removeAttributeValueCallbacks = makeCallbacks(
    () => apiClient.removeAttributeValueFromFixedAnswer(fixedAnswerId, isSelected),
    'Attribute Value removed',
  );

  const updateAttributeValueCallbacks = attributeValueId ? {
    service: (attributeValueCreateUpdateRequest) => apiClient.updateAttributeValue(
      attributeValueId,
      attributeValueCreateUpdateRequest,
    ),
    onSuccess: () => basicServiceHandler(
      () => apiClient.getAnswer(fixedAnswerId),
      (result) => {
        updateCallback(result.data);
        toastSetSuccessNotification('Attribute Value updated');
      },
      (error) => toastSetErrorNotification(`The Attribute Value was updated, but there was an error updating the Answer. ${convertAxiosErrorToUserFacingMessage(error)}`),
      () => {},
    ),
    onFail: (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
  } : null;

  return (
    <AttributeAndValueCrudForm
      attributeId={attributeId}
      attributeFieldValues={attributeFieldValues}
      attributeValueFieldValues={attributeValueFieldValues}
      createAttributeCallbacks={createAttributeCallbacks}
      updateAttributeCallbacks={updateAttributeCallbacks}
      addExistingAttributeCallbacks={addExistingAttributeCallbacks}
      removeAttributeCallbacks={removeAttributeCallbacks}
      createAttributeValueCallbacks={createAttributeValueCallbacks}
      updateAttributeValueCallbacks={updateAttributeValueCallbacks}
      addExistingAttributeValueCallbacks={addExistingAttributeValueCallbacks}
      removeAttributeValueCallbacks={removeAttributeValueCallbacks}
    />
  );
}

FixedAnswerFormSubFormAttributeAndValue.propTypes = {
  attributeId: PropTypes.number,
  attributeFieldValues: attributeFieldValuesPropType,
  attributeValueId: PropTypes.number,
  attributeValueFieldValues: attributeValueFieldValuesPropType,
  isSelected: PropTypes.bool.isRequired,
  fixedAnswerId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
};

FixedAnswerFormSubFormAttributeAndValue.defaultProps = {
  attributeId: null,
  attributeFieldValues: null,
  attributeValueId: null,
  attributeValueFieldValues: null,
  updateCallback: null,
};
