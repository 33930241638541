import React from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

export default function TranslationModuleLocalesTable({ moduleLocales }) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Object.entries(moduleLocales).map(([locale, modules]) => (
            <TableRow key={locale}>
              <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                {locale.toUpperCase()}
              </TableCell>
              <TableCell>
                {modules.map((module) => (
                  <div key={module}>{module}</div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}

TranslationModuleLocalesTable.propTypes = {
  moduleLocales: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
};
