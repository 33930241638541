/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';
import TreeItem from './TreeItem';

function LazyLoadingTreeItem(props) {
  const {
    nodeId, label, children, expandCallback, onSelect, isEndNode, expand, textToDisplayIfNoChildren,
  } = props;
  const [expanded, setExpanded] = useState(expand ? [nodeId.toString()] : []);
  const [selected, setSelected] = useState(null);

  const handleClickAway = () => {
    setSelected(null);
  };

  const handleToggle = (event, nodeIds) => {
    const expandingNodes = nodeIds.filter((node) => !expanded.includes(node));
    setExpanded(nodeIds);
    setSelected(nodeIds);
    onSelect();
    // TODO: There should only be 1 node in array, can probably remove filter in the future
    const id = expandingNodes[0];
    if (expandingNodes.length && id === nodeId) {
      expandCallback();
    }
  };

  useEffect(() => {
    setExpanded(expand ? [nodeId.toString()] : []);
  }, [expand]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TreeView
        aria-label="icon expansion"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeSelect={(e, node) => {
          setSelected(node);
          onSelect();
        }}
        onNodeToggle={handleToggle}
        selected={selected}
      >
        <TreeItem nodeId={nodeId.toString()} label={label}>
          {
          // if isEndNode
          isEndNode
            // then the node can never have children, so render no chevron
            ? null
            // else, check the status of `children`.  If it's null, it has not yet been prefetched
            : children === null
              // so pre-render a circular progress icon to display on expansion during fetch process
              ? <CircularProgress size={12} />
              // else, `children` has already been fetched.  If it's an empty array,
              : (children.length === 0
                // display a dummy node with the text from `textToDisplayIfNoChildren`
                ? <TreeItem nodeId="dummy" label={textToDisplayIfNoChildren} sx={{ fontStyle: 'italic' }} />
                // else display the child nodes
                : children
              )
          }
        </TreeItem>
      </TreeView>
    </ClickAwayListener>
  );
}

LazyLoadingTreeItem.propTypes = {
  nodeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array,
  label: PropTypes.string.isRequired,
  expandCallback: PropTypes.func,
  onSelect: PropTypes.func,
  isEndNode: PropTypes.bool,
  expand: PropTypes.bool,
  textToDisplayIfNoChildren: PropTypes.string,
};

LazyLoadingTreeItem.defaultProps = {
  children: [],
  onSelect: null,
  isEndNode: false,
  expand: false,
  expandCallback: null,
  textToDisplayIfNoChildren: 'No children yet...',
};

export default LazyLoadingTreeItem;
