import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import ScoreForm from './ScoreForm';
import ButtonWithModal from '../../components/ButtonWithModal';

export default function ScoreParentPane(props) {
  const {
    refreshParent,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const onSubmit = (values) => apiClient.createScore(values.uniqueName, values.reportPreString);

  const onSuccess = () => {
    refreshParent();
    setModalIsOpen(false);
    toastSetSuccessNotification('Score created.');
  };

  const onFail = (error) => {
    toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
  };

  return (
    <ButtonWithModal
      buttonText="Create Score"
      modalTitle="Create Score"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <ScoreForm
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onFail={onFail}
      />
    </ButtonWithModal>
  );
}

ScoreParentPane.propTypes = {
  refreshParent: PropTypes.func.isRequired,
};
