import React from 'react';
import PropTypes from 'prop-types';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import TemplateListTreeItem from '../TemplateList/TemplateListTreeItem';
import { useApiClient } from '../../../context/ApiClientContext';
import TemplateCategoryPane from './TemplateCategoryPane';

export default function TemplateCategoryTreeItem(props) {
  const {
    label, categoryUniqueName, setFormPane, setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
    expand,
    setExpand,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    const results = await apiClient.getTemplateLists(categoryUniqueName);
    setMCTreeItemChildNodes(results.data.map((result) => (
      <TemplateListTreeItem
        key={result.id}
        listId={result.id}
        listUniqueName={result.uniqueName}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
      />
    )));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(<TemplateCategoryPane
      categoryLabel={label}
      categoryUniqueName={categoryUniqueName}
      refreshParent={reloadAndExpandNode}
    />);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={label}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No lists yet..."
    />
  );
}

TemplateCategoryTreeItem.propTypes = {
  label: PropTypes.string.isRequired,
  categoryUniqueName: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
