import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import TemplateForm, { templateDefaultValues } from './TemplateForm';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';
import { useForm } from '../../../components/useForm';

export default function TemplatePane(props) {
  const {
    templateId, refreshParent,
  } = props;

  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(templateDefaultValues);

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getTemplate(templateId),
      (response) => setValues(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  }, [templateId]);

  const onSubmit = () => apiClient.updateTemplate(templateId, {
    uniqueName: values.uniqueName,
    content: values.content,
  });

  return (
    values
      ? (
        <TemplateForm
          values={values}
          handleInputChange={handleInputChange}
          onSubmit={onSubmit}
          onSuccess={refreshParent}
          successMsg="Saved"
          errorMsg="Error saving template"
        />
      ) : <CircularProgress />
  );
}

TemplatePane.propTypes = {
  templateId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
