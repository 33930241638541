import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function CreateModuleButton(props) {
  const { actionListId, reloadCallback } = props;
  const apiClient = useApiClient();

  return (
    <ServiceButtonWithToast
      buttonText="Create Module"
      service={() => apiClient.addModule(actionListId)}
      onSuccess={reloadCallback}
      successMsg="Module created"
      size="large"
    />
  );
}

CreateModuleButton.propTypes = {
  actionListId: PropTypes.number,
  reloadCallback: PropTypes.func,
};

CreateModuleButton.defaultProps = {
  actionListId: null,
  reloadCallback: null,
};
