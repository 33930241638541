import React, { } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import Form from '../../../../components/useForm';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';
import ServiceButtonWithToast from '../../../_common/ServiceButtonWithToast';
import AttributeValueSelector from './AttributeValueSelector';

export default function AttributeValueFindForm(props) {
  const {
    attributeIdToFindValuesFor,
    selectedAttributeValueId,
    setSelectedAttributeValueId,
    callbacks,
  } = props;
  const {
    service, onSuccess, successMsg, errorMsg,
  } = callbacks;

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <AttributeValueSelector
            attributeIdToFindValuesFor={attributeIdToFindValuesFor}
            selectedAttributeValueId={selectedAttributeValueId}
            setSelectedAttributeValueId={setSelectedAttributeValueId}
          />
          <Grid container justifyContent="flex-end">
            <ServiceButtonWithToast
              buttonText="Select"
              service={service}
              onSuccess={onSuccess}
              successMsg={successMsg}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

AttributeValueFindForm.propTypes = {
  attributeIdToFindValuesFor: PropTypes.number.isRequired,
  selectedAttributeValueId: PropTypes.number,
  setSelectedAttributeValueId: PropTypes.func.isRequired,
  callbacks: serviceCallbackPropTypes.isRequired,
};

AttributeValueFindForm.defaultProps = {
  selectedAttributeValueId: '',
};
