import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem from '../../_common/MedicalContentTreeItem';
import AttributePane from './AttributePane';

export default function AttributeTreeItem(props) {
  const {
    attributeId,
    attributeUniqueName,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
  } = props;

  const onSelect = () => {
    setFormPane(
      <AttributePane
        attributeId={attributeId}
        refreshParent={refreshParent}
      />,
    );
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={attributeId}
      MCTreeItemLabel={attributeUniqueName}
      isEndNode
      onSelect={onSelect}
    />
  );
}

AttributeTreeItem.propTypes = {
  attributeId: PropTypes.number.isRequired,
  attributeUniqueName: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
