import React, { } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Controls } from '../../components/controls/Controls';

export default function ReportSectionDropdown({ selectedSection, onChange, disabled }) {
  return (
    <Controls.Input
      disabled={disabled}
      select
      label="Report Section"
      name="reportSection"
      value={selectedSection}
      onChange={onChange}
      fullWidth
    >
      <MenuItem key="reportSectionTop" value="top">Top</MenuItem>
      <MenuItem key="reportSectionMiddle" value="middle">Middle</MenuItem>
      <MenuItem key="reportSectionBottom" value="bottom">Bottom</MenuItem>
    </Controls.Input>
  );
}

ReportSectionDropdown.propTypes = {
  selectedSection: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ReportSectionDropdown.defaultProps = {
  disabled: false,
  onChange: null,
};
