import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';

import SxOfDxhxTreeItem from './SxOfDxhxTreeItem';
import SxOfDxHxParentPane from './SxOfDxhxParentPane';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

export default function SxOfDxhxParentTreeItem(props) {
  const {
    id, setFormPane, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Symptoms');

  const apiClient = useApiClient();

  const fetchChildNodes = async () => {
    const results = await apiClient.getDxHxSymptoms(id);
    setMCTreeItemChildNodes(results.data.map((result) => {
      let label = result.symptom.medicalName;
      if (result.symptom.medicalName !== result.symptom.layName) {
        label += ` (pt sees: "${result.symptom.layName}")`;
      }
      return (
        <SxOfDxhxTreeItem
          key={`${result.symptom.medicalName}_${result.symptom.id}`}
          sxOfDxhxId={result.id}
          dxhxId={id}
          symptomId={result.symptom.id}
          actionListId={result['actionListId-endorsed']}
          label={label}
          setFormPane={setFormPane}
          setTreeManagementServices={setTreeManagementServices}
          refreshParent={fetchChildNodes}
        />
      );
    }));
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(<SxOfDxHxParentPane dxhxId={id} refreshParent={reloadAndExpandNode} />);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No symptoms yet..."
    />
  );
}

SxOfDxhxParentTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
