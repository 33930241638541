import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import AttributeParentTreeItem from './AttributeParentTreeItem';

export default function AttributeContextTreeItem(props) {
  const {
    label, setFormPane, setTreeManagementServices, contextType,
  } = props;

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem();

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes([
      <AttributeParentTreeItem
        key="String"
        label="String"
        contextType={contextType}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
      <AttributeParentTreeItem
        key="Numeric"
        label="Numeric"
        contextType={contextType}
        numeric
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
    ]);
  };

  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={label}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
    />
  );
}

AttributeContextTreeItem.propTypes = {
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  contextType: PropTypes.oneOf(['patient', 'symptom', 'medication']).isRequired,
};
