import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../../components/useForm';
import Dropdown from '../../../components/controls/Dropdown';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function SexConditionSubForm(props) {
  const initialFieldValues = {
    sex: '',
  };

  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const {
    values,
    setValues,
    errors,
  } = useForm(initialFieldValues);

  const { sex } = values;

  const handleSexChange = (e) => {
    const newVal = e.target.value;
    setValues({ sex: newVal });
  };

  const handleSave = () => {
    const condition = {};
    if (sex === 'M') {
      condition.conditionType = 'sexIsMale';
    } else if (sex === 'F') {
      condition.conditionType = 'sexIsFemale';
    } else if (sex === 'undefined') {
      condition.conditionType = 'sexIsUndefined';
    }
    return onSubmit(condition);
  };

  const sexOptionList = ['M', 'F', 'undefined'];
  const sexOptions = sexOptionList.map((val) => ({ id: val, name: val }));

  return (
    <div>
      <Dropdown
        value={sex}
        name="sex"
        onChange={handleSexChange}
        options={sexOptions}
        error={errors.sex}
      />
      <ServiceButtonWithToast
        buttonText="Save"
        service={handleSave}
        onSuccess={onSuccess}
        successMsg={successMsg}
      />
    </div>
  );
}

SexConditionSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

SexConditionSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
