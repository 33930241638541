import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import Modal from '../../components/Modal';
import { Controls } from '../../components/controls/Controls';
import MainClassForm from './MainClassForm';

function MedMainClassAdditionButton({ reloadCallback }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainClassName, setMainClassName] = useState('');

  const apiClient = useApiClient();
  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setMainClassName('');
  };

  const handleAddMainClass = async () => {
    try {
      await apiClient.createMainClass({ name: mainClassName });
      reloadCallback();
      setIsModalOpen(false);
      setMainClassName('');
      toastSetSuccessNotification('Medication class added!');
    } catch (err) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(err));
    }
  };

  return (
    <>
      <Controls.Button
        variant="contained"
        onClick={handleModalOpen}
        style={{ margin: '6px' }}
        text="Create a new medication class"
      />
      <Modal isOpen={isModalOpen} handleClose={handleModalClose} title="Create a new medication class">
        <Box>
          <MainClassForm
            className={mainClassName}
            setClassName={setMainClassName}
            onSubmit={handleAddMainClass}
            submitText="Add"
          />
        </Box>
      </Modal>
    </>
  );
}

MedMainClassAdditionButton.propTypes = {
  reloadCallback: PropTypes.func.isRequired,
};

export default MedMainClassAdditionButton;
