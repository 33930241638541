import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form from '../../../../components/useForm';
import AttributeFormContent, { attributeFieldValuesPropType } from '../AttributeFormContent';
import ServiceButtonWithToast from '../../../_common/ServiceButtonWithToast';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export default function AttributeCreateEditForm(props) {
  const {
    values, handleInputChange, callbacks,
  } = props;
  const {
    service, onSuccess, successMsg, errorMsg,
  } = callbacks;

  return (
    <Form>

      <AttributeFormContent
        values={values}
        handleInputChange={handleInputChange}
      />

      <Grid container justifyContent="flex-end">
        <ServiceButtonWithToast
          buttonText="Save"
          service={service}
          onSuccess={onSuccess}
          successMsg={successMsg}
          errorMsg={errorMsg}
        />
      </Grid>

    </Form>
  );
}

AttributeCreateEditForm.propTypes = {
  values: attributeFieldValuesPropType.isRequired,
  handleInputChange: PropTypes.func,
  callbacks: serviceCallbackPropTypes.isRequired,
};

AttributeCreateEditForm.defaultProps = {
  handleInputChange: null,
};
