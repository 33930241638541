import { useEffect } from 'react';

import useAuth from './useAuth';
import { useRefreshTokens } from '../services/auth/authServices';

// This custom hook is responsible for adding an authorization header to Axios requests
// using the user's access token. It also refreshes the access token if it has expired
// and the user has a refresh token.
const useAxiosPrivate = (axiosInstance) => {
  const refresh = useRefreshTokens();
  const { auth } = useAuth();

  useEffect(() => {
    // Add a request interceptor to set the authorization header on outgoing requests.
    const requestIntercept = axiosInstance.interceptors.request.use((config) => {
      const newConfig = { ...config };
      if (!config.headers.Authorization) {
        newConfig.headers.Authorization = `Bearer ${auth?.accessToken}`;
      }
      return newConfig;
    }, (error) => Promise.reject(error));

    // Add a response interceptor to handle 403 (Forbidden) errors and refresh the access token.
    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newTokens = await refresh();
          if (!newTokens) return Promise.reject(error);
          prevRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
          return axiosInstance(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    // Clean up the request and response interceptors when the component unmounts.
    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  // Return the axios instance with the added interceptors and authorization header.
  return axiosInstance;
};

export default useAxiosPrivate;
