import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import ApiClient from '../services/APIClient';
import { axiosMedicalContentInstance } from '../services/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export const convertAxiosErrorToUserFacingMessage = (axios500) => axios500?.response?.data?.message || axios500?.message || `${axios500}`;

const ApiClientContext = createContext();

export function ApiClientProvider({ children }) {
  const mcAxiosWithAuth = useAxiosPrivate(axiosMedicalContentInstance);

  const apiClient = new ApiClient(mcAxiosWithAuth);
  const memoizedApiClient = useMemo(() => apiClient, []);

  return (
    <ApiClientContext.Provider value={memoizedApiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}

export function useApiClient() {
  return useContext(ApiClientContext);
}

ApiClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
