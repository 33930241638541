import React from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function AnswerAdditionButton(props) {
  const { id, reloadCallback } = props;
  const apiClient = useApiClient();

  const handleAdd = () => apiClient.addAnswer(id);

  return (

    <ServiceButtonWithToast
      onSuccess={reloadCallback}
      service={handleAdd}
      buttonText="Add Answer"
      successMsg="Answer added!"
    />
  );
}

AnswerAdditionButton.propTypes = {
  id: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
};

AnswerAdditionButton.defaultProps = {
  reloadCallback: null,
};
