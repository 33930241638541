import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../../context/ApiClientContext';

import QuestionForm from '../../Question/QuestionForm';
// eslint-disable-next-line import/no-cycle
import FixedAnswerTreeItem from '../../FixedAnswer/FixedAnswerTreeItem';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../../_common/MedicalContentTreeItem';
import generateConditionLabel from '../../Condition/generateConditionLabel';

export default function QuestionTreeItem(props) {
  const {
    questionId,
    label,
    questionType,
    askQuestionActionId,
    setFormPane,
    setTreeManagementServices,
    onDeleteWarningMessage,
    deleteService,
    raiseService,
    lowerService,
    onRaiseLowerSuccess,
    refreshParent,
  } = props;

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
    updateLabel,
  } = useMedicalContentTreeItem(label);

  const apiClient = useApiClient();

  const fetchChildNodes = useCallback(async () => {
    const results = await apiClient.getFixedAnswers(questionId);
    setMCTreeItemChildNodes(results.data.map((result, idx, arr) => (
      <FixedAnswerTreeItem
        key={`${result.text}_${result.id}`}
        id={result.id}
        actionListId={result['actionListId-selected']}
        questionId={questionId}
        label={`${result.text}${result.exclusionCriteria ? ` [Omit if: ${generateConditionLabel(result.exclusionCriteria)}]` : ''}`}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
        index={idx}
        answerList={arr}
      />
    )));
  }, []);

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const moveService = (module) => apiClient.moveActionToNewModule(askQuestionActionId, module);
  const onMoveSuccess = () => {
    refreshParent();
    setTreeManagementServices({});
  };

  const onSelect = () => {
    setFormPane(
      <QuestionForm
        questionId={questionId}
        updateCallback={updateLabel}
        reloadCallback={reloadAndExpandNode}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService,
      raiseService,
      lowerService,
      onSuccess: onRaiseLowerSuccess,
      moveService,
      onMoveSuccess,
    });
  };

  return (
    <MedicalContentTreeItem
      id={questionId}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      isEndNode={!['selectAll', 'selectOne', 'selectOneBodyPart', 'selectAllBodyParts'].includes(questionType)}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No answers yet..."
    />
  );
}

QuestionTreeItem.propTypes = {
  questionId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  askQuestionActionId: PropTypes.number.isRequired,
  setFormPane: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  deleteService: PropTypes.func,
  raiseService: PropTypes.func,
  lowerService: PropTypes.func,
  onRaiseLowerSuccess: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

QuestionTreeItem.defaultProps = {
  deleteService: null,
  raiseService: null,
  lowerService: null,
};
