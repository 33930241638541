import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import AttributeSelector from '../../Attribute/attribute/findExisting/AttributeSelector';
import { Controls } from '../../../components/controls/Controls';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import Form from '../../../components/useForm';

export default function AddNumericAttributeValueToNarrativeButton(props) {
  const { actionListId, parentOnSuccess } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [contextType, setContextType] = useState('');
  const [attributeId, setAttributeId] = useState(null);
  const [value, setValue] = useState('');

  const apiClient = useApiClient();

  const handleNumberChange = (e) => {
    const newValue = e.target.value.replace(/[^\d.-]+/g, '');
    setValue(newValue);
  };

  const handleSubmit = () => apiClient.addNumericAttributeValueToNarrative(
    actionListId,
    attributeId,
    parseFloat(value),
  );

  const onSuccess = () => {
    parentOnSuccess();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Numeric"
      modalTitle="Add numeric value to narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={() => {
        setContextType('');
        setAttributeId(null);
        setValue('');
      }}
    >
      <Form>
        <AttributeSelector
          requiredSubtype="numeric"
          selectedContextType={contextType}
          setSelectedContextType={setContextType}
          selectedAttributeId={attributeId}
          setSelectedAttributeId={setAttributeId}
        />
        <Controls.Input
          value={value}
          name="value"
          onChange={handleNumberChange}
          label="Value"
          inputProps={{ inputMode: 'numeric', pattern: '-?[0-9.]*' }}
        />

        <Grid container justifyContent="flex-end">
          <ServiceButtonWithToast
            disabled={attributeId === null || value === ''}
            buttonText="Save"
            service={handleSubmit}
            onSuccess={onSuccess}
          />
        </Grid>
      </Form>

    </ButtonWithModal>
  );
}

AddNumericAttributeValueToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  parentOnSuccess: PropTypes.func.isRequired,
};
