import React from 'react';
import PropTypes from 'prop-types';
import MedicalContentTreeItem from './MedicalContentTreeItem';

export default function BasicEndNodeTreeItem(props) {
  const {
    id,
    label,
    setFormPane,
    setTreeManagementServices,
    deleteService,
    onDeleteWarningMessage,
    raiseService,
    lowerService,
    onSuccess,
  } = props;
  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService,
      raiseService,
      lowerService,
      onSuccess,
    });
  };

  return (
    <MedicalContentTreeItem
      id={id}
      MCTreeItemLabel={label}
      isEndNode
      onSelect={onSelect}
    />
  );
}

BasicEndNodeTreeItem.propTypes = {
  id: PropTypes.number.isRequired,
  setFormPane: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  deleteService: PropTypes.func,
  raiseService: PropTypes.func,
  lowerService: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

BasicEndNodeTreeItem.defaultProps = {
  deleteService: null,
  raiseService: null,
  lowerService: null,
};
