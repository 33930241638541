import Input from './Input';
import Button from './Button';
import Checkbox from './Checkbox';
import Select from './Select';

// eslint-disable-next-line import/prefer-default-export
export const Controls = {
  Input,
  Button,
  Checkbox,
  Select,
};
