import React from 'react';
import PropTypes from 'prop-types';

import AttributeCreateButtonWithModal from './createAndEdit/AttributeCreateButtonWithModal';
import AttributeFindButtonWithModal from './findExisting/AttributeFindButtonWithModal';
import { serviceCallbackPropTypes } from '../../../services/basicServiceHandler';

export default function AttributeCreateOrFindForm(props) {
  const {
    requiredSubtype,
    createCallbacks,
    addExistingAttributeCallbacks,
  } = props;

  return (
    <>
      <AttributeCreateButtonWithModal
        createCallbacks={createCallbacks}
      />
      <AttributeFindButtonWithModal
        requiredSubtype={requiredSubtype}
        initialContextType="patient"
        addExistingAttributeCallbacks={addExistingAttributeCallbacks}
      />
    </>
  );
}

AttributeCreateOrFindForm.propTypes = {
  requiredSubtype: PropTypes.oneOf(['string', 'numeric']),
  createCallbacks: serviceCallbackPropTypes.isRequired,
  addExistingAttributeCallbacks: serviceCallbackPropTypes.isRequired,
};

AttributeCreateOrFindForm.defaultProps = {
  requiredSubtype: null,
};
