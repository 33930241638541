import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem from '../../_common/MedicalContentTreeItem';
import TemplatePane from './TemplatePane';
import { useApiClient } from '../../../context/ApiClientContext';

export default function TemplateTreeItem(props) {
  const {
    templateId,
    templateUniqueName,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
  } = props;

  const apiClient = useApiClient();

  const onSelect = () => {
    setFormPane(
      <TemplatePane
        templateId={templateId}
        refreshParent={refreshParent}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the Template "${templateUniqueName}"?`,
      deleteService: () => apiClient.deleteTemplate(templateId),
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={templateId}
      MCTreeItemLabel={templateUniqueName}
      isEndNode
      onSelect={onSelect}
    />
  );
}

TemplateTreeItem.propTypes = {
  templateId: PropTypes.number.isRequired,
  templateUniqueName: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
