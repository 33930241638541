import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useApiClient } from '../../context/ApiClientContext';
import ConditionEditForm from '../Condition/ConditionEditForm';

export default function ScoreFactorPane(props) {
  const {
    scoreId,
    initialScoreFactor,
    refreshParent,
  } = props;

  const [scoreFactor, setScoreFactor] = useState(initialScoreFactor);

  const apiClient = useApiClient();

  const refreshScoreFactor = async () => {
    const factors = (await apiClient.getFactorsForScore(scoreId)).data;
    const matchingsSoreFactor = factors.find((factor) => factor.id === initialScoreFactor.id);
    setScoreFactor(matchingsSoreFactor);
  };

  useEffect(() => {
    const refresh = async () => {
      await refreshScoreFactor();
    };
    refresh();
  }, [initialScoreFactor.id]);

  const onUpdateCondition = (updatedCondition) => apiClient.updateScoreFactor(
    scoreFactor.id,
    { subtype: 'condition', condition: updatedCondition },
  );

  return (
    scoreFactor.subtype === 'condition'
    && (
    <ConditionEditForm
      condition={scoreFactor.condition}
      onSubmit={onUpdateCondition}
      onSuccess={() => {
        refreshParent();
        refreshScoreFactor();
      }}
    />
    )
  );
}

export const scoreFactorPropTypes = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    subtype: PropTypes.oneOf(['condition']),
    condition: PropTypes.shape({
      id: PropTypes.number,
      conditionType: PropTypes.string,
      subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
    }).isRequired,
    points: PropTypes.number.isRequired,
  }),
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    subtype: PropTypes.oneOf(['numericAttribute']),
    attributeName: PropTypes.string,
    operator: PropTypes.oneOf(['add', 'multiply']),
  })]);

ScoreFactorPane.propTypes = {
  scoreId: PropTypes.number.isRequired,
  initialScoreFactor: scoreFactorPropTypes.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
