import React from 'react';
import PropTypes from 'prop-types';

const Handle = React.forwardRef(({
  handleAxis,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
}, ref) => {
  const customStyle = {
    backgroundColor: 'lightgray',
    width: '2px',
    height: '100%',
    cursor: 'ew-resize',
    position: 'absolute',
    right: '0px',
    top: '0px',
  };

  return (
    <div
      ref={ref}
      style={customStyle}
      data-handleaxis={handleAxis}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      role="button"
      aria-label="Handle control"
      tabIndex={0}
    />
  );
});

Handle.propTypes = {
  handleAxis: PropTypes.string,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
};

Handle.defaultProps = {
  handleAxis: '',
  onMouseDown: () => {},
  onMouseUp: () => {},
  onTouchEnd: () => {},
};

export default Handle;
