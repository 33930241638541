import React from 'react';
import PropTypes from 'prop-types';

import TemplateCategoryTreeItem from './TemplateCategory/TemplateCategoryTreeItem';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';

export default function TemplateCategoryParentTreeItem(props) {
  const {
    setFormPane, setTreeManagementServices,
  } = props;

  const {
    MCTreeItemChildNodes,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Templates');

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes([
      <TemplateCategoryTreeItem
        key="exam"
        label="Exam"
        categoryUniqueName="exam"
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
      <TemplateCategoryTreeItem
        key="plan"
        label="Assessment/Plan"
        categoryUniqueName="plan"
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
      />,
    ]);
  };

  const onSelect = () => {
    setFormPane(null);
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel="Templates"
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
    />
  );
}

TemplateCategoryParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
