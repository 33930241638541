import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ButtonWithModal from '../../components/ButtonWithModal';
import SymptomSelector from '../Symptom/SymptomSelector';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

import basicServiceHandler from '../../services/basicServiceHandler';
import ToastContext from '../../context/ToastProvider';

export default function SxOfDxHxParentPane(props) {
  const { dxhxId, refreshParent } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useContext(ToastContext);

  const handleSymptomAdd = async (symptomId) => {
    basicServiceHandler(
      () => apiClient.addSymptomToDxHx(dxhxId, symptomId),
      () => {
        toastSetSuccessNotification('Symptom added.');
        refreshParent();
        setModalIsOpen(false);
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  return (
    <ButtonWithModal
      buttonText="Add Symptom"
      modalTitle="Select a symptom to add"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <SymptomSelector
        reloadCallback={refreshParent}
        handleSymptomAdd={handleSymptomAdd}
        buttonText="Add"
        successMsg="Symptom added!"
      />
    </ButtonWithModal>
  );
}

SxOfDxHxParentPane.propTypes = {
  dxhxId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
