import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useApiClient } from '../../context/ApiClientContext';
import ButtonWithModal from '../../components/ButtonWithModal';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';

const initialFieldValues = { uniqueName: '' };
export default function ModuleSaveAsNewButton(props) {
  const { sourceModuleId, onSuccess, onError } = props;
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const apiClient = useApiClient();

  const validate = () => {
    const errorMsg = {};
    errorMsg.uniqueName = values.uniqueName === '' ? 'Please enter a name.' : '';
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      basicServiceHandler(
        () => apiClient.saveAsNewModule(sourceModuleId, values.uniqueName),
        (result) => {
          onSuccess(result);
          setModalIsOpen(false);
        },
        (error) => onError(error),
        () => {
          setLoading(false);
          setValues(initialFieldValues);
        },
      );
    }
  };

  return (
    <ButtonWithModal
      buttonText="Save as New"
      modalTitle="Save as..."
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <Controls.Input
              label="New Module Name"
              name="uniqueName"
              value={values.uniqueName}
              onChange={handleInputChange}
              error={errors.uniqueName}
            />
            <Grid container justifyContent="flex-end">
              <Controls.Button
                variant="contained"
                type="submit"
                sx={{ margin: '6px' }}
                loading={loading}
                text="Save"
                size="medium"
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </ButtonWithModal>
  );
}

ModuleSaveAsNewButton.propTypes = {
  sourceModuleId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
