import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useForm } from '../../../../components/useForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import AttributeValueCreateEditForm from './AttributeValueCreateEditForm';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';

export const blankValues = {
  c2iName: '',
  reportText: '',
};

export default function AttributeValueCreateButtonWithModal(props) {
  const { createCallbacks, attributeReportStrings } = props;
  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(blankValues);

  const resetValues = () => setValues(blankValues);

  const [isOpen, setIsOpen] = useState(false);

  const callbacks = {
    ...createCallbacks,
    service: () => createCallbacks.service(values),
  };

  return (
    <ButtonWithModal
      buttonText="Create New Value"
      modalTitle="Create Attribute Value"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={resetValues}
    >
      <AttributeValueCreateEditForm
        values={values}
        attributeReportStrings={attributeReportStrings}
        handleInputChange={handleInputChange}
        callbacks={callbacks}
      />
    </ButtonWithModal>
  );
}

AttributeValueCreateButtonWithModal.propTypes = {
  createCallbacks: serviceCallbackPropTypes.isRequired,
  attributeReportStrings: PropTypes.shape({
    preString: PropTypes.string,
    postString: PropTypes.string,
  }).isRequired,
};
