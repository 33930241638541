import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../context/ApiClientContext';
import IntakePlanForm, { intakePlanDefaultValues } from './IntakePlanForm';
import basicServiceHandler from '../../services/basicServiceHandler';
import useToast from '../../hooks/useToast';
import { useForm } from '../../components/useForm';

export default function IntakePlanPane(props) {
  const {
    intakePlanId, refreshParent,
  } = props;

  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(intakePlanDefaultValues);

  useEffect(() => {
    basicServiceHandler(
      () => apiClient.getIntakePlan(intakePlanId),
      (response) => setValues(response.data),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => {},
    );
  }, [intakePlanId]);

  const onSubmit = () => apiClient.updateIntakePlan(intakePlanId, values);

  return (
    values
      ? (
        <IntakePlanForm
          values={values}
          setValues={setValues}
          handleInputChange={handleInputChange}
          onSubmit={onSubmit}
          onSuccess={refreshParent}
          successMsg="Saved"
          errorMsg="Error saving Intake Plan"
        />
      ) : <CircularProgress />
  );
}

IntakePlanPane.propTypes = {
  intakePlanId: PropTypes.number.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
