import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Form from '../../../components/useForm';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import Dropdown from '../../../components/controls/Dropdown';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../context/ApiClientContext';
import useToast from '../../../hooks/useToast';

const templateCategories = [{ id: 'exam', name: 'Exam' }, { id: 'plan', name: 'Assessment/Plan' }];

export default function templateListSelector(props) {
  const {
    handleSubmit, buttonText, onSuccess, successMsg,
  } = props;

  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const [selectedCategory, setSelectedCategory] = useState('');
  const [listOptions, setlistOptions] = useState([]);
  const [selectedlistId, setSelectedlistId] = useState(null);

  const onSelectCategory = (e) => {
    const selectedCat = e.target.value;
    setSelectedCategory(selectedCat);
    basicServiceHandler(
      () => apiClient.getTemplateLists(selectedCat),
      (result) => setlistOptions(
        result.data.map((list) => ({ id: list.id, name: list.uniqueName })),
      ),
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  return (
    <Form>
      <Grid container>
        <Grid item xs={10}>
          <Dropdown
            name="category"
            value={selectedCategory}
            onChange={onSelectCategory}
            label="Category"
            options={templateCategories}
          />
          {selectedCategory
          && (
          <>
            <Dropdown
              label="List Name"
              name="listId"
              value={selectedlistId}
              onChange={(e) => setSelectedlistId(e.target.value)}
              options={listOptions}
            />
            <ServiceButtonWithToast
              service={() => handleSubmit(selectedlistId)}
              onSuccess={onSuccess}
              successMsg={successMsg}
              buttonText={buttonText}
            />
          </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

templateListSelector.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMsg: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};
