import React, { } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function TakingAnyMedicationSubForm(props) {
  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const handleSave = () => onSubmit({
    conditionType: 'takingAnyMedication',
  });

  return (
    <Grid item xs={10}>
      <ServiceButtonWithToast
        service={handleSave}
        onSuccess={onSuccess}
        successMsg={successMsg}
        buttonText="Save"
      />
    </Grid>
  );
}

TakingAnyMedicationSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

TakingAnyMedicationSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
