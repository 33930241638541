import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from '../../components/useForm';
import ModuleSelector from './ModuleSelector';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ModuleFindForm({ onModuleSelected, onSuccess, successMessage }) {
  const {
    values: selectedModule,
    setValues: setSelectedModule,
  } = useForm(null);

  return (
    <>
      <ModuleSelector
        selectedModule={selectedModule}
        setSelectedModule={setSelectedModule}
      />
      <ServiceButtonWithToast
        buttonText="Select"
        service={() => onModuleSelected(selectedModule)}
        onSuccess={onSuccess}
        successMsg={successMessage}
      />
    </>
  );
}

ModuleFindForm.propTypes = {
  onModuleSelected: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
};
