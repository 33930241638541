import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';
import { Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { Controls } from '../../components/controls/Controls';
import NumericSliderDetailsForm from './NumericSliderDetailsForm';
import Form from '../../components/useForm';
import useToast from '../../hooks/useToast';
import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';

export default function QuestionFormSubFormQuestion(props) {
  const {
    values,
    handleInputChange,
    questionId,
    updateCallback,
  } = props;

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const isWeightSliderValid = (value) => {
    const integerPart = Math.floor(value);
    const decimalPart = round(value - integerPart, 10); // Round to 10 decimal places for safety
    return Number.isInteger(decimalPart * 10);
  };

  const validateSliderValue = (value, errorMessage, sliderType = null) => {
    const parsedValue = parseFloat(value);
    if (value === null || value === undefined || Number.isNaN(parsedValue)) {
      return errorMessage;
    }

    if (parsedValue < 0) {
      return 'The number must be greater than or equal to 0.';
    }

    if (sliderType === 'weight') {
      return isWeightSliderValid(parsedValue) ? '' : 'Weight slider increment size should only include up to one decimal place.';
    }

    return Number.isInteger(parsedValue) ? '' : 'The number must be an integer.';
  };

  const validate = () => {
    let errorMsg = {
      questionText: values.questionText.length > 0 ? '' : 'Question text is required!',
      // Initial individual field validations
      minValue: validateSliderValue(values.minValue, 'Minimum value is required and must be a number.'),
      maxValue: validateSliderValue(values.maxValue, 'Maximum value is required and must be a number.'),
      stepSize: validateSliderValue(values.stepSize, 'Increment size is required and must be a number.', values.sliderType),
      sliderType: (values.sliderType || values.questionType !== 'numberFormSlider') ? '' : 'Slider type is required',
    };

    if (values.questionType === 'numberFormSlider') {
      // Check minValue and maxValue relationship
      if (values.minValue >= values.maxValue) {
        errorMsg = {
          ...errorMsg,
          minValue: 'Minimum value must be less than maximum value.',
          maxValue: 'Maximum value must be greater than minimum value.',
        };
      }

      // Check stepSize is within the range
      if (values.stepSize > values.maxValue || values.stepSize < values.minValue) {
        errorMsg = {
          ...errorMsg,
          stepSize: 'Increment size must be between minimum and maximum value.',
        };
      }
    }

    setErrors(errorMsg);
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleSave = async () => {
    if (validate()) {
      setLoading(true);
      const units = values.units?.split(',');

      let minValue; let maxValue; let sliderType; let stepSize;

      if (values.questionType === 'numberFormSlider') {
        sliderType = values.sliderType;
        minValue = parseInt(values.minValue, 10);
        maxValue = parseInt(values.maxValue, 10);
        stepSize = parseFloat(values.stepSize);
      }

      basicServiceHandler(
        () => apiClient.updateQuestion(
          questionId,
          values.questionType,
          values.questionText,
          units,
          values.helperText,
          undefined,
          minValue,
          maxValue,
          sliderType,
          stepSize,
          values.isAboutPii,
        ),
        () => {
          updateCallback(values.questionText);
          toastSetSuccessNotification('Update successful!');
        },
        (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
        () => setLoading(false),
      );
    }
  };

  return (

    <Form>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            value={values.questionType}
            name="questionType"
            onChange={handleInputChange}
            select
            label="Question Type"
          >
            <MenuItem value="selectOne">Select One</MenuItem>
            <MenuItem value="selectAll">Select All</MenuItem>
            <MenuItem value="freeTextForm">Free text Form</MenuItem>
            <MenuItem value="numberForm">Numeric question (Textbox)</MenuItem>
            <MenuItem value="numberFormSlider">Numeric question (Slider)</MenuItem>
            <MenuItem value="text">Info Page</MenuItem>
            <MenuItem value="fileUpload">Image Upload</MenuItem>
            <MenuItem value="signature">Signature Question</MenuItem>
          </Controls.Input>
          <TextField
            label="Question Text"
            name="questionText"
            value={values.questionText}
            error={!!errors.questionText}
            helperText={errors.questionText}
            onChange={handleInputChange}
            multiline
            minRows={1}
            maxRows={5}
            fullWidth
          />
          <TextField
            label="Helper Text"
            name="helperText"
            value={values.helperText}
            onChange={handleInputChange}
            multiline
            minRows={1}
            maxRows={5}
            fullWidth
          />
          {values.questionType === 'numberForm' && (
          <Controls.Input
            label="Units"
            name="units"
            value={values.units}
            onChange={handleInputChange}
          />
          )}
          {values.questionType === 'numberFormSlider' && (
          <NumericSliderDetailsForm
            questionType={values.questionType}
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
          />
          )}
          {(values.questionType === 'freeTextForm' && (
            <Grid item xs={11}>
              <Controls.Checkbox
                label="Personally Identifiable Information"
                name="isAboutPii"
                checked={values.isAboutPii}
                onChange={handleInputChange}
              />
            </Grid>
          ))}
          <Grid container justifyContent="flex-end">
            <Controls.Button
              variant="contained"
              onClick={handleSave}
              sx={{ margin: '6px' }}
              loading={loading}
              text="Save"
              size="medium"
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

QuestionFormSubFormQuestion.propTypes = {
  values: PropTypes.shape({
    questionType: PropTypes.string,
    questionText: PropTypes.string,
    units: PropTypes.string,
    helperText: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    stepSize: PropTypes.number,
    sliderType: PropTypes.string,
    isAboutPii: PropTypes.bool.isRequired,
  }),
  handleInputChange: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
};

QuestionFormSubFormQuestion.defaultProps = {
  updateCallback: null,
  values: {
    questionType: '',
    questionText: '',
    units: '',
    helperText: '',
    minValue: 0,
    maxValue: 200,
    stepSize: 1,
    sliderType: '',
  },
};
