import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import QuestionAdditionButton from './AskQuestion/QuestionAdditionButton';
import ModuleAdditionButton from './AddModule/ModuleAdditionButton';
import ConditionalAdditionButton from './DoConditional/ConditionalAdditionButton';
import IncludeDxhxAdditionButton from './IncludeDxhx/IncludeDxhxAdditionButton';
import AddSymptomToNarrativeButton from './AddSymptomToNarrative/AddSymptomToNarrativeButton';
import AddAttributeValueToNarrativeButton from './AddAttributeValueToNarrative/AddAttributeValueToNarrativeButton';
import AddScoreToReportButton from './AddScoreToReport/AddScoreToReportButton';
import OfferTemplatesButton from './OfferTemplates/OfferTemplatesButton';
import AddDeniedSymptomToNarrativeButton from './AddDeniedSymptomToNarrative/AddDeniedSymptomToNarrativeButton';
import { useApiClient } from '../../context/ApiClientContext';
import ButtonWithModal from '../../components/ButtonWithModal';
import ModuleCreateForm from '../Module/ModuleCreateForm';
import AddMedicationToNarrativeButton from './AddMedicationToNarrative/AddMedicationToNarrativeButton';

export default function ActionAdditionForm(props) {
  const { actionListId, reloadCallback, isModule } = props;

  return (
    <Grid container>
      <QuestionAdditionButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <ModuleAdditionButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <ConditionalAdditionButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <IncludeDxhxAdditionButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <AddSymptomToNarrativeButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <AddDeniedSymptomToNarrativeButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <AddAttributeValueToNarrativeButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <AddMedicationToNarrativeButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <AddScoreToReportButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      <OfferTemplatesButton
        actionListId={actionListId}
        reloadCallback={reloadCallback}
      />
      {!isModule && (
        <MakeThisAModuleButton
          actionListId={actionListId}
          reloadCallback={reloadCallback}
        />
      )}
    </Grid>
  );
}

ActionAdditionForm.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func,
  isModule: PropTypes.bool,
};

ActionAdditionForm.defaultProps = {
  reloadCallback: null,
  isModule: false,
};

function MakeThisAModuleButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const onSuccess = () => {
    reloadCallback();
    setModalIsOpen(false);
  };

  return (
    <ButtonWithModal
      buttonText="Make this list a module"
      modalTitle="Reassigning the currently selected action list to a new module"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <ModuleCreateForm
        onSubmit={(values) => apiClient.reassignActionListToANewModule(
          actionListId,
          values.moduleName,
        )}
        onSuccess={onSuccess}
      />
    </ButtonWithModal>
  );
}

MakeThisAModuleButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};
