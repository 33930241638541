import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SymptomSelector from '../../Symptom/SymptomSelector';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';

function AddDeniedSymptomToNarrativeButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  return (
    <ButtonWithModal
      buttonText="Add Denied Symptom To Narrative"
      modalTitle="Add Denied Symptom To Narrative"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <Grid container>
        <Grid item xs={6}>
          <SymptomSelector
            handleSymptomAdd={
              (symptomId) => apiClient.addDeniedSymptomToNarrative(actionListId, symptomId)
            }
            buttonText="Add"
            successMsg="Success"
            reloadCallback={reloadCallback}
          />
        </Grid>
      </Grid>
    </ButtonWithModal>
  );
}

AddDeniedSymptomToNarrativeButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default AddDeniedSymptomToNarrativeButton;
