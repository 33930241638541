import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../../../context/ApiClientContext';
import useToast from '../../../../hooks/useToast';
import basicServiceHandler from '../../../../services/basicServiceHandler';

import Dropdown from '../../../../components/controls/Dropdown';

export default function AttributeSelector({
  requiredSubtype,
  selectedContextType,
  setSelectedContextType,
  selectedAttributeId,
  setSelectedAttributeId,
  excludeAttributesWithoutValues,
}) {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [selectedSubtype, setSelectedSubtype] = useState(requiredSubtype ?? '');
  const apiClient = useApiClient();

  const {
    toastSetErrorNotification,
  } = useToast();

  const attributeSearchServiceSuccessCB = (result) => {
    if (result.data.length) setAttributeOptions(result.data);
  };

  useEffect(
    () => {
      if (selectedContextType) {
        basicServiceHandler(
          () => apiClient.attributeSearch(
            selectedContextType,
            selectedSubtype === '' ? undefined : selectedSubtype,
            excludeAttributesWithoutValues,
          ),
          attributeSearchServiceSuccessCB,
          (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
          () => {},
        );
      }
    },
    [selectedContextType, selectedSubtype],
  );

  return (
    <>
      <Dropdown
        value={selectedContextType}
        name="selectedContextType"
        onChange={(e) => setSelectedContextType(e.target.value)}
        label="Select a context type"
        options={[{ id: 'patient', name: 'Patient' }, { id: 'symptom', name: 'Symptom' }, { id: 'medication', name: 'Medication' }]}
        disabled={setSelectedContextType === null}
      />
      <Dropdown
        value={selectedSubtype}
        name="subtype"
        onChange={(e) => setSelectedSubtype(e.target.value)}
        label="Select string or numeric subtype"
        options={[{ id: 'string', name: 'String' }, { id: 'numeric', name: 'Numeric' }]}
        disabled={Boolean(requiredSubtype)}
      />
      {selectedContextType && (
        <Dropdown
          value={selectedAttributeId}
          name="selectedAttribute"
          onChange={(e) => setSelectedAttributeId(e.target.value)}
          label="Select an Attribute"
          options={attributeOptions.map((attr) => ({ id: attr.id, name: attr.uniqueName }))}
        />
      )}
    </>
  );
}

AttributeSelector.propTypes = {
  requiredSubtype: PropTypes.oneOf(['string', 'numeric']),
  selectedContextType: PropTypes.oneOf(['patient', 'symptom', '']),
  setSelectedContextType: PropTypes.func,
  selectedAttributeId: PropTypes.number,
  setSelectedAttributeId: PropTypes.func.isRequired,
  excludeAttributesWithoutValues: PropTypes.bool,
};

AttributeSelector.defaultProps = {
  requiredSubtype: null,
  selectedContextType: '',
  setSelectedContextType: null,
  excludeAttributesWithoutValues: false,
  selectedAttributeId: '',
};
