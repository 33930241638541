import React, { useState, useEffect } from 'react';

import { useForm } from '../../../../components/useForm';
import ButtonWithModal from '../../../../components/ButtonWithModal';
import AttributeCreateEditForm from './AttributeCreateEditForm';
import { serviceCallbackPropTypes } from '../../../../services/basicServiceHandler';
import { attributeFieldValuesPropType } from '../AttributeFormContent';

export default function AttributeEditButtonWithModal(props) {
  const {
    attributeFieldValues, updateCallbacks,
  } = props;

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(attributeFieldValues);

  const [isOpen, setIsOpen] = useState(false);

  const resetValues = () => setValues(attributeFieldValues);

  useEffect(resetValues, [attributeFieldValues]);

  const callbacks = {
    ...updateCallbacks,
    service: () => updateCallbacks.service(values),
  };

  return (
    <ButtonWithModal
      buttonText="Edit Attribute"
      modalTitle="Edit Attribute"
      modalIsOpen={isOpen}
      setModalIsOpen={setIsOpen}
      onClose={() => setValues(attributeFieldValues)}
    >
      <AttributeCreateEditForm
        values={values}
        handleInputChange={handleInputChange}
        callbacks={callbacks}
      />
    </ButtonWithModal>
  );
}

AttributeEditButtonWithModal.propTypes = {
  attributeFieldValues: attributeFieldValuesPropType.isRequired,
  updateCallbacks: serviceCallbackPropTypes.isRequired,
};
