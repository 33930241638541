import React, { } from 'react';
import PropTypes from 'prop-types';
import MedicationSelector from '../../Medication/MedicationSelector';

export default function TakingMedicationSubForm(props) {
  const {
    onSubmit,
    onSuccess,
    successMsg,
  } = props;

  const handleSave = (medicationId) => {
    const condition = {
      conditionType: 'takingMedication',
      medicationId,
    };
    return onSubmit(condition);
  };

  return (
    <MedicationSelector
      reloadCallback={onSuccess}
      handleMedicationAdd={handleSave}
      buttonText="Save"
      successMsg={successMsg}
    />
  );
}

TakingMedicationSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  successMsg: PropTypes.string,
};

TakingMedicationSubForm.defaultProps = {
  onSuccess: undefined,
  successMsg: undefined,
};
