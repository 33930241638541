import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../context/ApiClientContext';
import Dropdown from '../../components/controls/Dropdown';

export default function ScoreDropdown(props) {
  const {
    name, value, onChange, label, error,
  } = props;
  const [scores, setScores] = useState([]);

  const apiClient = useApiClient();

  useEffect(() => {
    async function getScores() {
      const result = await apiClient.getAllScores();
      const scoresData = result.data.map((score) => ({
        id: score.id,
        name: score.uniqueName,
      }));
      setScores(scoresData);
    }
    getScores();
  }, []);

  return (
    <Dropdown
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      error={error}
      options={scores}
    />
  );
}

ScoreDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

ScoreDropdown.defaultProps = {
  error: null,
};
