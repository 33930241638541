import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import basicServiceHandler from '../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';

export default function SymptomForm(props) {
  const {
    initialFieldValues, onSubmit, onSuccess, onFail,
  } = props;
  const [loading, setLoading] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(initialFieldValues);

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    basicServiceHandler(
      () => onSubmit(values),
      () => { onSuccess(values); setUpdatedValues(values); },
      (error) => { onFail(convertAxiosErrorToUserFacingMessage(error)); setValues(updatedValues); },
      () => setLoading(false),
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            label="Medical Name"
            name="medicalName"
            value={values.medicalName}
            onChange={handleInputChange}
          />
          <Controls.Input
            label="Report Name"
            name="reportName"
            value={values.reportName}
            onChange={handleInputChange}
          />
          <Controls.Input
            label="Layperson Name"
            name="layName"
            value={values.layName}
            onChange={handleInputChange}
            helperText="(What the patient sees in a list of symptoms to pick from)"
          />
          <Controls.Input
            label="Substitutor for {{THE-SX}} placeholder"
            name="laySubstitutor"
            value={values.laySubstitutor}
            onChange={handleInputChange}
            helperText='(Complete the sentence: Where is ___? [eg "the rash"])'
          />
          <Controls.Input
            label="Helper Text"
            name="helperText"
            value={values.helperText}
            onChange={handleInputChange}
          />
          <Grid container justifyContent="flex-end">
            <Controls.Button
              variant="contained"
              type="submit"
              sx={{ margin: '6px' }}
              loading={loading}
              text="Save"
              size="medium"
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

SymptomForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialFieldValues: PropTypes.shape({
    medicalName: PropTypes.string.isRequired,
    layName: PropTypes.string.isRequired,
  }),
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

SymptomForm.defaultProps = {
  initialFieldValues: {
    medicalName: '', reportName: '', layName: '', helperText: '',
  },
};
