import React, { useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

// This component is responsible for requiring authentication for protected routes,
// based on the user's permissions.
// allowedPermissions ({[key: string]: string[]}) - the object with key-value pairs
// where the key represents a permission type, and the value is an array of allowed values.
function RequireAuth({ allowedPermissions }) {
  // Get the auth state and current location using hooks.
  const { auth, hasPermissions } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Use useEffect to perform the redirect only after the component has rendered.
  useEffect(() => {
    // If the user is authenticated but doesn't have permission, redirect to the unauthorized page.
    if (auth?.accessToken && !hasPermissions(allowedPermissions)) {
      navigate('/unauthorized', { state: { from: location }, replace: true });
    }

    // If the user is not authenticated, redirect to the login page.
    if (!auth?.accessToken) {
      navigate('/login', { state: { from: location }, replace: true });
    }
  }, [auth, hasPermissions, allowedPermissions, location, navigate]);

  // If the user has permission to access the route, render the child routes.
  if (hasPermissions(allowedPermissions)) {
    return <Outlet />;
  }

  // Return null if we're waiting for the redirect.
  return null;
}

RequireAuth.propTypes = {
  allowedPermissions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
};

export default RequireAuth;
