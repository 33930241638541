import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';

export default function Button(props) {
  const {
    // eslint-disable-next-line react/prop-types
    text, size, color, variant, onClick, loading = false, type, ...other
  } = props;
  const theme = useTheme();
  return (
    <LoadingButton
      sx={{
        '& .MuiButton-root': {
          margin: theme.spacing(1),
          textTransform: 'none',
        },
      }}
      variant={variant || 'contained'}
      color={color || 'primary'}
      size={size || 'large'}
      onClick={onClick}
      loading={loading}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {text}
    </LoadingButton>
  );
}
