import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import ServiceButtonWithToast from './ServiceButtonWithToast';

export default function DeletionAlertDialog(props) {
  const {
    isOpen, handleClose, message, onConfirmDelete, onSuccessfulDeletion,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ServiceButtonWithToast
          buttonText="Delete"
          service={onConfirmDelete}
          onSuccess={onSuccessfulDeletion}
        />
        <ServiceButtonWithToast
          buttonText="Cancel"
          service={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
}

DeletionAlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onSuccessfulDeletion: PropTypes.func,
};

DeletionAlertDialog.defaultProps = {
  onSuccessfulDeletion: () => {},
};
