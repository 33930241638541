import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Chip,
} from '@mui/material';
import ButtonWithModal from '../../../components/ButtonWithModal';
import Form, { useForm } from '../../../components/useForm';
import { Controls } from '../../../components/controls/Controls';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import Dropdown from '../../../components/controls/Dropdown';
import basicServiceHandler from '../../../services/basicServiceHandler';
import useToast from '../../../hooks/useToast';

export const commonDirectionFormDefaultValues = {
  doseMedical: '',
  doseLay: '',
  routeId: '',
  frequencyId: '',
  isPrn: false,
};

const commonDirectionToString = (direction) => {
  const {
    route, doseMedical, doseLay, frequency, prn,
  } = direction;
  const doseString = doseMedical === doseLay ? doseMedical : `${doseMedical} ("${doseLay}")`;
  return `${doseString} ${route} ${frequency}${prn ? ' PRN' : ''}`;
};

export default function CommonDirectionsForm({
  commonDirections,
  onAddCommonDirection,
  refreshParent,
}) {
  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();

  const handleDeleteCommonDirection = (directionId) => {
    basicServiceHandler(
      () => apiClient.deleteCommonDirection(directionId),
      refreshParent,
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  const commonDirectionChips = commonDirections.map((direction) => {
    const directionString = commonDirectionToString(direction);
    return (
      <Grid
        key={directionString}
        item
        xs={10}
      >
        <Chip
          label={directionString}
          style={{ margin: '4px' }}
          onDelete={() => handleDeleteCommonDirection(direction.id)}
        />
      </Grid>
    );
  });
  return (
    <Grid container>
      {commonDirectionChips.length > 0 ? commonDirectionChips : (
        <Typography variant="body2">No directions found.</Typography>
      )}
      <Grid item xs={6}>
        <CreateCommonDirectionButton
          onAddCommonDirection={onAddCommonDirection}
          refreshParent={refreshParent}
        />
      </Grid>
    </Grid>
  );
}

CommonDirectionsForm.propTypes = {
  commonDirections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    route: PropTypes.string,
    doseMedical: PropTypes.string,
    doseLay: PropTypes.string,
    frequency: PropTypes.string,
    prn: PropTypes.bool,
  })),
  onAddCommonDirection: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};

CommonDirectionsForm.defaultProps = {
  commonDirections: [],
};

function CreateCommonDirectionButton({ onAddCommonDirection, refreshParent }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [frequencies, setFrequencies] = useState([]);
  const [routes, setRoutes] = useState([]);

  const apiClient = useApiClient();

  const {
    values,
    errors,
    setErrors,
    setValues,
    handleInputChange,
  } = useForm(commonDirectionFormDefaultValues);

  useEffect(() => {
    async function getAllRoutes() {
      const result = await apiClient.getAllMedRoutes();
      const medsData = result.data.map((fq) => ({
        id: fq.id,
        name: fq.abbreviation,
      }));
      setRoutes(medsData);
    }
    async function getAllFrequencies() {
      const result = await apiClient.getAllMedFrequencies();
      const medsData = result.data.map((fq) => ({
        id: fq.id,
        name: fq.medicalName,
      }));
      setFrequencies(medsData);
    }
    getAllRoutes();
    getAllFrequencies();
  }, []);

  const validate = () => {
    const inputErrorMessages = {};
    inputErrorMessages.doseMedical = values.doseMedical === '' ? 'Cannot be empty.' : '';
    inputErrorMessages.doseLay = values.doseLay === '' ? 'Cannot be empty.' : '';
    inputErrorMessages.routeId = values.routeId === '' ? 'Cannot be empty.' : '';
    inputErrorMessages.frequencyId = values.frequencyId === '' ? 'Cannot be empty.' : '';

    setErrors(inputErrorMessages);
    return Object.values(inputErrorMessages).every((item) => item === '');
  };

  const handleSubmit = () => (validate() ? onAddCommonDirection(values) : Promise.reject(new Error('Form Validation Error')));

  const resetValues = () => setValues(commonDirectionFormDefaultValues);

  return (
    <ButtonWithModal
      buttonText="Add"
      modalTitle="Add common direction"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={() => {
        resetValues();
        setModalIsOpen(false);
      }}
    >
      <Form>
        <Grid container>
          <Grid item xs={10}>
            <Controls.Input
              name="doseMedical"
              label="Medical Dose"
              value={values.doseMedical}
              error={errors.doseMedical}
              onChange={handleInputChange}
              helperText="(what shows on the report)"
            />
            <Controls.Input
              name="doseLay"
              label="Layperson Dose"
              value={values.doseLay}
              error={errors.doseLay}
              onChange={handleInputChange}
              helperText="(what the patient sees in the intake)"
            />
            <Dropdown
              name="routeId"
              label="Select a Route"
              value={values.routeId}
              error={errors.routeId}
              fullWidth
              onChange={handleInputChange}
              options={routes}
            />
            <Dropdown
              name="frequencyId"
              label="Select a Frequency"
              value={values.frequencyId}
              error={errors.frequencyId}
              fullWidth
              onChange={handleInputChange}
              options={frequencies}
            />
            <Controls.Checkbox
              label="PRN"
              name="isPrn"
              checked={values.isPrn}
              onChange={handleInputChange}
              fullWidth
            />
            <Grid container justifyContent="flex-end">
              <ServiceButtonWithToast
                buttonText="Add"
                service={handleSubmit}
                onSuccess={() => {
                  refreshParent();
                  resetValues();
                  setModalIsOpen(false);
                }}
                successMsg="Added"
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>

    </ButtonWithModal>
  );
}

CreateCommonDirectionButton.propTypes = {
  onAddCommonDirection: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
