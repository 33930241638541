import React, {
  useState, createContext, useMemo, useCallback,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const initialState = {
  open: false,
  severity: 'success',
  text: '',
};

const ToastContext = createContext({});

export function ToastProvider({ children }) {
  const [toastState, setToastState] = useState(initialState);

  const toastOnClose = () => {
    setToastState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  // useCallback to prevent regenerating the function each render
  // https://react.dev/reference/react/useContext#optimizing-re-renders-when-passing-objects-and-functions
  const toastSetSuccessNotification = useCallback((msg) => {
    setToastState({
      open: true,
      severity: 'success',
      text: msg,
    });
  }, []);

  const toastSetErrorNotification = useCallback((msg) => {
    setToastState({
      open: true,
      severity: 'error',
      text: msg,
    });
  });

  // useMemo to prevent regenerating the object each render
  // https://react.dev/reference/react/useContext#optimizing-re-renders-when-passing-objects-and-functions
  const toastValue = useMemo(() => ({
    toastSetSuccessNotification,
    toastSetErrorNotification,
  }), []);

  return (
    <ToastContext.Provider value={toastValue}>
      {children}
      <Snackbar
        open={toastState.open}
        autoHideDuration={5000}
        onClose={toastOnClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={toastOnClose}
          severity={toastState.severity}
          sx={{ width: '100%' }}
        >
          {`${toastState.text}`}
        </MuiAlert>
      </Snackbar>
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastContext;
