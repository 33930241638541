import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, Divider, IconButton, DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Modal(props) {
  const {
    // eslint-disable-next-line react/prop-types
    isOpen, handleClose, title, subtitle, children,
  } = props;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
    >
      {title ? (
        <>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {title}
            <IconButton
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
        </>
      ) : null}
      <DialogContent>
        {subtitle ? (
          <DialogContentText>
            {subtitle}
          </DialogContentText>
        ) : null}
        {children}
      </DialogContent>
    </Dialog>
  );
}
