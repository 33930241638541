import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../context/ApiClientContext';
import Dropdown from '../../components/controls/Dropdown';

export default function SymptomDropdown(props) {
  const {
    name, value, onChange, label, error,
  } = props;
  const [symptoms, setSymptoms] = useState([]);

  const apiClient = useApiClient();

  useEffect(() => {
    async function getSymptoms() {
      const result = await apiClient.getSymptoms();
      const symptomsData = result.data.map((symptom) => ({
        id: symptom.id,
        name: symptom.medicalName,
      }));
      setSymptoms(symptomsData);
    }
    getSymptoms();
  }, []);

  return (
    <Dropdown
      name={name}
      value={value}
      onChange={onChange}
      label={label}
      error={error}
      options={symptoms}
    />
  );
}

SymptomDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

SymptomDropdown.defaultProps = {
  error: null,
};
