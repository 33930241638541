import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import ServiceButtonWithToast from './ServiceButtonWithToast';
import DeletionAlertDialog from './DeletionAlertDialog';
import MoveToNewModuleButton from './MoveToNewModuleButton';

export default function TreeManagementPane({
  treeManagementServices: {
    onDeleteWarningMessage,
    deleteService,
    raiseService,
    lowerService,
    onSuccess,
    moveService,
    onMoveSuccess,
  },
  setFormPane,
}) {
  const [deletionAlertIsOpen, setDeletionAlertIsOpen] = useState(false);

  return (
    <>
      <IconButton
        disabled={!deleteService}
        onClick={() => setDeletionAlertIsOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
      {deleteService
      && (
      <DeletionAlertDialog
        isOpen={deletionAlertIsOpen}
        handleClose={() => setDeletionAlertIsOpen(false)}
        message={onDeleteWarningMessage}
        onConfirmDelete={() => {
          setDeletionAlertIsOpen(false);
          setFormPane(null);
          return deleteService();
        }}
        onSuccessfulDeletion={onSuccess}
      />
      )}
      <MoveToNewModuleButton
        moveService={moveService}
        onMoveSuccess={onMoveSuccess}
      />
      <ServiceButtonWithToast
        disabled={!raiseService}
        buttonText="↑"
        size="small"
        variant="outlined"
        service={raiseService}
        onSuccess={onSuccess}
      />
      <ServiceButtonWithToast
        disabled={!lowerService}
        buttonText="↓"
        size="small"
        variant="outlined"
        service={lowerService}
        onSuccess={onSuccess}
      />

    </>
  );
}

TreeManagementPane.propTypes = {
  treeManagementServices: PropTypes.shape({
    onDeleteWarningMessage: PropTypes.string,
    deleteService: PropTypes.func,
    raiseService: PropTypes.func,
    lowerService: PropTypes.func,
    moveService: PropTypes.func,
    refreshParent: PropTypes.func,
    onSuccess: PropTypes.func,
    onMoveSuccess: PropTypes.func,
  }).isRequired,
  setFormPane: PropTypes.func.isRequired,
};
