import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import ModuleFindForm from '../../Module/ModuleFindForm';

function AddExistingModuleButton(props) {
  const { actionListId, reloadCallback } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiClient = useApiClient();

  const onModuleSelected = (
    (selectedModule) => apiClient.addExistingModule(actionListId, selectedModule.id));

  const onSuccess = () => {
    setModalIsOpen(false);
    reloadCallback();
  };

  return (
    <ButtonWithModal
      buttonText="Add Existing Module"
      modalTitle="Add Existing Module"
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <ModuleFindForm onModuleSelected={onModuleSelected} onSuccess={onSuccess} successMessage="Module added." />
    </ButtonWithModal>
  );
}

AddExistingModuleButton.propTypes = {
  actionListId: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
};

export default AddExistingModuleButton;
