import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import ConditionEditForm from '../Condition/ConditionEditForm';
import ActionAdditionForm from '../Actions/ActionAdditionForm';
import { useApiClient } from '../../context/ApiClientContext';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ConditionalPane(props) {
  const {
    initialConditional, canAddElse, actionListIdIfTrue, reloadCallback, refreshParent,
  } = props;

  const [conditional, setConditional] = useState(initialConditional);
  const apiClient = useApiClient();

  const refreshConditional = async () => {
    setConditional((await apiClient.getConditional(initialConditional.id)).data);
  };

  useEffect(() => {
    const refresh = async () => {
      await refreshConditional();
    };
    refresh();
  }, [initialConditional.id]);
  return (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <ConditionEditForm
          condition={conditional.condition}
          onSubmit={(condition) => apiClient.updateConditional(conditional.id, { condition })}
          onSuccess={() => {
            refreshConditional();
            reloadCallback();
            refreshParent();
          }}
          successMsg="Conditional updated."
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Add an action</Typography>
        <ActionAdditionForm
          actionListId={actionListIdIfTrue}
          reloadCallback={reloadCallback}
        />
      </Grid>
      <Grid item xs={12}>
        {canAddElse && (
        <ServiceButtonWithToast
          onSuccess={refreshParent}
          service={() => apiClient.updateConditional(conditional.id, { hasElse: true })}
          buttonText="Add ELSE"
          successMsg="ELSE added"
        />
        )}
      </Grid>

    </Grid>
  );
}
ConditionalPane.propTypes = {
  initialConditional:
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      condition: PropTypes.shape({
        id: PropTypes.number,
        conditionType: PropTypes.string,
        subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
      }),
    }).isRequired,
  canAddElse: PropTypes.bool.isRequired,
  actionListIdIfTrue: PropTypes.number.isRequired,
  reloadCallback: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
